import { adminClient } from "../utils/axios";
import query from "querystringify";
import { getIfExist } from "../utils/getIfExist";

// FAQs
export const getFaqsList = () => {
  return adminClient.get(`/admin/content-qa/`);
};

export const addFaq = ({ faqData }) => {
  return adminClient.post("/admin/content-qa/", getIfExist(faqData));
};

export const deleteFaq = ({ id }) => {
  return adminClient.delete(`/admin/content-qa/${id}/`);
};

export const updateFaq = ({ faqData }) => {
  return adminClient.put(`/admin/content-qa/${faqData.id}/`, getIfExist(faqData));
};

// Tips
export const getTipsList = () => {
  return adminClient.get(`/admin/content-tips/`);
};

export const getCurrentTips = ({ id }) => {
  return adminClient.get(`/admin/content-tips/${id}/`);
};

export const addTips = ({ tipsData }) => {
  return adminClient.post("/admin/content-tips/", getIfExist(tipsData));
};

export const updateTips = ({ tipsData }) => {
  return adminClient.put(`/admin/content-tips/${tipsData.id}/`, getIfExist(tipsData));
};

export const deleteTips = ({ id }) => {
  return adminClient.delete(`/admin/content-tips/${id}/`);
};
