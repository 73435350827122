import React from "react";
import { get } from "lodash";
import PaginationBootstrap from "react-bootstrap/Pagination";
import { useSelector } from "react-redux";
import { history } from "../../utils/history";

const Pagination = ({ paginatedPageState }) => {
  const { count, page, page_size, next, previous } = useSelector(state => {
    return get(state, paginatedPageState, {});
  });

  const getItemsCount = () => Math.ceil(count / page_size);

  const handlePageClick = pageNum => {
    const params = new URLSearchParams(history.location.search);
    params.set("page", pageNum);
    params.set("page_size", page_size);
    history.navigate({
      pathname: history.location.pathname,
      search: params.toString(),
    });
  };

  const renderPaginationItems = () => {
    const itemsCount = getItemsCount();
    let items = [];

    if (itemsCount <= 6) {
      for (let i = 1; i <= itemsCount; i++) {
        items.push(
          <PaginationBootstrap.Item key={i} active={i === page} onClick={() => handlePageClick(i)}>
            {i}
          </PaginationBootstrap.Item>,
        );
      }

      return items;
    }

    items.push(
      <PaginationBootstrap.Item key={1} active={page === 1} onClick={() => handlePageClick(1)}>
        1
      </PaginationBootstrap.Item>,
    );

    if (page > 4) {
      items.push(<PaginationBootstrap.Ellipsis key="left-ellipsis" />);
    }

    const startPage = Math.max(2, page - 2);
    const endPage = Math.min(itemsCount - 1, page + 2);

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationBootstrap.Item key={i} active={i === page} onClick={() => handlePageClick(i)}>
          {i}
        </PaginationBootstrap.Item>,
      );
    }

    if (page < itemsCount - 3) {
      items.push(<PaginationBootstrap.Ellipsis key="right-ellipsis" />);
    }

    items.push(
      <PaginationBootstrap.Item
        key={itemsCount}
        active={page === itemsCount}
        onClick={() => handlePageClick(itemsCount)}
      >
        {itemsCount}
      </PaginationBootstrap.Item>,
    );

    return items;
  };

  return (
    <div className="row text-center mt-auto">
      <div className="col-12 mt-4">
        <div className="d-md-flex align-items-center text-center justify-content-between">
          <span className="text-muted me-3">
            Showing {(page - 1) * page_size + 1} - {Math.min(page * page_size, count)} out of{" "}
            {count}
          </span>
          <PaginationBootstrap>
            <PaginationBootstrap.Prev
              disabled={!previous}
              onClick={() => handlePageClick(page - 1)}
            >
              Prev
            </PaginationBootstrap.Prev>

            {renderPaginationItems()}

            <PaginationBootstrap.Next disabled={!next} onClick={() => handlePageClick(page + 1)}>
              Next
            </PaginationBootstrap.Next>
          </PaginationBootstrap>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
