import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { history } from "../../utils/history";
import Wrapper from "../wrapper";
import { useAccess } from "../../hooks/useAccess";

const PrivateRoute = () => {
  const auth = useSelector(s => s.auth);
  const location = history.location;

  const { hasRouteAccess } = useAccess();

  if (!auth.isLoggedIn) return <Navigate to="/login" state={{ from: location }} />;

  if (!hasRouteAccess(location.pathname)) {
    return <Navigate to="/index" />;
  }

  return (
    <Wrapper isWithFooter={location.pathname !== "/calendar"}>
      <Outlet />
    </Wrapper>
  );
};

export default PrivateRoute;
