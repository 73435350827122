import { createSlice } from "@reduxjs/toolkit";
import { getDoctorAllServices, getDoctorProfile, getDoctorsList } from "../actions/doctor";

const initialState = {
  count: 0,
  page: 1,
  page_size: 50,
  next: null,
  previous: null,
  ordering: "first_name",
  list: [],
  doctorProfile: {},
  doctorServices: [],
};

export const doctorSliceName = "doctors";

export const doctorSlice = createSlice({
  name: doctorSliceName,
  initialState,
  reducers: {
    resetDoctorsState: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getDoctorsList.fulfilled, (state, action) => {
        state.list = action.payload.data.results;
        state.count = action.payload.data.count;
        state.next = action.payload.data.next;
        state.previous = action.payload.data.previous;
        state.page = action.payload.page;
      })
      .addCase(getDoctorProfile.fulfilled, (state, action) => {
        state.doctorProfile = action.payload.data;
      })
      .addCase(getDoctorAllServices.fulfilled, (state, action) => {
        state.doctorServices = action.payload.data;
      });
  },
});

export const { resetDoctorsState } = doctorSlice.actions;

export default doctorSlice.reducer;
