import { adminClient } from "../utils/axios";
import query from "querystringify";
import { getIfExist } from "../utils/getIfExist";

export const getPatientProfilesList = queryObj => {
  return adminClient.get(`admin/patient-profiles/${query.stringify(getIfExist(queryObj), true)}`);
};

export const addPatientProfile = ({ patientProfileData }) => {
  return adminClient.post("admin/patient-profiles/", getIfExist(patientProfileData));
};

export const getPatientProfileDetails = ({ id }) => {
  return adminClient.get(`admin/patient-profiles/${id}/`);
};

export const updatePatientProfile = ({ id, patientProfileData }) => {
  return adminClient.put(`admin/patient-profiles/${id}/`, patientProfileData);
};

export const removePatientProfile = id => {
  return adminClient.delete(`admin/patient-profiles/${id}/`);
};

export const updateMedRecords = ({ id, data }) => {
  return adminClient.put(`admin/medical_records/${id}/`, data);
};

export const getPatientFamilyAccessDetails = patientId => {
  return adminClient.get(`admin/family_access/?manager=${patientId}`);
};

export const getFamilyAccessDetails = accessId => {
  return adminClient.get(`admin/family_access/${accessId}/`);
};

export const addPatientToFamilyAccess = data => {
  return adminClient.post(`admin/family_access/`, data);
};

export const updatePatientFamilyAccess = (accessId, data) => {
  return adminClient.put(`admin/family_access/${accessId}/`, data);
};

export const removePatientFromFamilyAccess = id => {
  return adminClient.delete(`admin/family_access/${id}/`);
};
