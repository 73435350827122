import { useSelector } from "react-redux";
import { matchPath } from "react-router-dom";
import {
  accessControlConfig,
  controlledRoutesPaths,
  entityLevel,
} from "../config/accessControlConfig";

export function useAccess() {
  const user = useSelector(s => s.auth.user);

  const hasRouteAccess = path => {
    if (accessControlConfig[user.role]?.allowAll) return true;

    const isControlled = controlledRoutesPaths.some(pattern =>
      matchPath({ path: pattern, end: true }, path),
    );

    if (!isControlled) return true;

    const allowedRoutes = accessControlConfig[user.role]?.routes || [];

    return allowedRoutes.some(pattern => matchPath({ path: pattern, end: true }, path));
  };

  const hasPermission = (resource, action, entityId) => {
    if (accessControlConfig[user.role]?.allowAll) return true;

    const perms = accessControlConfig[user.role]?.permissions?.[resource] || [];

    for (const perm of perms) {
      if (typeof perm === "string") {
        if (perm === action) return true;
      }

      if (typeof perm === "object" && perm !== null && perm[action]) {
        const levels = perm[action];

        if (!Array.isArray(levels)) continue;

        if (!entityId) {
          if (levels.includes(entityLevel.any)) return true;

          continue;
        }

        if (levels.includes(entityLevel.any)) return true;

        if (
          levels.includes(entityLevel.self) &&
          (+entityId === +user.data?.doctor_profile?.id ||
            +entityId === +user.data?.staff_profile?.id)
        ) {
          return true;
        }
      }
    }

    return false;
  };

  return { hasRouteAccess, hasPermission };
}
