import React, { memo, useCallback, useState } from "react";
import classNames from "classnames";
import { FaHeart, FaRegFilePdf } from "react-icons/fa";
import moment from "moment/moment";
import { FiCornerUpLeft } from "react-icons/fi";
import { MdDone, MdDoneAll } from "react-icons/md";
import { Link } from "react-router-dom";

import { formatTextWithLinks } from "../../utils/textHelpers";
import Loader from "../Loader/Loader";
import { useSocket } from "../../hooks/useSocket";
import { showToast } from "../../utils/toasts";
import { FaArrowRotateRight } from "react-icons/fa6";

function ChatMessage({
  message,
  setLikeHandler,
  setMessageToWhichAnswer,
  scrollToMessage,
  isHighlighted,
}) {
  const socket = useSocket();
  const {
    is_sender_patient,
    text,
    created_at,
    is_liked,
    is_read,
    reply_to,
    uuid,
    id,
    files,
    data,
  } = message;
  const isAdminSender = !is_sender_patient;
  const messageDate = moment(created_at);
  const isToday = messageDate.isSame(moment(), "day");
  const formattedDate = isToday
    ? messageDate.format("HH:mm")
    : messageDate.format("DD.MM.YYYY HH:mm");

  const [isRepeatArrowVisible, setIsRepeatArrowVisible] = useState(false);

  const diffInSeconds = moment().diff(messageDate, "seconds");

  const isOverdueMessage = id === undefined && diffInSeconds >= 60 * 60;

  const overdueMessageHandler = useCallback(() => {
    if (isOverdueMessage) {
      if (navigator.onLine && socket.readyState === WebSocket.OPEN) {
        delete message.userId;
        socket?.send(
          JSON.stringify({
            type: "chat_message",
            message,
          }),
        );
        showToast({ message: "Resending a message", type: "info" });
      } else {
        showToast({ message: "Something went wrong", type: "error" });
      }
    }
  }, [isOverdueMessage, message, socket]);

  return (
    <div className={classNames({ "chat-right": isAdminSender })}>
      <div className="d-inline-block">
        <div className="d-flex chat-type mb-3">
          <div className="chat-msg" style={{ maxWidth: "500px" }}>
            <div
              style={
                isOverdueMessage
                  ? { border: "1px solid red", cursor: "pointer", position: "relative" }
                  : {}
              }
              className={`text-muted small px-4 py-2 bg-light rounded mb-1 position-relative custom-chat-msg ${isHighlighted ? "highlighted" : ""}`}
              onMouseEnter={() => setIsRepeatArrowVisible(true)}
              onMouseLeave={() => setIsRepeatArrowVisible(false)}
              onClick={overdueMessageHandler}
            >
              {isOverdueMessage && (
                <FaArrowRotateRight
                  style={{ position: "absolute", left: "-24px", bottom: "6px", fontSize: "18px" }}
                />
              )}
              {reply_to && (
                <div
                  className="bg-white border rounded p-2"
                  onClick={() => scrollToMessage(reply_to.id)}
                >
                  <div className="single-line-text">
                    {reply_to.text || reply_to.files[0]?.filename || (
                      <span className="first-letter-capital" style={{ display: "inline-block" }}>
                        {reply_to.data?.type.replaceAll("_", " ")}
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div style={{ color: data ? "#5EB6C1" : "#212529", whiteSpace: "break-spaces" }}>
                {data ? (
                  <span className="first-letter-capital" style={{ display: "inline-block" }}>
                    {data.type.replaceAll("_", " ")}
                  </span>
                ) : (
                  formatTextWithLinks(text)
                )}
              </div>
              <div className="d-flex gap-2 flex-wrap">
                {files.map(file => (
                  <Link to={file.safe_url} key={file.id} target="_blank" rel="noopener noreferrer">
                    {file.mime_type === "application/pdf" ? (
                      <div>
                        <FaRegFilePdf size={50} />
                        <p>{file.filename}</p>
                      </div>
                    ) : (
                      <img
                        src={file.safe_url + "?y=50"}
                        style={{ height: "50px" }}
                        crossOrigin="use-credentials"
                      />
                    )}
                  </Link>
                ))}
              </div>
              <div
                className="position-absolute top-0"
                style={isAdminSender ? { left: "5px" } : { right: "5px" }}
              >
                <div>
                  {(!isAdminSender || is_liked) && (
                    <FaHeart
                      style={{
                        color: is_liked ? "red" : "lightgray",
                        cursor: !isAdminSender ? "pointer" : "default",
                      }}
                      onClick={!isAdminSender ? () => setLikeHandler(message) : undefined}
                    />
                  )}
                </div>
                {isRepeatArrowVisible && !isAdminSender && (
                  <div>
                    <FiCornerUpLeft
                      style={{ color: "#000000", cursor: "pointer" }}
                      onClick={() => setMessageToWhichAnswer(message)}
                    />
                  </div>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {isAdminSender &&
                  uuid &&
                  id &&
                  (is_read ? (
                    <MdDoneAll style={{ marginTop: "2px", marginRight: "5px" }} />
                  ) : (
                    <MdDone style={{ marginTop: "2px", marginRight: "5px" }} />
                  ))}
                {isAdminSender && uuid && !id && !isOverdueMessage && (
                  <Loader size={13} style={{ marginTop: "2px", marginRight: "5px" }} />
                )}
                <small className="text-muted msg-time">{formattedDate}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ChatMessage);
