import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import classNames from "classnames";
import { FiTrash } from "react-icons/fi";
import { BsPencil } from "react-icons/bs";

import DraggableItem from "../../components/dnd/DraggableItem";
import {
  getTipsListThunk,
  addTipsThunk,
  updateTipsThunk,
  deleteTipsThunk,
  updateTipsListThunk,
} from "../../store/actions/content";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";

import Editor from "../../components/Editor/Editor";

const defaultValues = {
  title: "",
  text: "",
  is_active: true,
  is_visible_on_website: true,
  is_visible_in_app: true,
};
const validationSchema = Yup.object().shape({
  title: Yup.string().required("The title is required."),
  text: Yup.string().required("The text is required."),
});
const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues,
};

export default function Tips() {
  const dispatch = useDispatch();
  const tipsList = useSelector(s => s.content.tipsList);
  const [renderTips, setRenderTips] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingTips, setIsEditingTips] = useState(false);

  useEffect(() => {
    dispatch(getTipsListThunk());
  }, [dispatch]);

  useEffect(() => {
    setRenderTips(tipsList);
  }, [tipsList]);

  const methods = useForm(formOptions);
  const { handleSubmit, formState, reset, register } = methods;
  const { errors, isSubmitting } = formState;

  const onSubmit = async tipsData => {
    if (isEditingTips) {
      await dispatch(updateTipsThunk({ tipsData }));
    } else {
      await dispatch(updateTipsListThunk({ tipsList: renderTips, isNewFaq: true }));
      await dispatch(addTipsThunk({ tipsData }));
    }

    setIsModalOpen(false);
    reset(defaultValues);
    dispatch(getTipsListThunk());
  };

  const onDelete = id => {
    dispatch(
      showSubmitConfirmation({
        message: `Are you sure want to delete this tips?`,
        onConfirm: async () => {
          await dispatch(deleteTipsThunk({ id }));
          const res = await dispatch(getTipsListThunk());

          if (res.payload) dispatch(updateTipsListThunk({ tipsList: res.payload.data }));
        },
      }),
    );
  };

  const onEdit = async faqData => {
    setIsEditingTips(true);
    setIsModalOpen(true);
    reset(faqData);
  };

  const onModalHide = () => {
    setIsEditingTips(false);
    setIsModalOpen(false);
    reset(defaultValues);
    methods.clearErrors();
  };

  const moveItem = useCallback((fromIndex, toIndex) => {
    setRenderTips(prevFaqs => {
      const updatedFaqs = [...prevFaqs];
      const [movedItem] = updatedFaqs.splice(fromIndex, 1);
      updatedFaqs.splice(toIndex, 0, movedItem);

      return updatedFaqs;
    });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="layout-specing">
          <div className="d-md-flex justify-content-between">
            <div>
              <h5 className="mb-0">Tips</h5>

              <nav aria-label="breadcrumb" className="d-inline-block mt-1">
                <ul className="breadcrumb breadcrumb-muted bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/">AOSmiles</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Tips
                  </li>
                </ul>
              </nav>
            </div>

            <div className="mt-4 mt-sm-0">
              <Link to="#" className="btn btn-primary" onClick={() => setIsModalOpen(true)}>
                Add Tips
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="mt-4">
              <div className="card p-4 rounded shadow border-0">
                <h6 className="mb-0">Tips from a dentist</h6>
                <div className="accordion mt-4">
                  {renderTips.map((item, index) => {
                    return (
                      <DraggableItem
                        key={item.id}
                        index={index}
                        item={item}
                        moveItem={moveItem}
                        type="TIPS_ITEM"
                        onDrop={() => {
                          dispatch(updateTipsListThunk({ tipsList: renderTips }));
                        }}
                      >
                        <div className="d-flex flex-row gap-4 mt-2">
                          <div className="accordion-item border rounded flex-1">
                            <h2 className="accordion-header">
                              <button
                                className={`${activeIndex === item.id ? "" : "collapsed"} accordion-button border-0 bg-light`}
                                type="button"
                                onClick={() =>
                                  setActiveIndex(activeIndex === item.id ? null : item.id)
                                }
                              >
                                {item.title}
                              </button>
                            </h2>
                            <div
                              className={`${activeIndex === item.id ? "show" : ""} accordion-collapse border-0 collapse`}
                            >
                              <div
                                className="accordion-body text-muted"
                                dangerouslySetInnerHTML={{ __html: item.text }}
                              ></div>
                            </div>
                          </div>
                          <div className="d-flex flex-row gap-1 pt-1">
                            <div
                              className="btn btn-icon btn-pills btn-soft-success"
                              onClick={() => onEdit(item)}
                            >
                              <BsPencil />
                            </div>
                            <div
                              className="btn btn-icon btn-pills btn-soft-danger col-1"
                              onClick={() => onDelete(item.id)}
                            >
                              <FiTrash />
                            </div>
                          </div>
                        </div>
                      </DraggableItem>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={isModalOpen} onHide={onModalHide} centered size="xl">
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {isEditingTips ? "Edit Tips" : "Add Tips"}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Title <span className="text-danger">*</span>
                    </label>
                    <input
                      id="title"
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.title,
                      })}
                      placeholder="Title :"
                      {...register("title")}
                    />
                    <div className="invalid-feedback">{errors.title?.message}</div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Text <span className="text-danger">*</span>
                    </label>
                    <Editor
                      defaultValue={methods.watch("text") || ""}
                      onTextChange={content => {
                        methods.setValue("text", content === "<p><br></p>" ? "" : content, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      }}
                    />
                    <div className={errors.text && "is-invalid"}></div>
                    <div className="invalid-feedback">{errors.text?.message}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3 form-group">
                      <input
                        className={classNames("form-check-input", {
                          "is-invalid": errors.is_active,
                        })}
                        type="checkbox"
                        value=""
                        id="is_active"
                        {...register("is_active")}
                      />
                      <label className="form-check-label ps-2" htmlFor="is_active">
                        Is active
                      </label>
                    </div>
                    <div className="mb-3 form-group">
                      <input
                        className={classNames("form-check-input", {
                          "is-invalid": errors.is_visible_on_website,
                        })}
                        type="checkbox"
                        value=""
                        id="is_visible_on_website"
                        {...register("is_visible_on_website")}
                      />
                      <label className="form-check-label ps-2" htmlFor="is_visible_on_website">
                        Is visible on website
                      </label>
                    </div>
                    <div className="mb-3 form-group">
                      <input
                        className={classNames("form-check-input", {
                          "is-invalid": errors.is_visible_in_app,
                        })}
                        type="checkbox"
                        value=""
                        id="is_visible_in_app"
                        {...register("is_visible_in_app")}
                      />
                      <label className="form-check-label ps-2" htmlFor="is_visible_in_app">
                        Is visible in app
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 text-end">
                  <button className="btn btn-primary" disabled={isSubmitting || !formState.isDirty}>
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    {isEditingTips ? "Edit Tips" : "Add Tips"}
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}
