import { createSlice } from "@reduxjs/toolkit";
import { getUserProfile, login } from "../actions/auth";
import { history } from "../../utils/history";
import { ROLES } from "../../config/accessControlConfig";

const initialState = {
  isLoggedIn: false,
  error: null,
  tokens: null,
  user: {},
  permissions: [],
};

export const authSliceName = "auth";

export const authSlice = createSlice({
  name: authSliceName,
  initialState,
  reducers: {
    logout: () => {
      const { from } = history.location.state || { from: { pathname: "/login" } };
      history.navigate(from);

      return {
        ...initialState,
      };
    },
    changeToken: (state, action) => {
      state.tokens.access_token = action.payload.access_token;
      state.tokens.refresh_token = action.payload.refresh_token;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        const { data, permissions } = action.payload;
        state.isLoggedIn = true;
        state.error = null;
        state.user = {
          display: data.display,
          isDoctor: data.is_doctor,
          isSuperuser: data.is_superuser,
          username: data.username,
          scope: data.scope,
        };
        state.tokens = {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          expires_in: data.expires_in,
        };
        state.permissions = permissions;
        state.user.role = data.is_doctor
          ? ROLES.DOCTOR
          : data.is_superuser
            ? ROLES.SUPER_ADMIN
            : ROLES.ADMIN;
        state.user.data = data;
        state.user.photo = data.photo_details;
        state.user.userId = data.user_id;
        const { from } = history.location.state || { from: { pathname: "/" } };
        history.navigate(from);
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.user.role = data.is_doctor
          ? ROLES.DOCTOR
          : data.is_superuser
            ? ROLES.SUPER_ADMIN
            : ROLES.ADMIN;
        state.user.data = data;
        state.user.photo = data.photo_details;
        state.user.userId = data.user_id;
      })
      .addCase(login.rejected, (state, action) => {
        return {
          ...initialState,
          error: action.payload.message,
        };
      });
  },
});

export const { logout, changeToken } = authSlice.actions;

export default authSlice.reducer;
