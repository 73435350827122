import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import classNames from "classnames";
import { FiTrash } from "react-icons/fi";
import { BsPencil } from "react-icons/bs";

import DraggableItem from "../../components/dnd/DraggableItem";
import {
  addFaqThunk,
  deleteFaqThunk,
  getFaqListThunk,
  updateFaqThunk,
  updateFaqsThunk,
} from "../../store/actions/content";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";

const defaultValues = {
  question: "",
  answer: "",
  is_active: true,
  is_visible_on_website: true,
  is_visible_in_app: true,
};
const validationSchema = Yup.object().shape({
  question: Yup.string().required("The question is required."),
  answer: Yup.string().required("The answer is required."),
});
const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues,
};

export default function Faqs() {
  const dispatch = useDispatch();
  const faqsList = useSelector(s => s.content.faqsList);
  const [renderFaqs, setRenderFaqs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingFaq, setIsEditingFaq] = useState(false);

  useEffect(() => {
    dispatch(getFaqListThunk());
  }, [dispatch]);

  useEffect(() => {
    setRenderFaqs(faqsList);
  }, [faqsList]);

  const methods = useForm(formOptions);
  const { handleSubmit, formState, reset, register } = methods;
  const { errors, isSubmitting } = formState;

  const onSubmit = async faqData => {
    if (isEditingFaq) {
      await dispatch(updateFaqThunk({ faqData }));
    } else {
      await dispatch(updateFaqsThunk({ faqs: renderFaqs, isNewFaq: true }));
      await dispatch(addFaqThunk({ faqData }));
    }

    setIsModalOpen(false);
    reset(defaultValues);
    dispatch(getFaqListThunk());
  };

  const onDelete = id => {
    dispatch(
      showSubmitConfirmation({
        message: `Are you sure want to delete this question?`,
        onConfirm: async () => {
          await dispatch(deleteFaqThunk({ id }));
          const res = await dispatch(getFaqListThunk());

          if (res.payload) dispatch(updateFaqsThunk({ faqs: res.payload.data }));
        },
      }),
    );
  };

  const onEdit = async faqData => {
    setIsEditingFaq(true);
    setIsModalOpen(true);
    reset(faqData);
  };

  const onModalHide = () => {
    setIsEditingFaq(false);
    setIsModalOpen(false);
    reset(defaultValues);
    methods.clearErrors();
  };

  const moveItem = useCallback((fromIndex, toIndex) => {
    setRenderFaqs(prevFaqs => {
      const updatedFaqs = [...prevFaqs];
      const [movedItem] = updatedFaqs.splice(fromIndex, 1);
      updatedFaqs.splice(toIndex, 0, movedItem);

      return updatedFaqs;
    });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="layout-specing">
          <div className="d-md-flex justify-content-between">
            <div>
              <h5 className="mb-0">FAQs</h5>

              <nav aria-label="breadcrumb" className="d-inline-block mt-1">
                <ul className="breadcrumb breadcrumb-muted bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/">AOSmiles</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    FAQs
                  </li>
                </ul>
              </nav>
            </div>

            <div className="mt-4 mt-sm-0">
              <Link to="#" className="btn btn-primary" onClick={() => setIsModalOpen(true)}>
                Add Question
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="mt-4">
              <div className="card p-4 rounded shadow border-0">
                <h6 className="mb-0">Frequently Asked Questions</h6>
                <div className="accordion mt-4">
                  {renderFaqs.map((item, index) => {
                    return (
                      <DraggableItem
                        key={item.id}
                        index={index}
                        item={item}
                        moveItem={moveItem}
                        type="FAQ_ITEM"
                        onDrop={() => {
                          dispatch(updateFaqsThunk({ faqs: renderFaqs }));
                        }}
                      >
                        <div className="d-flex flex-row gap-4 mt-2">
                          <div className="accordion-item border rounded flex-1">
                            <h2 className="accordion-header">
                              <button
                                className={`${activeIndex === item.id ? "" : "collapsed"} accordion-button border-0 bg-light`}
                                type="button"
                                onClick={() =>
                                  setActiveIndex(activeIndex === item.id ? null : item.id)
                                }
                              >
                                {item.question}
                              </button>
                            </h2>
                            <div
                              className={`${activeIndex === item.id ? "show" : ""} accordion-collapse border-0 collapse`}
                            >
                              <div className="accordion-body text-muted">{item.answer}</div>
                            </div>
                          </div>
                          <div className="d-flex flex-row gap-1 pt-1">
                            <div
                              className="btn btn-icon btn-pills btn-soft-success"
                              onClick={() => onEdit(item)}
                            >
                              <BsPencil />
                            </div>
                            <div
                              className="btn btn-icon btn-pills btn-soft-danger col-1"
                              onClick={() => onDelete(item.id)}
                            >
                              <FiTrash />
                            </div>
                          </div>
                        </div>
                      </DraggableItem>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={isModalOpen} onHide={onModalHide} centered>
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {isEditingFaq ? "Edit Question" : "Add Question"}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Question <span className="text-danger">*</span>
                    </label>
                    <input
                      id="name"
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.question,
                      })}
                      placeholder="Question :"
                      {...register("question")}
                    />
                    <div className="invalid-feedback">{errors.question?.message}</div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label">
                      Answer <span className="text-danger">*</span>
                    </label>
                    <textarea
                      id="comments"
                      rows="4"
                      className={classNames("form-control", {
                        "is-invalid": errors.answer,
                      })}
                      placeholder="Answer :"
                      {...register("answer")}
                    ></textarea>
                    <div className="invalid-feedback">{errors.answer?.message}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3 form-group">
                      <input
                        className={classNames("form-check-input", {
                          "is-invalid": errors.is_active,
                        })}
                        type="checkbox"
                        value=""
                        id="is_active"
                        {...register("is_active")}
                      />
                      <label className="form-check-label ps-2" htmlFor="is_active">
                        Is active
                      </label>
                    </div>
                    <div className="mb-3 form-group">
                      <input
                        className={classNames("form-check-input", {
                          "is-invalid": errors.is_visible_on_website,
                        })}
                        type="checkbox"
                        value=""
                        id="is_visible_on_website"
                        {...register("is_visible_on_website")}
                      />
                      <label className="form-check-label ps-2" htmlFor="is_visible_on_website">
                        Is visible on website
                      </label>
                    </div>
                    <div className="mb-3 form-group">
                      <input
                        className={classNames("form-check-input", {
                          "is-invalid": errors.is_visible_in_app,
                        })}
                        type="checkbox"
                        value=""
                        id="is_visible_in_app"
                        {...register("is_visible_in_app")}
                      />
                      <label className="form-check-label ps-2" htmlFor="is_visible_in_app">
                        Is visible in app
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 text-end">
                  <button className="btn btn-primary" disabled={isSubmitting || !formState.isDirty}>
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    {isEditingFaq ? "Edit Question" : "Add Question"}
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}
