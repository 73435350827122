import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import query from "querystringify";
import moment from "moment";
import { useVirtualizer } from "@tanstack/react-virtual";
import { DebounceInput } from "react-debounce-input";
import classNames from "classnames";

import { getChatsListThunk } from "../../store/actions/chat";
import { history } from "../../utils/history";
import {
  addCachedChats,
  removeUnreadLikeChatId,
  setActiveChatId,
  setIsFetching,
} from "../../store/reducers/chatSlice";
import { getPatientProfilesList } from "../../api/patient";
import { getChatPatientWithDoctor } from "../../api/chat";
import BlinkingHeart from "../BlinkingHeart/BlinkingHeart";
import {
  DB_NAME,
  getDataFromIndexDB,
  KEY_PATH,
  saveDataToIndexDB,
  STORE_NAME,
} from "../../utils/indexedDB";

export default function ChatsList() {
  const dispatch = useDispatch();
  const chatsList = useSelector(s => s.chat.chatsList);
  const activeChatId = useSelector(s => s.chat.activeChatId);
  const isFetching = useSelector(s => s.chat.isFetching);
  const next = useSelector(s => s.chat.next);
  const { isDoctor, display: userName } = useSelector(s => s.auth.user);

  const queries = useMemo(() => query.parse(history.location.search), [history.location.search]);

  useEffect(() => {
    dispatch(getChatsListThunk({ queryObj: queries, isFirstLoad: true }));
  }, [dispatch, queries]);

  useEffect(() => {
    if (!activeChatId || chatsList.length) return;

    async function fetchChats() {
      if (!queries.search) {
        const cachedChats = await getDataFromIndexDB({
          dbName: DB_NAME.chatsListDB,
          storeName: STORE_NAME.chatsList,
          keyPath: KEY_PATH.chatId,
          requestSelector: activeChatId,
        });

        if (cachedChats.length > 0) {
          dispatch(addCachedChats(cachedChats));
        }
      }

      dispatch(getChatsListThunk({ queryObj: queries, isFirstLoad: true }));
    }

    fetchChats();
  }, [activeChatId, dispatch, chatsList.length, queries]);

  useEffect(() => {
    if (!activeChatId || chatsList.length === 0) return;

    async function cacheChats() {
      await saveDataToIndexDB({
        dbName: DB_NAME.chatsListDB,
        storeName: STORE_NAME.chatsList,
        keyPath: KEY_PATH.chatId,
        requestSelector: activeChatId,
        data: chatsList.slice(0, 100),
      });
    }

    cacheChats();
  }, [activeChatId, chatsList]);

  const isWillUnmountRef = useRef(false);

  useEffect(() => {
    isWillUnmountRef.current = false;

    return () => {
      isWillUnmountRef.current = true;
    };
  }, []);

  useEffect(() => {
    if (queries.chat_id || localStorage.getItem("activeChatId")) {
      dispatch(removeUnreadLikeChatId(+queries.chat_id));
      dispatch(setActiveChatId(+queries.chat_id || +localStorage.getItem("activeChatId")));
    } else if (chatsList[0]) {
      dispatch(setActiveChatId(chatsList[0].id));
      dispatch(removeUnreadLikeChatId(chatsList[0].id));
    }

    return () => {
      if (isWillUnmountRef.current) {
        dispatch(setActiveChatId(null));
      }
    };
  }, [dispatch, queries.chat_id, chatsList]);

  const setQueryChatId = id => {
    localStorage.setItem("activeChatId", id);
    history.navigate({
      pathname: history.location.pathname,
      search: query.stringify({ ...queries, chat_id: id }),
    });
  };

  const parentRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: chatsList.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 60,
    overscan: 5,
  });
  const items = rowVirtualizer.getVirtualItems();

  const [searchPatientValue, setSearchPatientValue] = useState(queries.search);
  const [patientsList, setPatientsList] = useState([]);

  const handlePatientSearch = event => {
    const searchValue = event.target.value;

    if (isDoctor) {
      setSearchPatientValue(searchValue);
    } else {
      const params = new URLSearchParams(location.search);
      const currentParams = Object.fromEntries(params.entries());

      if (searchValue || currentParams.search) {
        if (searchValue !== currentParams.search) {
          if (searchValue) {
            params.set("search", searchValue);
          } else {
            params.delete("search");
          }

          history.navigate(
            { pathname: location.pathname, search: params.toString() },
            { replace: true },
          );
        }
      }
    }
  };

  useEffect(() => {
    const lastItem = rowVirtualizer.getVirtualItems().at(-1);

    if (!lastItem || searchPatientValue) return;

    if (lastItem.index >= chatsList.length - 1 && next && !isFetching) {
      dispatch(setIsFetching(true));
      dispatch(getChatsListThunk({ queryObj: queries }));
    }
  }, [
    rowVirtualizer.getVirtualItems(),
    chatsList,
    next,
    isFetching,
    dispatch,
    queries,
    searchPatientValue,
  ]);

  useEffect(() => {
    if (!queries.search) setSearchPatientValue("");
  }, [queries]);

  useEffect(() => {
    const getPatientsList = async () => {
      try {
        if (searchPatientValue) {
          const response = await getPatientProfilesList({ search: searchPatientValue });
          setPatientsList(response.data.results);
        }
      } catch (e) {
        console.error(e);
      }
    };

    getPatientsList();
  }, [searchPatientValue]);

  const createNewChat = async patientId => {
    try {
      if (searchPatientValue) {
        const response = await getChatPatientWithDoctor({ patient_id: patientId });

        if (response.data.id) {
          dispatch(setActiveChatId(response.data.id));
          setQueryChatId(response.data.id);
        }
      }
    } catch (e) {
      console.error(e);
    }

    setSearchPatientValue("");
    setPatientsList([]);
  };

  return (
    <div className="col-xl-3 col-lg-5 col-md-5 col-12 mt-4">
      <div className="card border-0 rounded shadow d-flex flex-column" style={{ height: "100%" }}>
        <div className="text-center p-4 border-bottom">
          <h5 className="mt-3 mb-1">{userName}</h5>
          <p className="text-muted mb-0">AOSmiles</p>

          <div className="search-bar p-0 ms-2 mt-1">
            <div id="search" className="menu-search mb-0">
              <form role="search" id="searchform" className="searchform">
                <div>
                  <DebounceInput
                    type="text"
                    debounceTimeout={700}
                    className="form-control border rounded-pill"
                    placeholder="Search patient..."
                    value={searchPatientValue}
                    onChange={handlePatientSearch}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          ref={parentRef}
          className="p-2 chat chat-list flex-grow-1"
          style={{ height: "550px", overflowY: "auto", position: "relative" }}
        >
          {searchPatientValue ? (
            patientsList.length ? (
              patientsList.map(patient => {
                return (
                  <div
                    key={patient.id}
                    className="d-flex chat-list p-2 rounded"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => createNewChat(patient.id)}
                  >
                    <div className="overflow-hidden flex-1 ms-2">
                      <div className="d-flex justify-content-between">
                        <h6 className="text-dark mb-0 d-block">{`${patient.first_name} ${patient.last_name}`}</h6>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center">There are no such users...</div>
            )
          ) : (
            <div style={{ height: `${rowVirtualizer.getTotalSize()}px`, position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  transform: `translateY(${items[0]?.start ?? 0}px)`,
                }}
              >
                {rowVirtualizer.getVirtualItems().map(virtualItem => {
                  const chat = chatsList[virtualItem.index];

                  return (
                    <div
                      key={chat.id}
                      data-index={virtualItem.index}
                      ref={virtualItem.measureRef}
                      className={`d-flex chat-list p-2 rounded ${
                        chat.id === activeChatId ? "active" : ""
                      }`}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => setQueryChatId(chat.id)}
                    >
                      <div className="overflow-hidden flex-1 ms-2">
                        <div className="d-flex justify-content-between">
                          <h6 className="text-dark mb-0 d-block">{chat.patient_name}</h6>
                          {chat.last_message && <Time date={chat.last_message?.created_at} />}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            className={classNames("text-truncate", {
                              "text-dark h6 mb-0": !!chat.count_unread_from_patient,
                              "text-muted": !chat.count_unread_from_patient,
                            })}
                          >
                            {chat.last_message?.text || chat.last_message?.files[0]?.filename || (
                              <span
                                className="first-letter-capital"
                                style={{ display: "inline-block" }}
                              >
                                {chat.last_message?.data?.type.replaceAll("_", " ")}
                              </span>
                            )}
                          </div>
                          <div className="d-flex gap-2">
                            <BlinkingHeart chatId={chat.id} />
                            {!!chat.count_unread_from_patient && (
                              <span className="badge rounded-pill bg-soft-danger">
                                {chat.count_unread_from_patient}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const formatTime = date => {
  const now = moment();
  const messageTime = moment(date);
  const diffMinutes = now.diff(messageTime, "minutes");
  const diffHours = now.diff(messageTime, "hours");
  const diffDays = now.diff(messageTime, "days");

  let timeAgo;

  if (diffMinutes < 1) timeAgo = `now`;
  else if (diffMinutes < 60) timeAgo = `${diffMinutes} Min`;
  else if (diffHours < 24) timeAgo = `${diffHours} Hours`;
  else timeAgo = `${diffDays} Day${diffDays === 1 ? "" : "s"}`;

  return timeAgo;
};

const Time = ({ date }) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const timerId = setInterval(() => setCount(prev => prev + 1), 1000);

    return () => clearInterval(timerId);
  }, []);

  return <small className="text-muted">{formatTime(date)}</small>;
};
