import { createSlice } from "@reduxjs/toolkit";
import { getStaffList, getStaffProfile, staffSliceName } from "../actions/staff";

const initialState = {
  count: 0,
  page: 1,
  page_size: 50,
  next: null,
  previous: null,
  ordering: "first_name",
  list: [],
  staffProfile: {},
};

export const staffSlice = createSlice({
  name: staffSliceName,
  initialState,
  reducers: {
    resetStaffState: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getStaffList.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.count = action.payload.data.count;
        state.next = action.payload.data.next;
        state.previous = action.payload.data.previous;
        state.page = action.payload.page;
      })
      .addCase(getStaffProfile.fulfilled, (state, action) => {
        state.staffProfile = action.payload.data;
      });
  },
});
export const { resetStaffState } = staffSlice.actions;

export default staffSlice.reducer;
