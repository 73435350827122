import React, { useEffect, useState } from "react";
import { isEqual } from "lodash";

import { defaultTheme } from "react-select";
import { Button } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { FiArrowRight, FiTrash } from "react-icons/fi";
import { AsyncPaginate } from "react-select-async-paginate";
import { Link } from "react-router-dom";
import { CiSettings } from "react-icons/ci";

const { colors } = defaultTheme;

const selectStyles = {
  control: provided => ({
    ...provided,
    minWidth: 240,
    margin: 8,
    border: 0,
  }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
};

const AsyncPopoutSelect = ({
  changeActiveTab,
  control,
  rules,
  name,
  className,
  getOptions,
  limit,
  defaultOption,
  initialOptionData,
  isMulti,
  placeholder = "Search...",
  handleOpenPermissionsOfCanvas,
  handleOnChange,
  handleRemoveItem,
}) => {
  const [selectOptions, setSelectOptions] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (initialOptionData?.length) {
      const options = initialOptionData.map(option => ({
        value: option.managed_detail?.id,
        label: `${option.managed_detail?.first_name} ${option.managed_detail?.last_name}`,
        data: option,
      }));

      if (!isEqual(selectOptions, options)) {
        setSelectOptions(options);
      }
    } else if (Array.isArray(initialOptionData) && selectOptions?.length) {
      setSelectOptions(null);
    }
  }, [initialOptionData, selectOptions]);

  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await getOptions({ search: search, page, page_size: limit });

    return {
      options: defaultOption ? [defaultOption, ...response.options] : response.options,
      hasMore: response.hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const onChange = async option => {
          if (handleOnChange) {
            await handleOnChange(option);
            setSelectOptions(selectOptions ? [...selectOptions, option] : [option]);
            setIsOpen(false);
          } else {
            setSelectOptions(selectOptions ? [...selectOptions, option] : [option]);
            field.onChange(isMulti ? [...field.value, option.value] : option?.value || "");
            setIsOpen(false);
          }
        };

        return (
          <>
            <Dropdown
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              className={className}
              target={
                <div className={className}>
                  <Button
                    variant={isOpen ? "dark" : "light"}
                    onClick={() => setIsOpen(prev => !prev)}
                    size="sm"
                  >
                    <span className="fs-6">
                      {"Add family member"}
                      <ChevronDown />
                    </span>
                  </Button>
                </div>
              }
            >
              <AsyncPaginate
                autoFocus
                backspaceRemovesValue={false}
                components={{ DropdownIndicator, IndicatorSeparator: null }}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                debounceTimeout={500}
                additional={{
                  page: 1,
                }}
                menuIsOpen
                loadOptions={loadOptions}
                onChange={onChange}
                placeholder={placeholder}
                styles={selectStyles}
                tabSelectsValue={false}
              />
            </Dropdown>
            {error && <div className="invalid-feedback d-block">{error.message}</div>}
            <ul className="list-unstyled">
              {selectOptions?.map(item => {
                return (
                  <li
                    className="mt-3 mb-2 ms-0 col-12 d-flex align-items-center justify-content-between"
                    key={item?.value}
                  >
                    <div className="d-flex align-items-center">
                      <Link
                        to={`/patients/${item.value}`}
                        className="text-dark"
                        onClick={() => changeActiveTab()}
                      >
                        <FiArrowRight className="text-primary" />
                        <span className="ms-1">{item?.label ? item.label : ""}</span>
                        <span className="ms-1">
                          {item?.data?.birth_date ? `(${item.data.birth_date})` : ""}
                        </span>
                      </Link>
                    </div>
                    <div className="d-flex gap-2">
                      <div
                        className="btn btn-icon btn-pills btn-soft-success"
                        onClick={() => handleOpenPermissionsOfCanvas(item.data.id)}
                      >
                        <CiSettings />
                      </div>
                      <div
                        className="btn btn-icon btn-pills btn-soft-danger"
                        onClick={() => handleRemoveItem(item)}
                      >
                        <FiTrash />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        );
      }}
    />
  );
};

const Menu = props => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  );
};

const Blanket = props => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div className="position-relative">
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = p => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
);
const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);

export default AsyncPopoutSelect;
