import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { getPatientProfilesList } from "../../api/patient";
import AsyncPopoutSelect from "./AsyncPopoutSelect";
import PermissionsForm from "./PermissionsForm";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import {
  createPatientFamilyAccess,
  deletePatientFamilyAccessItem,
  getFamilyAccessItemDetails,
  getPatientFamilyAccessList,
} from "../../store/actions/patient";
import { useDispatch, useSelector } from "react-redux";

const Family = ({ patientProfile, changeActiveTab }) => {
  const [isShowOfCanvas, setIsShowOfCanvas] = useState(false);
  const dispatch = useDispatch();
  const patientFamilyAccess = useSelector(s => s.patients.patientFamilyAccess);

  const validationSchema = Yup.object().shape({
    familyList: Yup.array(),
  });

  const defaultValues = {
    familyList: [],
  };

  const formOptions = { resolver: yupResolver(validationSchema), defaultValues };

  const { formState, control, watch, setValue } = useForm(formOptions);

  const { errors } = formState;

  const familyList = watch("familyList");

  useEffect(() => {
    if (patientProfile) {
      dispatch(getPatientFamilyAccessList(patientProfile.id));
    }
  }, [dispatch, patientProfile]);

  useEffect(() => {
    setValue(
      "familyList",
      patientFamilyAccess.list.map(item => item.managed),
    );
  }, [patientFamilyAccess, setValue]);

  const handleOnChange = useCallback(
    option => {
      return new Promise((resolve, reject) => {
        dispatch(
          showSubmitConfirmation({
            message: `Are you sure want to add patient: ${option?.data?.first_name} ${option?.data?.last_name} to family list?`,
            onConfirm: async () => {
              try {
                await dispatch(
                  createPatientFamilyAccess({ managed: option.value, manager: patientProfile?.id }),
                );
                await dispatch(getPatientFamilyAccessList(patientProfile.id));
                resolve();
              } catch (error) {
                reject(error);
              }
            },
            onCancel: () => {
              resolve();
            },
          }),
        );
      });
    },
    [dispatch, patientProfile],
  );

  const handleDeletePatientFromFamily = useCallback(
    option => {
      return new Promise((resolve, reject) => {
        dispatch(
          showSubmitConfirmation({
            message: `Are you sure want to delete patient: ${option?.data?.managed_detail?.first_name} ${option?.data?.managed_detail?.last_name} from family list?`,
            onConfirm: async () => {
              try {
                await dispatch(deletePatientFamilyAccessItem(option.data.id));
                await dispatch(getPatientFamilyAccessList(patientProfile.id));
                resolve();
              } catch (error) {
                reject(error);
              }
            },
            onCancel: () => {
              resolve();
            },
          }),
        );
      });
    },
    [dispatch, patientProfile],
  );

  const getPatientsData = useCallback(
    async requestObj => {
      return getPatientProfilesList(requestObj).then(response => {
        return {
          options: response.data.results
            .filter(item => item.id !== patientProfile.id && !familyList.includes(item.id))
            .map(option => ({
              value: option.id,
              label: `${option.first_name} ${option.last_name}`,
              data: option,
            })),
          hasMore: !!response.data.next,
        };
      });
    },
    [patientProfile, familyList],
  );

  const handleClosePermissionsForm = () => {
    setIsShowOfCanvas(false);
  };

  const handleOpenPermissionsForm = async id => {
    await dispatch(getFamilyAccessItemDetails(id));
    setIsShowOfCanvas(true);
  };

  return (
    <div>
      <form className="mt-4">
        <div className="col-lg-12">
          <div className="mb-3 form-group">
            <label className="form-label">Patient</label>
            <AsyncPopoutSelect
              name="familyList"
              placeholder="Select patient"
              control={control}
              getOptions={getPatientsData}
              limit={10}
              className={classNames("form-control", "p-0", "border-0", {
                "is-invalid": errors.patient_id,
              })}
              handleOnChange={handleOnChange}
              isMulti={true}
              changeActiveTab={changeActiveTab}
              handleOpenPermissionsOfCanvas={handleOpenPermissionsForm}
              initialOptionData={patientFamilyAccess?.list}
              handleRemoveItem={handleDeletePatientFromFamily}
            />
          </div>
        </div>
      </form>
      <PermissionsForm isShowEditForm={isShowOfCanvas} handleClose={handleClosePermissionsForm} />
    </div>
  );
};

export default Family;
