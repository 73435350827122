import { createSlice } from "@reduxjs/toolkit";
import {
  getTreatmentPlan,
  getTreatmentPlansList,
  getTreatmentStage,
  treatmentsSliceName,
} from "../actions/treatments";

const initialState = {
  treatmentPlans: {
    count: 0,
    page: 1,
    page_size: 20,
    next: null,
    previous: null,
    ordering: "-id",
    list: [],
    treatmentPlan: {},
  },
  treatmentStages: {
    count: 0,
    page: 1,
    page_size: 20,
    next: null,
    previous: null,
    list: [],
    treatmentStage: {},
  },
};

export const treatmentsSlice = createSlice({
  name: treatmentsSliceName,
  initialState,
  reducers: {
    resetTreatmentPlanState: state => {
      state.treatmentPlans.treatmentPlan = initialState.treatmentPlans.treatmentPlan;
    },
    resetTreatmentStageState: state => {
      state.treatmentStages.treatmentStage = initialState.treatmentStages.treatmentStage;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTreatmentPlansList.fulfilled, (state, action) => {
        state.treatmentPlans.list = action.payload.data.results;
        state.treatmentPlans.count = action.payload.data.count;
        state.treatmentPlans.next = action.payload.data.next;
        state.treatmentPlans.previous = action.payload.data.previous;
        state.treatmentPlans.page = action.payload.page;
      })
      .addCase(getTreatmentPlan.fulfilled, (state, action) => {
        state.treatmentPlans.treatmentPlan = action.payload.data;
      })
      .addCase(getTreatmentStage.fulfilled, (state, action) => {
        state.treatmentStages.treatmentStage = action.payload.data;
      });
  },
});

export const { resetTreatmentPlanState, resetTreatmentStageState } = treatmentsSlice.actions;

export default treatmentsSlice.reducer;
