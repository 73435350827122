import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

import { getDoctorsList } from "../../store/actions/doctor";
import { getSpecializations } from "../../store/actions/specialization";
import { getServices } from "../../store/actions/services";
import DoctorEditForm from "../../components/doctors/DoctorEditForm";
import { resetDoctorsState } from "../../store/reducers/doctorSlice";
import blankImagePath from "../../assets/images/blank-image-icon.png";
import ImageLoader from "../../components/FileLoader/ImageLoader";
import { controlledPages, permissions } from "../../config/accessControlConfig";
import { useAccess } from "../../hooks/useAccess";

export default function AddDoctor() {
  const dispatch = useDispatch();
  const specializations = useSelector(s => s.specializations.list);
  const services = useSelector(s => s.services.list);
  const doctorData = useSelector(s => s.doctors.list);
  const { hasPermission } = useAccess();

  useEffect(() => {
    dispatch(resetDoctorsState());
    dispatch(getSpecializations());
    dispatch(getServices());
    dispatch(getDoctorsList({ isWithQueries: false }));
  }, [dispatch]);
  const isCanAdd = hasPermission(controlledPages.addDoctor, permissions.add);
  const isCanEditUsername = hasPermission(controlledPages.doctor, permissions.editUsername);

  const getSpecializationsDoctorData = list => {
    return list.reduce((acc, specializationId, index) => {
      const specialization = specializations.find(item => item.id === specializationId)?.name;

      if (specialization) {
        acc += specialization + (index < list.length - 1 ? ", " : "");
      }

      return acc;
    }, "");
  };

  return (
    <>
      <div className="container-fluid">
        <div className="layout-specing">
          <div className="d-md-flex justify-content-between">
            <h5 className="mb-0">Add New Doctor</h5>

            <nav aria-label="breadcrumb" className="d-inline-block mt-4 mt-sm-0">
              <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">AOSmiles</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/doctors">Doctors</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add Doctor
                </li>
              </ul>
            </nav>
          </div>

          <div className="row">
            <div className="col-lg-8 mt-4">
              <div className="card border-0 p-4 rounded shadow">
                <DoctorEditForm
                  specializations={specializations}
                  services={services}
                  buttonText="Add Doctor"
                  isCanEdit={isCanAdd}
                  isCanEditUsername={isCanEditUsername}
                />
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className="card rounded border-0 shadow">
                <div className="p-4 border-bottom">
                  <h5 className="mb-0">Doctors List</h5>
                </div>
                <SimpleBar style={{ height: "660px" }}>
                  <ul className="list-unstyled mb-0 p-4 pt-0">
                    {doctorData.slice(0, 6).map(item => {
                      return (
                        <li
                          className="d-md-flex align-items-center text-center text-md-start mt-4 ms-0"
                          key={item.id}
                        >
                          <div className="position-relative">
                            <ImageLoader
                              src={item?.photo_details?.safe_url || blankImagePath}
                              className="avatar avatar-medium rounded-md shadow"
                              alt=""
                            />
                          </div>

                          <div className="ms-md-3 mt-4 mt-sm-0">
                            <Link to={`/doctors/${item.id}`} className="text-dark h6">
                              {`${item.first_name} ${item.last_name}`}
                            </Link>
                            <p className="text-muted my-1">
                              {getSpecializationsDoctorData(item.specializations)}
                            </p>
                            {/*<p className="text-muted mb-0">{item.exp}</p>*/}
                          </div>
                        </li>
                      );
                    })}

                    <li className="mt-4">
                      <Link to="/doctors" className="btn btn-primary">
                        All Doctors
                      </Link>
                    </li>
                  </ul>
                </SimpleBar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
