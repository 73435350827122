import React from "react";
import { Link } from "react-router-dom";
import { FiEye, FiTrash } from "react-icons/fi";
import moment from "moment/moment";
import FileLoader from "../FileLoader/FileLoader";

const FeedbackItem = ({ item, removeItem, showShortInfoModal }) => {
  const patient = item.patient_detail || null;

  const handleRemoveItem = () => {
    removeItem(item);
  };

  return (
    <tr>
      <th className="p-3">{item.id}</th>
      <td className="p-3">
        <Link to={`/patients/${patient.id}`} className="text-dark">
          <div className="d-flex align-items-center">
            <span className="ms-2">
              {patient ? (
                `${patient.first_name} ${patient.last_name}`
              ) : (
                <span className="text-decoration-underline text-muted">
                  {patient?.id ? "View Patient Profile" : ""}
                </span>
              )}
            </span>
          </div>
        </Link>
      </td>
      <td className="p-3">
        <div>{patient.phone}</div>
      </td>
      <td className="p-3">
        <div>{patient.email}</div>
      </td>
      {/*<td className="p-3">*/}
      {/*  <ul className="list-unstyled mb-0">*/}
      {/*    <li className="list-inline-item">*/}
      {/*      <i className="mdi mdi-star text-warning"></i>*/}
      {/*    </li>*/}
      {/*    <li className="list-inline-item">*/}
      {/*      <i className="mdi mdi-star text-warning"></i>*/}
      {/*    </li>*/}
      {/*    <li className="list-inline-item">*/}
      {/*      <i className="mdi mdi-star text-warning"></i>*/}
      {/*    </li>*/}
      {/*    <li className="list-inline-item">*/}
      {/*      <i className="mdi mdi-star text-warning"></i>*/}
      {/*    </li>*/}
      {/*    <li className="list-inline-item">*/}
      {/*      <i className="mdi mdi-star text-warning"></i>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</td>*/}
      <td className="p-3 text-muted">
        <span className="d-inline-block text-truncate" style={{ maxWidth: "150px" }}>
          {item.text}
        </span>
      </td>
      <td className="p-3">{moment(item.created_at).format("DD-MM-YYYY")}</td>
      <td className="p-3">{moment(item.created_at).format("HH:mm")}</td>
      <td className="p-3">
        <div className="mb-2 d-flex flex-wrap">
          {item?.files_detail.map((item, index) => {
            const attachmentUrl = item.safe_url;

            return (
              <Link
                to={attachmentUrl}
                key={index}
                className="position-relative m-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FileLoader
                  item={item}
                  isShowRemoveButton={false}
                  width={70}
                  height={70}
                  fileSize={70}
                />
              </Link>
            );
          })}
        </div>
      </td>
      <td className="text-end p-3">
        <div className="d-flex patientsActions">
          <div
            className="btn btn-icon btn-pills btn-soft-primary"
            onClick={() => showShortInfoModal(item.id)}
          >
            <FiEye />
          </div>
          <div className="btn btn-icon btn-pills btn-soft-danger" onClick={handleRemoveItem}>
            <FiTrash />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default FeedbackItem;
