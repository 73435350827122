export const ROLES = {
  ADMIN: "admin",
  SUPER_ADMIN: "superAdmin",
  DOCTOR: "doctor",
};

export const controlledPages = {
  doctors: "doctors",
  addDoctor: "addDoctor",
  doctor: "doctor",
  staff: "staff",
  staffProfile: "staffProfile",
  addStaff: "addStaff",
};

export const controlledRoutes = {
  [controlledPages.doctors]: "/doctors",
  [controlledPages.addDoctor]: "/add-doctor",
  [controlledPages.doctor]: "/doctors/:id",
  [controlledPages.staff]: "/staff",
  [controlledPages.staffProfile]: "/staff/:id",
  [controlledPages.addStaff]: "/add-staff",
};

export const permissions = {
  view: "view",
  edit: "edit",
  delete: "delete",
  add: "add",
  editUsername: "editUsername",
};

export const entityLevel = {
  self: "self",
  any: "any",
};

export const controlledRoutesPaths = Object.values(controlledRoutes);
export const allPermissions = Object.values(permissions);

export const accessControlConfig = {
  [ROLES.SUPER_ADMIN]: {
    allowAll: true,
  },
  [ROLES.ADMIN]: {
    routes: [
      controlledRoutes.doctor,
      controlledRoutes.doctors,
      controlledRoutes.addDoctor,
      controlledRoutes.staff,
      controlledRoutes.staffProfile,
    ],
    permissions: {
      [controlledPages.doctors]: allPermissions,
      [controlledPages.addDoctor]: allPermissions,
      [controlledPages.doctor]: allPermissions,
      [controlledPages.staff]: allPermissions,
      [controlledPages.staff]: [{ [permissions.view]: [entityLevel.any] }],
      [controlledPages.staffProfile]: [{ [permissions.view]: [entityLevel.any] }],
    },
  },
  [ROLES.DOCTOR]: {
    routes: [
      controlledRoutes.doctor,
      controlledRoutes.doctors,
      controlledRoutes.staff,
      controlledRoutes.staffProfile,
    ],
    permissions: {
      [controlledPages.doctors]: [{ [permissions.view]: [entityLevel.any] }],
      [controlledPages.doctor]: [
        { [permissions.view]: [entityLevel.any] },
        { [permissions.edit]: [entityLevel.self] },
      ],
      [controlledPages.staff]: [{ [permissions.view]: [entityLevel.any] }],
      [controlledPages.staffProfile]: [{ [permissions.view]: [entityLevel.any] }],
    },
  },
};
