import React, { createContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";

import { API_URL } from "../../config/api";
import { getAnUpdatedChatThunk, getUnreadMessagesCountThunk } from "../../store/actions/chat";
import {
  addUnreadLikeChatId,
  setIsLikedOrRead,
  updateMessage,
} from "../../store/reducers/chatSlice";
import * as calendarActions from "../../store/actions/calendar";
import query from "querystringify";
import { useLocation } from "react-router-dom";
import { getPatientTreatmentPlansList } from "../../store/actions/patient";
import { sendMessages } from "./helpers";

export const SocketContext = createContext(null);

export default function SocketProvider({ children }) {
  const dispatch = useDispatch();
  const store = useStore();
  const [socket, setSocket] = useState(null);
  const accessToken = useSelector(s => s.auth.tokens?.access_token);
  const userId = useSelector(s => s.auth.user?.userId);
  const reconnectTimer = useRef(null);
  const wsRef = useRef(null);
  const location = useLocation();
  const locationRef = useRef(location);

  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  useEffect(() => {
    if (!accessToken) return;

    const url = `${API_URL.replace("https", "wss")}/ws/`;

    const connectWebSocket = () => {
      const ws = new WebSocket(url, [accessToken]);

      ws.onopen = () => {
        console.log("WebSocket has connected");

        if (reconnectTimer.current) {
          clearTimeout(reconnectTimer.current);
          reconnectTimer.current = null;
        }
      };

      ws.onmessage = event => {
        const data = JSON.parse(event.data);
        const activeChatId = store.getState().chat.activeChatId;

        switch (data.type) {
          case "pong": {
            const socketPingTimestamp = Number(
              JSON.parse(localStorage.getItem("socketPingTimestamp")),
            );

            if (data.timestamp === socketPingTimestamp) {
              sendMessages({ socket: ws, dispatch, userId });
            }

            break;
          }

          case "chat_message": {
            dispatch(getAnUpdatedChatThunk(data.message.chat_id));
            dispatch(updateMessage(data.message));
            dispatch(getUnreadMessagesCountThunk());

            if (data.message.id) {
              const localMessagesList = JSON.parse(localStorage.getItem("chat_message") || "{}");
              delete localMessagesList[data.message.uuid];
              localStorage.setItem("chat_message", JSON.stringify(localMessagesList));
            }

            break;
          }

          case "chat_message_updated": {
            dispatch(getAnUpdatedChatThunk(data.message.chat_id));
            dispatch(setIsLikedOrRead(data.message));
            dispatch(getUnreadMessagesCountThunk());

            if (data.message.is_liked && data.message.chat_id !== activeChatId) {
              dispatch(addUnreadLikeChatId(data.message.chat_id));
            }

            if (data.message.message_id) {
              const allChatMessageRead = JSON.parse(
                localStorage.getItem("allChatMessageRead") || "{}",
              );
              delete allChatMessageRead[data.message.message_id];
              localStorage.setItem("allChatMessageRead", JSON.stringify(allChatMessageRead));
            }

            break;
          }

          case "schedule_entry_updated":
          case "schedule_entry_deleted":

          // eslint-disable-next-line no-fallthrough
          case "schedule_entry_created": {
            const currentLocation = locationRef.current;
            setTimeout(() => {
              dispatch(
                calendarActions.getCalendarList(
                  currentLocation?.pathname === "/appointment"
                    ? {
                        ...(currentLocation?.search && {
                          queryObj: query.parse(currentLocation?.search),
                        }),
                        isWithQueries: true,
                      }
                    : {},
                ),
              );
            }, 1000);
            break;
          }

          case "treatment_plan_approved":

          // eslint-disable-next-line no-fallthrough
          case "treatment_plan_rejected": {
            const patient = store.getState().patients.patientProfile;

            if (patient?.id) {
              setTimeout(() => {
                dispatch(getPatientTreatmentPlansList(patient.id));
              }, 1000);
            }

            break;
          }
        }
      };

      ws.onerror = error => {
        console.error("WebSocket Error:", error);

        if (error.message?.includes("403 Access denied")) {
          console.warn("Authorization error: stopping reconnection");
          ws.close();
        }
      };

      ws.onclose = event => {
        console.warn("WebSocket is closed", event);

        if (!reconnectTimer.current) {
          reconnectTimer.current = setTimeout(connectWebSocket, 3000);
        }
      };

      wsRef.current = ws;
      setSocket(ws);
    };

    connectWebSocket();

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }

      if (reconnectTimer.current) {
        clearTimeout(reconnectTimer.current);
      }
    };
  }, [accessToken, dispatch, store]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
}
