export const formatTextWithLinks = text => {
  if (!text) return null;

  const urlRegex = /(https?:\/\/[^\s]+)/g;

  return text.split(urlRegex).map((part, index) =>
    part.match(urlRegex) ? (
      <a
        key={index}
        href={part}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#007bff" }}
      >
        {part}
      </a>
    ) : (
      part
    ),
  );
};
