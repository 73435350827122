import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { history } from "../../utils/history";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import blankImagePath from "../../assets/images/blank-image-icon.png";

import "tiny-slider/dist/tiny-slider.css";
import ImageLoader from "../../components/FileLoader/ImageLoader";
import { resetStaffState } from "../../store/reducers/staffSlice";
import { getStaffProfile, removeStaffProfile } from "../../store/actions/staff";
import StaffEditForm from "../../components/staff/StaffEditForm";
import { useAccess } from "../../hooks/useAccess";
import { controlledPages, permissions } from "../../config/accessControlConfig";

export default function StaffProfilePage() {
  let params = useParams();
  const dispatch = useDispatch();
  let id = params.id;
  const staffProfile = useSelector(s => s.staff.staffProfile);
  const user = useSelector(s => s.auth.user);
  let [activeIndex, setActiveIndex] = useState(1);
  const { hasPermission } = useAccess();

  const isCanEdit = hasPermission(controlledPages.staffProfile, permissions.edit, id);
  const isCanDelete = hasPermission(controlledPages.staffProfile, permissions.delete);
  const isCanEditUsername = hasPermission(controlledPages.staffProfile, permissions.editUsername);

  useEffect(() => {
    dispatch(resetStaffState());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getStaffProfile(id));
  }, [dispatch, id]);

  const handleDelete = () => {
    dispatch(
      showSubmitConfirmation({
        message: `Are you sure you want to delete staff: ${staffProfile?.first_name} ${staffProfile?.last_name}?`,
        onConfirm: async () => {
          await dispatch(removeStaffProfile(staffProfile.id));
          history.navigate("/staff");
        },
      }),
    );
  };

  return (
    <>
      <div className="container-fluid">
        <div className="layout-specing">
          <div className="d-md-flex justify-content-between">
            <h5 className="mb-0">Staff Profile & Settings</h5>

            <nav aria-label="breadcrumb" className="d-inline-block mt-4 mt-sm-0">
              <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">AOSmiles</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/staff">Staff</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Profile
                </li>
              </ul>
            </nav>
          </div>

          <div className="card rounded shadow overflow-hidden mt-4 border-0">
            <div className="p-5 bg-primary bg-gradient"></div>
            <div className="avatar-profile d-flex margin-nagative mt-n5 position-relative ps-3">
              <div className="position-relative">
                <ImageLoader
                  src={
                    staffProfile?.photo_details?.safe_url
                      ? `${staffProfile?.photo_details?.safe_url}?x=300`
                      : blankImagePath
                  }
                  className="rounded-circle shadow-md avatar avatar-medium bg-white"
                  alt=""
                />
              </div>
              <div className="mt-4 ms-3 pt-3">
                <h5 className="mt-3 mb-1">{`${staffProfile.id ? `${staffProfile?.first_name} ${staffProfile?.last_name}` : ""}`}</h5>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-4">
                <div className="card border-0 rounded-0 p-4">
                  <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded shadow overflow-hidden bg-light">
                    <li className="nav-item">
                      <Link
                        className={`${activeIndex === 1 ? "active" : ""} nav-link rounded-0`}
                        to="#"
                        onClick={() => setActiveIndex(1)}
                      >
                        <div className="text-center pt-1 pb-1">
                          <h5 className="mb-0">Settings</h5>
                        </div>
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content mt-4" id="pills-tabContent">
                    {activeIndex === 1 ? (
                      <div className="tab-pane fade show active">
                        <h5 className="mb-1">Settings</h5>
                        <div className="row">
                          <StaffEditForm
                            staffProfile={staffProfile}
                            isCanEdit={isCanEdit}
                            isCanDelete={isCanDelete}
                            isDisableDeleteButton={+user?.userId === +id}
                            isCanEditUsername={isCanEditUsername}
                            buttonText="Save changes"
                            handleDelete={handleDelete}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
