import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const DraggableItem = ({ item, index, moveItem, type, onDrop, children }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: type,
    hover: draggedItem => {
      if (!ref.current) return;

      if (draggedItem.index === index) return;

      moveItem(draggedItem.index, index);
      draggedItem.index = index;
    },
    drop: onDrop,
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item: { id: item.id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} className={isDragging ? "opacity-50" : ""} style={{ cursor: "grab" }}>
      {children}
    </div>
  );
};

export default DraggableItem;
