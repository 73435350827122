import moment from "moment";
import { filterChat } from "../../store/reducers/chatSlice";

export const sendMessages = ({ socket, dispatch, userId }) => {
  const localMessagesList = JSON.parse(localStorage.getItem("chat_message") || "{}");
  Object.values(localMessagesList).forEach(message => {
    const createdAtMoment = moment(message.created_at);
    const diffInSeconds = moment().diff(createdAtMoment, "seconds");

    if (diffInSeconds >= 24 * 60 * 60) {
      delete localMessagesList[message.uuid];
      localStorage.setItem("chat_message", JSON.stringify(localMessagesList));
      dispatch(filterChat(message));
    } else if (diffInSeconds <= 60 * 60 && message.userId === userId) {
      delete message.userId;
      socket?.send(
        JSON.stringify({
          type: "chat_message",
          message,
        }),
      );
    }
  });

  const allChatMessageRead = JSON.parse(localStorage.getItem("allChatMessageRead"));
  Object.values(allChatMessageRead || {}).forEach(message => {
    socket?.send(JSON.stringify(message));
  });
};
