import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserProfile } from "../store/actions/auth";

export const useGetUserData = () => {
  const dispatch = useDispatch();
  const token = useSelector(s => s.auth.tokens?.access_token);
  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        await dispatch(getUserProfile(token));
      };

      fetchData();
    }
  }, [dispatch, token]);
};
