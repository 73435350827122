import React, { useEffect } from "react";
import { Link, useLocation, matchPath } from "react-router-dom";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { GrDashboard } from "react-icons/gr";
import { CiCalendar } from "react-icons/ci";
import PatientIcon from "../assets/images/svg/PatientIcon";
import { MdOutlineContentPaste, MdOutlineMedicalServices } from "react-icons/md";
import { FaRegComment } from "react-icons/fa";
import { BsChatLeftText } from "react-icons/bs";
import { RiStethoscopeLine } from "react-icons/ri";
import { LuUsers } from "react-icons/lu";
import logoDark from "../assets/images/aosLogo.png";
import logoLight from "../assets/images/aosLogo.png";
import { VscFeedback } from "react-icons/vsc";
import { LiaFileMedicalAltSolid } from "react-icons/lia";
import { useSelector } from "react-redux";
import { FaUserDoctor } from "react-icons/fa6";
import { useAccess } from "../hooks/useAccess";

const SIDEBAR_MENU = [
  {
    title: "Dashboard",
    path: "/",
    icon: GrDashboard,
  },
  {
    title: "Calendar",
    path: "/calendar",
    icon: CiCalendar,
  },
  {
    title: "Patients",
    path: "/patients",
    icon: PatientIcon,
    associatedPaths: ["/patients/:id"],
    subMenu: [
      {
        title: "All Patients",
        path: "/patients",
      },
      {
        title: "Add Patients",
        path: "/add-patient",
      },
    ],
  },
  {
    title: "Doctors",
    path: "/doctors",
    icon: FaUserDoctor,
    associatedPaths: ["/doctors/:id"],
    subMenu: [
      {
        title: "Doctors",
        path: "/doctors",
      },
      {
        title: "Add Doctor",
        path: "/add-doctor",
      },
    ],
  },
  {
    title: "Staff",
    path: "/staff",
    icon: LuUsers,
    associatedPaths: ["/staff/:id"],
    subMenu: [
      {
        title: "Staff",
        path: "/staff",
      },
      {
        title: "Add Staff",
        path: "/add-staff",
      },
    ],
  },
  {
    title: "Services",
    path: "/services",
    icon: MdOutlineMedicalServices,
    subMenu: [
      {
        title: "Services",
        path: "/services",
      },
    ],
  },
  {
    title: "Appointments",
    path: "/appointment",
    icon: RiStethoscopeLine,
  },
  {
    title: "Chat",
    path: "/chat",
    icon: BsChatLeftText,
  },
  {
    title: "Feedbacks",
    path: "/feedbacks",
    icon: VscFeedback,
  },
  // {
  //   title: "Treatments",
  //   path: "/treatments",
  //   icon: LiaFileMedicalAltSolid,
  // },
  {
    title: "Content",
    path: "/faqs",
    icon: MdOutlineContentPaste,
    subMenu: [
      {
        title: "Faqs",
        path: "/faqs",
      },
      {
        title: "Tips",
        path: "/tips",
      },
    ],
  },
];

export default function Sidebar({ manuClass }) {
  const { pathname } = useLocation();
  const unreadMessagesCount = useSelector(s => s.chat.unreadMessagesCount);
  const { hasRouteAccess } = useAccess();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <nav id="sidebar" className={manuClass}>
      <SimpleBar className="sidebar-content" data-simplebar style={{ height: "calc(100% - 60px)" }}>
        <div className="sidebar-brand">
          <Link to="/">
            <img src={logoDark} height="48" className="logo-light-mode" alt="" />
            <img src={logoLight} height="48" className="logo-dark-mode" alt="" />
            <span className="sidebar-colored">
              <img src={logoLight} height="48" alt="" />
            </span>
          </Link>
        </div>

        <ul className="sidebar-menu">
          {SIDEBAR_MENU.filter(menu => hasRouteAccess(menu.path)).map((menuItem, index) => {
            const IconComponent = menuItem.icon;
            const isMenuActive =
              menuItem.path === pathname ||
              menuItem.subMenu?.some(item => item.path === pathname) ||
              menuItem.associatedPaths?.some(
                associatedPath => !!matchPath(associatedPath, pathname),
              );

            return (
              <li
                key={index}
                className={classNames(
                  {
                    "sidebar-dropdown": !!menuItem.subMenu,
                    active: isMenuActive,
                  },
                  "ms-0",
                )}
              >
                <Link to={menuItem.path}>
                  <IconComponent className="me-2 d-inline-block mb-0 icon" />
                  <span className="me-2">{menuItem.title}</span>
                  {menuItem.path === "/chat" && unreadMessagesCount > 0 && (
                    <div className="d-flex gap-2">
                      <span className="badge rounded-pill bg-soft-danger">
                        {unreadMessagesCount}
                      </span>
                    </div>
                  )}
                </Link>
                {menuItem.subMenu && (
                  <div className={`sidebar-submenu ${isMenuActive ? "d-block" : ""}`}>
                    <ul>
                      {menuItem.subMenu
                        .filter(submenu => hasRouteAccess(submenu.path))
                        .map((subMenuItem, subMenuIndex) => {
                          const isSubmenuActive = subMenuItem.path === pathname;

                          return (
                            <li
                              key={subMenuIndex}
                              className={`${isSubmenuActive ? "active" : ""} ms-0`}
                            >
                              <Link to={subMenuItem.path}>{subMenuItem.title}</Link>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </SimpleBar>
      <ul className="sidebar-footer list-unstyled mb-0">
        <li className="list-inline-item mb-0 ms-1">
          <Link to="/chat" className="d-flex gap-2 flex-row align-items-center">
            <div className="btn btn-icon btn-pills btn-soft-primary">
              <FaRegComment />
            </div>
            {unreadMessagesCount > 0 && (
              <div className="d-flex gap-2">
                <span className="badge rounded-pill bg-soft-danger">{unreadMessagesCount}</span>
              </div>
            )}
          </Link>
        </li>
      </ul>
    </nav>
  );
}
