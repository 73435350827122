import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import CustomAsyncSelect from "../Select/CustomAsyncSelect";
import { getTreatmentPlanDetailsApi, getTreatmentStageDetailsApi } from "../../api/treatments";
import { useSelector } from "react-redux";

const TreatmentStageField = ({ control, name, initialStageOption }) => {
  const [currentKey, setCurrentKey] = useState(null);
  const eventItem = useSelector(s => s.calendar.dialog.selected);
  const { watch } = useFormContext();
  const treatmentPlan = watch("treatment_plan");
  const patientId = watch("patient_id");
  useEffect(() => {
    const keyForSet = `${treatmentPlan ? treatmentPlan : ""}${patientId ? patientId : ""}`;

    if (currentKey !== keyForSet) {
      setCurrentKey(keyForSet);
    }
  }, [currentKey, patientId, treatmentPlan]);

  const getTreatmentStagesData = async () => {
    if (treatmentPlan) {
      let currentTreatmentPlan = "";

      if (eventItem?.treatment_stage) {
        await getTreatmentStageDetailsApi(eventItem?.treatment_stage).then(
          response => (currentTreatmentPlan = response.data.plan),
        );
      }

      return getTreatmentPlanDetailsApi(treatmentPlan).then(response => {
        return {
          options: response.data.stages_details.reduce((acc, item) => {
            if (item.is_completed) {
              return acc;
            }

            if (!item.appointment_details.length) {
              acc.push({
                value: item.id,
                label: item.name,
              });
            }

            if (treatmentPlan === currentTreatmentPlan && item.id === eventItem?.treatment_stage) {
              acc.push({
                value: item.id,
                label: item.name,
              });
            }

            return acc;
          }, []),
          hasMore: false,
        };
      });
    }
  };

  return (
    <div className="col-lg-12">
      <div className="mb-3 form-group">
        <label className="form-label">Treatment Stage</label>
        <CustomAsyncSelect
          name={name}
          control={control}
          placeholder="Treatment Stage"
          getOptions={getTreatmentStagesData}
          limit={10}
          isSearchable={false}
          initialOption={initialStageOption}
          className={classNames("form-control", "p-0")}
          currentKey={currentKey}
        />
      </div>
    </div>
  );
};

export default TreatmentStageField;
