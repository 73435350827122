import { adminClient } from "../utils/axios";
import { getIfExist } from "../utils/getIfExist";

export const addMedicalCheckoutServiceApi = service => {
  return adminClient.post(`admin/checkout-services/`, getIfExist(service));
};

export const updateMedicalCheckoutServiceApi = service => {
  return adminClient.put(`admin/checkout-services/${service.id}/`, service);
};

export const deleteMedicalCheckoutServiceApi = id => {
  return adminClient.delete(`admin/checkout-services/${id}/`);
};

export const addMedicalCheckoutApi = (eventId, checkoutDetails) => {
  return adminClient.put(`admin/checkouts/${eventId}/`, getIfExist(checkoutDetails));
};

export const removeMedicalCheckoutApi = eventId => {
  return adminClient.delete(`admin/checkouts/${eventId}/`);
};
