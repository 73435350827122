import { checkUsernameApi } from "../api/staff";
import slugify from "slugify";

export const generateCustomUserName = async ({ firstName, lastName }) => {
  if (!firstName || !lastName) {
    return;
  }

  let username;
  let attempt = 0;
  let isUnique = false;

  while (!isUnique) {
    const suffix = attempt > 0 ? `${attempt}` : "";
    username = slugify(`${firstName.toLowerCase()} ${lastName.toLowerCase()}${suffix}`, {
      replacement: "_",
      remove: /[*+~.,;()'"!:@]/g,
      locale: "en-AU",
      trim: true,
    });

    try {
      await checkUsernameApi({ username });

      isUnique = true;
    } catch (error) {
      attempt++;
    }
  }

  return username;
};
