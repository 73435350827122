import React, { useEffect, useState } from "react";
import ReactSelect from "react-select/creatable";

const Select = ({
  initialValue,
  isMulti = true,
  name = "",
  isSearchable,
  className,
  placeholder,
  optionsData = [],
  onChange,
  valueField = "id",
  labelField = "name",
  isClearable = true,
  ...rest
}) => {
  const [serviceOptions, setServiceOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null);
  useEffect(() => {
    const formattedOptions = optionsData.map(option => ({
      value: option[valueField],
      label: option[labelField],
    }));
    setServiceOptions(formattedOptions);

    const effectiveInitialValue = initialValue || (isMulti ? [] : null);

    const formattedDefault = formattedOptions.filter(option =>
      isMulti
        ? effectiveInitialValue.includes(option.value)
        : option.value === effectiveInitialValue,
    );
    setSelectedValue(isMulti ? formattedDefault : formattedDefault[0] || null);
  }, [initialValue, isMulti, labelField, optionsData, valueField]);

  const handleOnChange = selected => {
    setSelectedValue(selected);

    const value = isMulti ? selected.map(item => item.value) : selected?.value;
    onChange(name, value);
  };

  return (
    <ReactSelect
      isMulti={isMulti}
      isClearable={isClearable}
      isSearchable={isSearchable}
      onChange={handleOnChange}
      options={serviceOptions}
      placeholder={placeholder}
      className={className}
      value={selectedValue}
      styles={{
        control: (styles, { isDisabled }) => ({
          ...styles,
          backgroundColor: isDisabled ? "#e9ecef" : "#fff",
          minHeight: "36px",
          borderRadius: "0.375rem",
          border: 0,
        }),
      }}
      {...rest}
    />
  );
};

export default Select;
