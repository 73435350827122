export const formatPhoneNumber = phone => {
  const part1 = phone.slice(0, 3);
  const part2 = phone.slice(3, 5);
  const part3 = phone.slice(5, 8);
  const part4 = phone.slice(8, 10);
  const part5 = phone.slice(10, 12);

  return `${part1} ${part2} ${part3} ${part4} ${part5}`;
};

export const normalizePhoneNumber = input => {
  if (!input) return "";

  const cleaned = input.replace(/[^\d+]/g, "");
  let result = cleaned;

  if (cleaned.startsWith("+61(0)")) {
    result = cleaned.replace("+61(0)", "+61");
  }

  if (cleaned.startsWith("+610")) {
    result = "+61" + cleaned.slice(4);
  }

  if (cleaned.startsWith("+61")) {
    result = cleaned;
  }

  if (cleaned.startsWith("61")) {
    result = "+" + cleaned;
  }

  if (cleaned.startsWith("04")) {
    result = "+61" + cleaned.slice(1);
  }

  if (cleaned.startsWith("4") && cleaned.length === 9) {
    result = "+61" + cleaned;
  }

  return result.trim() ? formatPhoneNumber(result.trim()) : "";
};
