import classNames from "classnames";
import SearchFilter from "../queries/FIlter/SearchFilter";

const TableHeaderItem = ({ isFilterEnable, title, className, styles, filterField, ...props }) => {
  return (
    <th
      className={classNames("border-bottom p-3 base align-baseline", className)}
      style={styles}
      {...props}
    >
      <div className="d-flex flex-column align-items-start">
        <div>{title}</div>
        {isFilterEnable && <SearchFilter filterName={filterField} />}
      </div>
    </th>
  );
};

export default TableHeaderItem;
