import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import query from "querystringify";
import { useDispatch, useSelector } from "react-redux";

import ServicesListItem from "../../components/services/servicesListItem";
import {
  getServices,
  removeService,
  updateService as updateServiceThunk,
} from "../../store/actions/services";
import { history } from "../../utils/history";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import CreateUpdateServiceModal from "../../components/services/createUpdateServiceModal";

export default function Services() {
  const dispatch = useDispatch();
  const [isCreatingUpdatingService, setIsCreatingUpdatingService] = useState(false);
  const [serviceOnUpdate, setServiceOnUpdate] = useState({});
  const servicesData = useSelector(s => s.services.list);
  const queries = useMemo(() => query.parse(history.location.search), [history.location.search]);

  useEffect(() => {
    dispatch(getServices(queries));
  }, [dispatch, queries]);

  const setIsActiveService = useCallback(
    service => {
      dispatch(
        showSubmitConfirmation({
          message: `Are you sure want to ${service.is_Active ? "deactivate" : "activate"} service?`,
          onConfirm: async () => {
            await dispatch(
              updateServiceThunk({
                ...service,
                is_active: !service.is_active,
              }),
            );
            dispatch(getServices(queries));
          },
        }),
      );
    },
    [dispatch, queries],
  );

  const deleteService = useCallback(
    service => {
      dispatch(
        showSubmitConfirmation({
          message: `Are you sure want to delete ${service.name} service?`,
          onConfirm: async () => {
            await dispatch(removeService({ id: service.id }));
            dispatch(getServices(queries));
          },
        }),
      );
    },
    [dispatch, queries],
  );

  const updateService = useCallback(service => {
    setIsCreatingUpdatingService(true);
    setServiceOnUpdate(service);
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="layout-specing">
          <div className="d-md-flex justify-content-between">
            <div>
              <h5 className="mb-0">Services List</h5>

              <nav aria-label="breadcrumb" className="d-inline-block mt-4 mt-sm-0">
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/">AOSmiles</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Services
                  </li>
                </ul>
              </nav>
            </div>

            <div className="col-xl-3 col-md-6 mt-4 mt-md-0 text-md-end">
              <Link
                to="#"
                className="btn btn-primary"
                onClick={() => setIsCreatingUpdatingService(true)}
              >
                Add New Service
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-4">
              <div className="table-responsive shadow rounded">
                <table className="table table-center bg-white mb-0">
                  <thead>
                    <tr>
                      <th className="border-bottom p-3" style={{ minWidth: "50px" }}>
                        Code
                      </th>
                      <th className="border-bottom p-3" style={{ minWidth: "180px" }}>
                        Name
                      </th>
                      <th className="border-bottom p-3">Description</th>
                      <th className="border-bottom p-3" style={{ minWidth: "150px" }}>
                        Price
                      </th>
                      <th className="border-bottom p-3">Duration minutes</th>
                      <th className="border-bottom p-3" style={{ minWidth: "100px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {servicesData?.map(item => {
                      return (
                        <ServicesListItem
                          key={item.id}
                          service={item}
                          setIsActiveService={setIsActiveService}
                          updateService={updateService}
                          deleteService={deleteService}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateUpdateServiceModal
        setIsModalOpen={setIsCreatingUpdatingService}
        isModalOpen={isCreatingUpdatingService}
        serviceOnUpdate={serviceOnUpdate}
        setServiceOnUpdate={setServiceOnUpdate}
      />
    </>
  );
}
