import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import { checkIfRequired } from "../../utils/requiredField";
import { addTreatmentPlan, updateTreatmentPlan } from "../../store/actions/treatments";
import { getPatientTreatmentPlansList } from "../../store/actions/patient";
import CustomAsyncSelect from "../Select/CustomAsyncSelect";
import { getAsyncSelectOption, getDoctorsData } from "../../utils/getAsyncSelectOptions";
import { getDoctorProfileDetails } from "../../api/doctor";
import StatusSelect from "../Select/StatusSelect";
import { status } from "../../config/settings";

const TreatmentPlanForm = ({ isShowEditForm, handleClose, patientProfile }) => {
  const [initialDoctorOption, setInitialDoctorOption] = useState(null);
  const treatmentPlanItem = useSelector(s => s.treatments.treatmentPlans.treatmentPlan);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string(),
    main_doctor: Yup.string().required("Doctor is required"),
    status: Yup.string(),
  });

  const defaultValues = {
    name: "",
    description: "",
    main_doctor: "",
    status: "DRAFT",
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues,
  };
  const methods = useForm(formOptions);
  const { handleSubmit, formState, reset, control, register } = methods;
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (treatmentPlanItem?.id) {
      reset({
        name: treatmentPlanItem.name || "",
        description: treatmentPlanItem.description || "",
        main_doctor: treatmentPlanItem.main_doctor || "",
        status: treatmentPlanItem.status || "DRAFT",
      });
    }
  }, [treatmentPlanItem, reset]);

  const onSubmit = async formValues => {
    if (treatmentPlanItem?.id) {
      await dispatch(updateTreatmentPlan({ ...treatmentPlanItem, ...formValues }));
    } else await dispatch(addTreatmentPlan({ patient: patientProfile.id, ...formValues }));

    await dispatch(getPatientTreatmentPlansList(patientProfile.id));
    handleClose();
    reset(defaultValues);
  };

  const handleFormClose = () => {
    if (formState.isDirty) {
      dispatch(
        showSubmitConfirmation({
          message: "Are you sure you want to exit? All unsaved data will be lost.",
          onConfirm: () => {
            handleClose();
            reset(defaultValues);
          },
        }),
      );
    }

    if (!formState.isDirty) {
      handleClose();
      reset(defaultValues);
    }
  };

  const fetchInitialDoctorOption = useCallback(async () => {
    if (treatmentPlanItem?.main_doctor) {
      try {
        const result = await getDoctorProfileDetails({ id: treatmentPlanItem?.main_doctor });

        setInitialDoctorOption(getAsyncSelectOption(result.data));
      } catch (error) {
        console.error("Error to load initialDoctorOption:", error);
      }
    }
  }, [treatmentPlanItem?.main_doctor]);

  useEffect(() => {
    fetchInitialDoctorOption();
  }, [fetchInitialDoctorOption]);

  return (
    <div>
      <Offcanvas
        show={isShowEditForm}
        onHide={() => {
          handleFormClose();
        }}
        backdrop={true}
        keyboard={false}
        placement="end"
        className="offcanvasEventEditForm"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>{`${treatmentPlanItem?.id ? "Edit" : "Create"} Treatment Plan`}</Offcanvas.Title>
          <div className="d-flex gap-1">
            <Button variant="secondary" onClick={handleFormClose}>
              Close
            </Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FormProvider {...methods}>
            <form className="login-form mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">{`Name${checkIfRequired("name", validationSchema)}`}</label>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.name,
                      })}
                      placeholder="Treatment Plan Name"
                      {...register("name")}
                    />
                    <div className="invalid-feedback">{errors.name?.message}</div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">{`Description${checkIfRequired("description", validationSchema)}`}</label>
                    <input
                      name="description"
                      id="description"
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.name,
                      })}
                      placeholder="Treatment Plan Description"
                      {...register("description")}
                    />
                    <div className="invalid-feedback">{errors.description?.message}</div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">{`Main Doctor${checkIfRequired("main_doctor", validationSchema)}`}</label>
                    <CustomAsyncSelect
                      name="main_doctor"
                      placeholder="Select doctor"
                      control={control}
                      getOptions={getDoctorsData}
                      {...(treatmentPlanItem?.main_doctor && {
                        initialOption: initialDoctorOption,
                      })}
                      limit={10}
                      className={classNames("form-control", "p-0", {
                        "is-invalid": errors.main_doctor,
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Treatment plan status</label>
                    <StatusSelect
                      name="status"
                      placeholder="Select treatment plan status"
                      control={control}
                      statusFlags={status}
                      className={classNames("form-control", "p-0", {
                        "is-invalid": errors.status,
                      })}
                      statusKeys={["DRAFT", "IN_PROGRESS", "COMPLETED"]}
                    />
                  </div>
                </div>
                <div className="col-lg-12 mb-0">
                  <div className="d-grid">
                    <button disabled={isSubmitting} className="btn btn-primary">
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default TreatmentPlanForm;
