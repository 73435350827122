import { createSlice } from "@reduxjs/toolkit";
import { getMedicalConditionsList } from "../actions/medicalConditions";

const initialState = {
  count: 0,
  page: 1,
  page_size: 20,
  next: null,
  previous: null,
  list: [],
};

export const medicalConditionsSliceName = "medicalConditions";

export const medicalConditionsSlice = createSlice({
  name: medicalConditionsSliceName,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getMedicalConditionsList.fulfilled, (state, action) => {
      state.list = action.payload.data.results;
      state.count = action.payload.data.count;
      state.next = action.payload.data.next;
      state.previous = action.payload.data.previous;
      state.page = action.payload.page;
    });
  },
});

export default medicalConditionsSlice.reducer;
