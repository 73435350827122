import { getPatientProfilesList } from "../api/patient";
import { getDoctorProfilesList } from "../api/doctor";

export const getAsyncSelectOption = option => {
  if (!option) {
    return null;
  }

  return {
    value: option.id,
    label: `${option.first_name} ${option.last_name}`,
    data: option,
  };
};

export const getPatientsData = async requestObj => {
  return getPatientProfilesList(requestObj).then(response => {
    return {
      options: response.data.results.map(getAsyncSelectOption),
      hasMore: !!response.data.next,
    };
  });
};

export const getDoctorsData = async requestObj => {
  return getDoctorProfilesList(requestObj).then(response => {
    return {
      options: response.data.results.map(getAsyncSelectOption),
      hasMore: !!response.data.next,
    };
  });
};
