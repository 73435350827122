import { createSlice } from "@reduxjs/toolkit";
import { feedbackSliceName, getFeedbackList } from "../actions/feedback";

const initialState = {
  count: 0,
  page: 1,
  page_size: 50,
  next: null,
  previous: null,
  ordering: "-created_at",
  patient: null,
  list: [],
};

export const feedbackSlice = createSlice({
  name: feedbackSliceName,
  initialState,
  reducers: {
    resetFeedbackState: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(getFeedbackList.fulfilled, (state, action) => {
      state.list = action.payload.data.results;
      state.count = action.payload.data.count;
      state.next = action.payload.data.next;
      state.previous = action.payload.data.previous;
      state.page = action.payload.page;
      state.ordering = action.payload.ordering;
    });
  },
});

export const { resetFeedbackState } = feedbackSlice.actions;

export default feedbackSlice.reducer;
