import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import FadeLoader from "react-spinners/FadeLoader";
import { useVirtualizer } from "@tanstack/react-virtual";

import ChatMessage from "./chatMessage";
import ChatInput from "./chatInput";
import { getMessagesListFromThunk } from "../../store/actions/chat";
import {
  addCachedMessages,
  addNewMessage,
  setIsFetchingMessages,
} from "../../store/reducers/chatSlice";
import { useSocket } from "../../hooks/useSocket";
import { store } from "../../store/configureStore";
import {
  saveDataToIndexDB,
  getDataFromIndexDB,
  DB_NAME,
  KEY_PATH,
  STORE_NAME,
} from "../../utils/indexedDB";
import { getActiveChatDetail } from "../../api/chat";
import { usePingSocket } from "../../hooks/usePingSocket";

export default function Chat() {
  const socket = useSocket();
  const dispatch = useDispatch();
  const userId = useSelector(s => s.auth.user?.userId);
  const chatsList = useSelector(s => s.chat.chatsList);
  const activeChatId =
    useSelector(s => s.chat.activeChatId) ||
    +localStorage.getItem("activeChatId") ||
    chatsList[0]?.id;
  const activeChat = useSelector(s => s.chat.activeChats.find(chat => chat.id === activeChatId));
  const isFetchingMessages = useSelector(s => s.chat.isFetchingMessages);

  const [newMessageText, setNewMessageText] = useState("");
  const [messageToWhichAnswer, setMessageToWhichAnswer] = useState(null);
  const [isLoadingForScrollToMessage, setIsLoadingForScrollToMessage] = useState(false);
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);

  const renderMessages = useMemo(() => activeChat?.results || [], [activeChat]);

  useEffect(() => {
    if (!activeChatId || renderMessages.length) return;

    async function fetchMessages() {
      dispatch(setIsFetchingMessages(true));

      const cachedMessages = await getDataFromIndexDB({
        dbName: DB_NAME.chatDB,
        storeName: STORE_NAME.messages,
        keyPath: KEY_PATH.chatId,
        requestSelector: activeChatId,
      });

      if (cachedMessages.length > 0) {
        dispatch(addCachedMessages({ chatId: activeChatId, messages: cachedMessages }));
      }

      dispatch(getMessagesListFromThunk({ activeChatId, isFirstRequest: true }));
    }

    fetchMessages();
  }, [activeChatId, dispatch, renderMessages.length]);

  useEffect(() => {
    if (!activeChatId || renderMessages.length === 0) return;

    async function cacheMessages() {
      await saveDataToIndexDB({
        dbName: DB_NAME.chatDB,
        storeName: STORE_NAME.messages,
        keyPath: KEY_PATH.chatId,
        requestSelector: activeChatId,
        data: renderMessages.slice(0, 100),
      });
    }

    cacheMessages();
  }, [activeChatId, renderMessages]);

  const setLikeHandler = useCallback(
    message => {
      socket.send(
        JSON.stringify({
          type: message.is_liked ? "chat_message_disliked" : "chat_message_liked",
          message: {
            message_id: message.id,
          },
        }),
      );
    },
    [socket],
  );

  const parentRef = useRef(null);
  const loaderRef = useRef(null);
  const setAsReadMessages = useRef([]);

  const rowVirtualizer = useVirtualizer({
    count: renderMessages.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 100,
    overscan: 5,
  });

  useEffect(() => {
    if (!loaderRef.current || !parentRef.current) return;

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !isFetchingMessages && activeChat?.next) {
          dispatch(setIsFetchingMessages(true));
          dispatch(getMessagesListFromThunk({ activeChatId }));
        }
      },
      { root: parentRef.current, rootMargin: "50px" },
    );

    observer.observe(loaderRef.current);

    return () => observer.disconnect();
  }, [activeChatId, isFetchingMessages, dispatch, activeChat?.next]);

  useEffect(() => {
    const handleWheel = event => {
      parentRef.current.scrollTop -= event.deltaY;
      event.preventDefault();
    };

    const el = parentRef.current;

    if (el) {
      el.addEventListener("wheel", handleWheel, { passive: false });

      return () => el.removeEventListener("wheel", handleWheel);
    }
  }, [activeChatId, renderMessages.length]);

  const addMessage = useCallback(
    files => {
      try {
        const newMessage = {
          uuid: uuidv4(),
          created_at: new Date().toISOString(),
          text: newMessageText.trim(),
          chat_id: activeChatId,
          files,
        };

        dispatch(
          addNewMessage({
            ...newMessage,
            sender_display: "root",
            is_sender_patient: false,
            reply_to: messageToWhichAnswer,
          }),
        );

        const localMessagesList = JSON.parse(localStorage.getItem("chat_message") || "{}");
        localMessagesList[newMessage.uuid] = {
          userId,
          ...newMessage,
          ...(messageToWhichAnswer ? { reply_to_id: messageToWhichAnswer.id } : {}),
        };
        localStorage.setItem("chat_message", JSON.stringify(localMessagesList));

        if (navigator.onLine && socket) {
          const socketPingTimestamp = Date.now();
          localStorage.setItem("socketPingTimestamp", JSON.stringify(socketPingTimestamp));
          socket.send(
            JSON.stringify({
              type: "ping",
              timestamp: socketPingTimestamp,
            }),
          );
        }

        setNewMessageText("");
        setMessageToWhichAnswer(null);
        rowVirtualizer.scrollToIndex(0);
      } catch (error) {
        console.error(error);
      }
    },
    [newMessageText, activeChatId, dispatch, messageToWhichAnswer, socket, rowVirtualizer],
  );

  usePingSocket();

  useLayoutEffect(() => {
    const savedScroll = sessionStorage.getItem(`chatScroll-${activeChatId}`);
    const scrollPosition = savedScroll ? parseInt(savedScroll, 10) : null;

    if (parentRef.current) {
      if (scrollPosition !== null && scrollPosition > 0 && renderMessages.length > 0) {
        parentRef.current.scrollTop = scrollPosition;
      } else {
        parentRef.current.scrollTop = 0;
      }
    }
  }, [activeChatId, renderMessages.length]);

  useEffect(() => {
    const handleScroll = () => {
      if (parentRef.current) {
        sessionStorage.setItem(`chatScroll-${activeChatId}`, parentRef.current.scrollTop);
      }
    };

    const el = parentRef.current;

    if (el) {
      el.addEventListener("scroll", handleScroll);

      return () => el.removeEventListener("scroll", handleScroll);
    }
  }, [activeChatId, parentRef.current]);

  const scrollToMessage = useCallback(
    async messageId => {
      const state = store.getState();
      let activeChat = state.chat.activeChats.find(chat => chat.id === state.chat.activeChatId);
      let renderMessages = activeChat?.results || [];
      let index = renderMessages.findIndex(msg => msg.id === messageId);

      if (index !== -1) {
        rowVirtualizer.scrollToIndex(index, { align: "center" });
        setIsLoadingForScrollToMessage(false);
        setHighlightedMessageId(messageId);
        setTimeout(() => setHighlightedMessageId(null), 1000);

        return;
      }

      if (!activeChat?.next) {
        setIsLoadingForScrollToMessage(false);

        return;
      }

      setIsLoadingForScrollToMessage(true);
      dispatch(setIsFetchingMessages(true));
      await dispatch(getMessagesListFromThunk({ activeChatId: state.chat.activeChatId }));
      await scrollToMessage(messageId);
    },
    [dispatch, rowVirtualizer],
  );

  const [isTabActive, setIsTabActive] = useState(true);
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(prev => !prev);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const [activeChatDetail, setActiveChatDetail] = useState({});

  useEffect(() => {
    (async () => {
      if (!activeChatId) return;

      try {
        const res = await getActiveChatDetail(activeChatId);

        if (res.data) {
          setActiveChatDetail(res.data);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [activeChatId]);

  if (!activeChat) return null;

  return (
    <div className="col-xl-9 col-lg-7 col-md-7 col-12 mt-4">
      <div
        className="card chat chat-person border-0 shadow rounded"
        style={{ height: "100%", position: "relative" }}
      >
        {isLoadingForScrollToMessage && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(0,0,0, 0.1)",
              borderRadius: "5px",
              zIndex: 100,
            }}
          >
            <FadeLoader />
          </div>
        )}
        <div className="d-flex justify-content-between border-bottom p-4">
          <div className="d-flex">
            <div className="overflow-hidden ms-3">
              {activeChatDetail.patient_id ? (
                <Link
                  to={`/patients/${activeChatDetail?.patient_id}`}
                  className="text-dark mb-0 h6 d-block text-truncate"
                >
                  {activeChatDetail?.patient_name}
                </Link>
              ) : (
                "Updating chat..."
              )}
            </div>
          </div>
        </div>
        <div
          ref={parentRef}
          className="p-2 chat chat-list"
          style={{
            flex: 1,
            width: "100%",
            overflowY: "auto",
            contain: "strict",
            transform: "scaleY(-1)",
          }}
        >
          <div
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                transform: `translateY(${rowVirtualizer.getVirtualItems()[0]?.start ?? 0}px)`,
              }}
            >
              {parentRef.current &&
                rowVirtualizer.getVirtualItems().map(virtualRow => {
                  const message = renderMessages[virtualRow.index];
                  const isMessageVisible =
                    virtualRow.start >= parentRef.current.scrollTop &&
                    virtualRow.end <= parentRef.current.scrollTop + parentRef.current.clientHeight;

                  if (
                    message.is_sender_patient &&
                    !message.is_read &&
                    !setAsReadMessages.current.some(id => message.id === id) &&
                    !document.hidden &&
                    isMessageVisible
                  ) {
                    setAsReadMessages.current.push(message.id);
                    const allChatMessageRead = JSON.parse(
                      localStorage.getItem("allChatMessageRead") || "{}",
                    );
                    allChatMessageRead[message.id] = {
                      type: "chat_message_read",
                      message: {
                        message_id: message.id,
                      },
                    };
                    localStorage.setItem("allChatMessageRead", JSON.stringify(allChatMessageRead));

                    if (navigator.onLine && socket) {
                      socket.send(
                        JSON.stringify({
                          type: "ping",
                          timestamp: Date.now(),
                        }),
                      );
                    }
                  }

                  return (
                    <div
                      key={message.uuid || message.id}
                      data-index={virtualRow.index}
                      ref={rowVirtualizer.measureElement}
                      style={{ transform: "scaleY(-1)" }}
                    >
                      <ChatMessage
                        message={message}
                        setLikeHandler={setLikeHandler}
                        setMessageToWhichAnswer={setMessageToWhichAnswer}
                        scrollToMessage={scrollToMessage}
                        isHighlighted={highlightedMessageId === message.id}
                      />
                    </div>
                  );
                })}
              <div ref={loaderRef} />
            </div>
          </div>
        </div>
        <ChatInput
          newMessageText={newMessageText}
          addMessage={addMessage}
          setNewMessageText={setNewMessageText}
          messageToWhichAnswer={messageToWhichAnswer}
          setMessageToWhichAnswer={setMessageToWhichAnswer}
          patientId={chatsList.find(chat => chat.id === activeChatId)?.patient_id}
        />
      </div>
    </div>
  );
}
