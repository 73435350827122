import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import CustomAsyncSelect from "../Select/CustomAsyncSelect";
import { getTreatmentPlansListApi } from "../../api/treatments";
import { getDoctorProfileDetails } from "../../api/doctor";
import { showToast } from "../../utils/toasts";

const TreatmentPlanField = ({
  control,
  name,
  initialPlanOption,
  patientId,
  setInitialStageOption,
  setInitialDoctorOption,
}) => {
  const [currentDoctor, setCurrentDoctor] = useState({});
  const [currentKey, setCurrentKey] = useState(null);
  const { setValue, watch } = useFormContext();
  const doctor = watch("doctor");
  const fetchDoctorData = useCallback(async () => {
    try {
      const result = await getDoctorProfileDetails({
        id: doctor,
      });
      setCurrentDoctor(result.data);
    } catch (error) {
      console.error("Error to load DoctorData:", error);
    }
  }, [doctor]);

  useEffect(() => {
    if (doctor && currentDoctor?.id !== doctor) {
      fetchDoctorData();
    }
  }, [currentDoctor?.id, doctor, fetchDoctorData]);

  useEffect(() => {
    const keyForSet = `${patientId ? patientId : ""}`;

    if (currentKey !== keyForSet) {
      setCurrentKey(keyForSet);
    }
  }, [currentKey, patientId]);

  const getTreatmentPlansData = async () => {
    if (patientId) {
      return getTreatmentPlansListApi({
        patient: patientId,
        ordering: "-id",
      }).then(response => {
        return {
          options: response.data.reduce((acc, item) => {
            if (item.status !== "COMPLETED") {
              acc.push({
                value: item.id,
                label: item.name,
              });
            }

            return acc;
          }, []),
          hasMore: false,
        };
      });
    }
  };

  const handleOnChange = () => {
    setValue("treatment_stage", null);
    setInitialStageOption(null);

    if (doctor && currentDoctor && !currentDoctor.can_participate_in_treatment_plans) {
      setCurrentDoctor({});
      setValue("doctor", null);
      setInitialDoctorOption(null);
      showToast({
        message: "Selected doctor can't participate in treatment plan. Please select other doctor",
        type: "warning",
      });
    }
  };

  return (
    <div className="col-lg-12">
      <div className="mb-3 form-group">
        <label className="form-label">Treatment Plan</label>
        <CustomAsyncSelect
          name={name}
          control={control}
          placeholder="Treatment Plan"
          getOptions={getTreatmentPlansData}
          limit={10}
          isSearchable={false}
          initialOption={initialPlanOption}
          className={classNames("form-control", "p-0")}
          customOnChange={handleOnChange}
          currentKey={currentKey}
        />
      </div>
    </div>
  );
};

export default TreatmentPlanField;
