import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import multiMonthPlugin from "@fullcalendar/multimonth";
import tinycolor from "tinycolor2";

import * as calendarActions from "../../store/actions/calendar";
import { deleteCalendarEvent } from "../../store/actions/calendar";
import moment from "moment";
import EventEditForm from "../../components/calendar/EventEditForm";
import {
  resetDialogEventValues,
  setDialogEventValues,
  setSchedulePeriod,
} from "../../store/reducers/calendarSlice";
import { isCheckTimeConflict } from "../../config/settings";
import * as doctorActions from "../../store/actions/doctor";
import classNames from "classnames";
import { showToast } from "../../utils/toasts";

export default function Calendar() {
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const [isShowEditForm, setIsShowEditForm] = useState(false);

  const schedule = useSelector(s => s.calendar.schedule.list);
  const doctors = useSelector(s => s.doctors.list);
  const businessHours = useSelector(s => s.calendar.schedule.businessHours);
  const unavailableList = useSelector(s => s.calendar.schedule.unavailableList);
  const calendarApi = calendarRef?.current?.getApi();

  useEffect(() => {
    dispatch(calendarActions.getBusinessHours());
    dispatch(doctorActions.getDoctorsList({ isWithQueries: false }));
  }, [dispatch]);

  const getEvents = () => {
    const eventsList = schedule.reduce((acc, item) => {
      const doctor = doctors.find(doctor => doctor.id === item.doctor);

      if (item.entry_type === "APPOINTMENT") {
        acc.push({
          id: item.id,
          title: `Doctor: ${doctor?.first_name} ${doctor?.last_name}, Patient: ${item.patient.first_name} ${item.patient.last_name}`,
          start: item.start,
          end: item.end,
          backgroundColor: doctor?.calendar_color || "#ece8e8",
          borderColor: `var(--fc-event-border-color-${item.status})`,
          extendedProps: { eventData: { ...item } },
          ...(isCheckTimeConflict && {
            constraint: "businessHours",
          }),
          textColor: tinycolor(doctor?.calendar_color || "#ece8e8").isDark() ? "" : "#363131",
          className: classNames("fc-custom-event-class", {
            "fc-custom-event-class-overflow-y": calendarApi?.view?.type === "timeGridWeek",
            "fc-custom-event-class-overflow-x": calendarApi?.view?.type === "dayGridMonth",
          }),
        });
      }

      if (item.entry_type === "CLINIC_UNAVAILABLE") {
        acc.push({
          id: `restriction-${item.id}`,
          title: `"CLINIC_UNAVAILABLE in this time"${item.comment && `: ${item.comment}`}`,
          start: item.start,
          end: item.end,
          display: "background",
          backgroundColor: "var(--bs-gray-500)",
          groupId: item.entry_type,
        });
      }
      // TODO: need to uncomment and update when doctor will see his calendar
      // if (item.entry_type === "DOCTOR_UNAVAILABLE") {
      //   acc.push({
      //     id: `restriction-${item.id}`,
      //     title: `${doctor?.first_name} ${doctor?.last_name} UNAVAILABLE${item.comment ? `(${item.comment})` : ""}`,
      //     start: item.start,
      //     end: item.end,
      //     display: "background",
      //     backgroundColor: "var(--bs-gray-300)",
      //     textColor: tinycolor(doctor?.calendar_color || "#ece8e8").isDark() ? "" : "#363131",
      //     className: classNames("fc-custom-event-class"),
      //   });
      // }

      return acc;
    }, []);

    const unavailableDaysEvents = unavailableList.reduce((acc, item) => {
      if (!item.doctor) {
        acc.push({
          id: `restriction-${item.date}`,
          title: item.comment || "Clinic Unavailable",
          start: item.date,
          end: moment(item.date).add(1, "days").format("YYYY-MM-DD"),
          ...(isCheckTimeConflict && {
            overlap: "false",
          }),
          display: "background",
          backgroundColor: "#ff9f89",
        });
      } else {
        const doctor = doctors.find(doctor => doctor.id === item.doctor);
        acc.push({
          id: `restriction-${item.doctor}`,
          title: `Doctor: ${doctor?.first_name} ${doctor?.last_name} is not work in this day${item.comment ? `(${item.comment})` : ""}`,
          start: item.date,
          end: moment(item.date).add(1, "days").format("YYYY-MM-DD"),
          display: "background",
          backgroundColor: "var(--fc-non-business-color)",
          groupId: `doctor-${item.doctor}`,
        });
      }

      return acc;
    }, []);

    return [...eventsList, ...unavailableDaysEvents];
  };

  const handleClose = () => {
    calendarApi.unselect();
    dispatch(resetDialogEventValues());
    setIsShowEditForm(false);
  };

  const handleDelete = id => {
    dispatch(deleteCalendarEvent({ id }));
    dispatch(resetDialogEventValues());
    setIsShowEditForm(false);
  };

  const handleShow = () => setIsShowEditForm(true);

  function handleDateSelect(info) {
    if (isCheckTimeConflict) {
      const selectedDate = moment(info.start).format("YYYY-MM-DD");
      const calendarView = info.view.type;

      const isDayUnavailable = unavailableList.some(
        unavailable => !unavailable.doctor && unavailable.date === selectedDate,
      );

      if (isDayUnavailable) {
        alert("Cannot create events on this day - Clinic unavailable");
        calendarApi.unselect();

        return;
      }

      if (calendarView === "dayGridMonth") {
        const eventDayOfWeek = moment(info.start).isoWeekday();

        const isWithinBusinessDay = businessHours.some(hours => {
          return hours.daysOfWeek.includes(eventDayOfWeek);
        });

        if (!isWithinBusinessDay) {
          alert("Cannot create events on this day - Clinic closed on this day");
          calendarApi.unselect();

          return;
        }

        dispatch(
          setDialogEventValues({
            start: moment(info.start).format("YYYY-MM-DDTHH:mm:ssZ"),
            end: moment(info.end).format("YYYY-MM-DDTHH:mm:ssZ"),
          }),
        );
        handleShow();

        return;
      }

      const isWithinHours = isWithinBusinessHours(info.start, info.end);

      if (!isWithinHours) {
        alert("Cannot create events on this day - Outside of Business Hours");
        calendarApi.unselect();

        return;
      }

      const isRestrictedTime = schedule.some(event => {
        const isInRestrictedPeriod =
          event.entry_type === "CLINIC_UNAVAILABLE" &&
          moment(info.start).isBetween(event.start, event.end, null, "[]");

        const isStartingAtEndOfRestrictedPeriod =
          event.entry_type === "CLINIC_UNAVAILABLE" && moment(info.start).isSame(event.end);

        return isInRestrictedPeriod && !isStartingAtEndOfRestrictedPeriod;
      });

      if (isRestrictedTime) {
        alert("Cannot create events on this day - Clinic unavailable");
        calendarApi.unselect();

        return;
      }
    }

    dispatch(
      setDialogEventValues({
        start: moment(info.start).format("YYYY-MM-DDTHH:mm:ssZ"),
        end: moment(info.end).format("YYYY-MM-DDTHH:mm:ssZ"),
      }),
    );
    handleShow();
  }

  const handleEventClick = info => {
    if (info.event.groupId) {
      calendarApi.unselect();

      return;
    }

    calendarApi.unselect();
    dispatch(calendarActions.getEventDetails({ id: info.event.id }));
    handleShow();
  };

  function handleEvents(events) {}

  const handleDatesSet = info => {
    dispatch(
      setSchedulePeriod({
        start: moment(info.start).format("YYYY-MM-DDTHH:mm:ss"),
        end: moment(info.end).format("YYYY-MM-DDTHH:mm:ss"),
      }),
    );
    dispatch(calendarActions.getCalendarList({}));
    dispatch(calendarActions.getUnavailableList());
  };

  const handleEventChange = info => {
    const eventStart = moment(info.event.start);

    if (eventStart.isBefore(moment())) {
      showToast({
        message: "Can't move the event to the past. Returning to the previous position.",
        type: "error",
      });
      info.revert();

      return;
    }

    dispatch(
      calendarActions.updateCalendarEvent({
        id: info.event.id,
        eventData: {
          start: moment(info.event.start).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          end: moment(info.event.end).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        },
      }),
    );
  };

  const miniCalendarHandleDateClick = info => {
    calendarApi.changeView("timeGridDay");
    calendarApi.gotoDate(info.date);
  };

  const isWithinBusinessHours = (eventStart, eventEnd) => {
    const eventDayOfWeek = eventStart.getUTCDay();
    const eventStartTime = moment(eventStart).format("HH:mm");
    const eventEndTime = moment(eventEnd).format("HH:mm");

    for (const hours of businessHours) {
      if (hours.daysOfWeek.includes(eventDayOfWeek)) {
        const startTime = moment(hours.startTime, "HH:mm").format("HH:mm");
        const endTime = moment(hours.endTime, "HH:mm").format("HH:mm");

        if (eventStartTime >= startTime && eventStartTime < endTime && eventEndTime <= endTime) {
          return true;
        }
      }
    }

    return false;
  };

  const getEventsByGroupId = groupId => {
    const calendarApi = calendarRef.current.getApi();

    return calendarApi.getEvents().filter(event => event.groupId === groupId);
  };

  const getEventsByDoctorId = doctor => {
    const calendarApi = calendarRef.current.getApi();

    return calendarApi.getEvents().filter(event => {
      return event.extendedProps?.eventData?.doctor === doctor;
    });
  };

  const checkHasConflict = (events, eventStart, eventEnd, eventId) => {
    return events.some(existingEvent => {
      if (eventId && existingEvent.id === eventId) {
        return false;
      }

      const groupStart = moment(existingEvent.start);
      const groupEnd = moment(existingEvent.end);

      const isStartSameAsGroupEnd = eventStart.isSame(groupEnd);
      const isEndSameAsGroupStart = eventEnd.isSame(groupStart);

      if (isStartSameAsGroupEnd || isEndSameAsGroupStart) {
        return false;
      }

      const isStartWithinRange = eventStart.isBetween(groupStart, groupEnd, null, "[]");
      const isEndWithinRange = eventEnd.isBetween(groupStart, groupEnd, null, "[]");

      return isStartWithinRange || isEndWithinRange;
    });
  };

  const eventAllow = (dropInfo, draggedEvent) => {
    if (isCheckTimeConflict) {
      const eventStart = moment(dropInfo.start);
      const eventEnd = moment(dropInfo.end);
      const eventId = draggedEvent.id;
      const doctor = draggedEvent.extendedProps.eventData.doctor;

      const doctorEventsUnavailable = getEventsByGroupId(`doctor-${doctor}`);
      const clinicEventsUnavailable = getEventsByGroupId("CLINIC_UNAVAILABLE");
      const doctorScheduleEvents = getEventsByDoctorId(doctor);

      const isDoctorUnavailableConflict = checkHasConflict(
        doctorEventsUnavailable,
        eventStart,
        eventEnd,
      );
      const isClinicUnavailableConflict = checkHasConflict(
        clinicEventsUnavailable,
        eventStart,
        eventEnd,
      );
      const isDoctorScheduleConflict = checkHasConflict(
        doctorScheduleEvents,
        eventStart,
        eventEnd,
        eventId,
      );

      return (
        !isDoctorUnavailableConflict && !isClinicUnavailableConflict && !isDoctorScheduleConflict
      );
    }

    return true;
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="calendar-layout">
          <div className="d-md-flex justify-content-between">
            <div className="col-xl-12col-lg-12 col-12">
              <div id="calendar-container" className="card rounded border-0 shadow p-2 pb-0">
                <div id="calendar"></div>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listDay",
                  }}
                  eventOrder="start,title,-duration"
                  height="calc(100vh - 86px)"
                  ref={calendarRef}
                  initialView="timeGridWeek"
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  allDaySlot={false}
                  unselectAuto={false}
                  nowIndicator={true}
                  events={getEvents()}
                  businessHours={businessHours}
                  datesSet={handleDatesSet}
                  select={handleDateSelect}
                  eventContent={renderEventContent}
                  eventClick={handleEventClick}
                  eventsSet={handleEvents}
                  eventChange={handleEventChange}
                  eventAllow={eventAllow}
                  locale="en-AU"
                  firstDay={1}
                  titleFormat={date =>
                    `${moment(date.start).format("DD.MM.YYYY")} – ${moment(date.end).format("DD.MM.YYYY")}`
                  }
                  dayHeaderFormat={date => moment(date.date).format("ddd, DD.MM")}
                />
              </div>
            </div>
            <EventEditForm
              isShowEditForm={isShowEditForm}
              handleClose={handleClose}
              handleDelete={handleDelete}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function renderEventContent(eventInfo) {
  return (
    <div
      className={classNames("fc-event-main-frame", {
        "fc-event-overflow": eventInfo.view.type === "timeGridWeek",
      })}
    >
      <div className="fc-event-title-container">
        <div
          className={classNames("fc-event-title fc-sticky", {
            "fc-event-overflow": eventInfo.view.type === "timeGridWeek",
          })}
        >
          <span style={{ fontWeight: 600 }}>{eventInfo.timeText}</span>{" "}
          <span>{eventInfo.event.title}</span>
        </div>
      </div>
    </div>
  );
}
