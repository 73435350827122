import React from "react";
import { MdDoNotDisturb } from "react-icons/md";
import { CiCalendar } from "react-icons/ci";
import moment from "moment/moment";
import { Button } from "react-bootstrap";
import { BsPencil } from "react-icons/bs";
import { FiTrash } from "react-icons/fi";
import { deleteEventSchedule } from "../../store/actions/calendar";
import { useDispatch } from "react-redux";
import * as calendarActions from "../../store/actions/calendar";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";

const DoctorUnavailableTimeItem = ({
  handleOpenUnavailableTimeForm,
  doctorProfile,
  item,
  isCanEdit,
}) => {
  const dispatch = useDispatch();

  const handleDeleteItem = async () => {
    dispatch(
      showSubmitConfirmation({
        message: `Are you sure you want to delete this unavailable period: ${moment(item.start).format("HH:mm")} ${moment(item.start).format("D MMMM YYYY")} - ${moment(item.end).format("HH:mm")} ${moment(item.end).format("D MMMM YYYY")}?`,
        onConfirm: async () => {
          await dispatch(deleteEventSchedule({ id: item.id }));
          await dispatch(
            calendarActions.getCalendarList({
              isWithQueries: true,
              queryObj: {
                doctor: doctorProfile.id,
                entry_type: "DOCTOR_UNAVAILABLE",
                start: null,
                end: null,
                page: null,
                page_size: null,
              },
            }),
          );
        },
      }),
    );
  };

  return (
    <div className="d-flex justify-content-between align-items-center rounded p-3 shadow mt-3">
      <MdDoNotDisturb className="h3 fw-normal text-danger mb-0" />
      <div className="flex-1 overflow-hidden ms-2">
        <div className="d-flex gap-2 align-items-center" style={{ fontSize: "14px" }}>
          <CiCalendar />
          <span>{moment(item.start).format("HH:mm")}</span>
          {moment(item.start).format("D MMMM YYYY") !== moment(item.end).format("D MMMM YYYY") && (
            <span className="text-muted">{moment(item.start).format("D MMMM YYYY")}</span>
          )}
          -<span>{moment(item.end).format("HH:mm")}</span>
          <span className="text-muted">{moment(item.end).format("D MMMM YYYY")}</span>
        </div>
        <h6 className="mb-0">{item.comment}</h6>
      </div>
      {isCanEdit && (
        <div className="d-flex gap-1 align-self-center">
          <Button
            to="#"
            className="btn-icon btn-pills btn-soft-success mx-1"
            onClick={() => handleOpenUnavailableTimeForm(item.id)}
          >
            <BsPencil />
          </Button>
          <Button className="btn-icon btn-pills btn-soft-danger mx-1" onClick={handleDeleteItem}>
            <FiTrash />
          </Button>
        </div>
      )}
    </div>
  );
};

export default DoctorUnavailableTimeItem;
