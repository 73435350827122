import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addFaq,
  addTips,
  deleteFaq,
  deleteTips,
  getCurrentTips,
  getFaqsList,
  getTipsList,
  updateFaq,
  updateTips,
} from "../../api/faq";

// FAQs
export const getFaqListThunk = createAsyncThunk(`content/getFaqsList`, async (_, thunkAPI) => {
  try {
    const response = await getFaqsList();

    return {
      data: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
});

export const addFaqThunk = createAsyncThunk(`content/addFaq`, async ({ faqData }, thunkAPI) => {
  try {
    const response = await addFaq({
      faqData,
    });

    return {
      data: response.data,
      successMessage: "FAQ added successfully",
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ errorMessage: "Failed to add FAQ" });
  }
});

export const deleteFaqThunk = createAsyncThunk(`content/deleteFaq`, async ({ id }, thunkAPI) => {
  try {
    const response = await deleteFaq({
      id,
    });

    return {
      data: response.data,
      successMessage: "FAQ deleted successfully",
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ errorMessage: "Failed to delete FAQ" });
  }
});

export const updateFaqThunk = createAsyncThunk(
  `content/updateFaq`,
  async ({ faqData, isWithNotification = true }, thunkAPI) => {
    try {
      const response = await updateFaq({
        faqData,
      });

      return {
        data: response.data,
        ...(isWithNotification && {
          successMessage: "FAQ updated successfully",
        }),
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update FAQ" });
    }
  },
);

export const updateFaqsThunk = createAsyncThunk(
  `content/updateFaqs`,
  async ({ faqs, isNewFaq }, thunkAPI) => {
    try {
      await Promise.all(
        faqs.map((faq, index) => {
          if (faq.order !== index && !isNewFaq) {
            return thunkAPI.dispatch(
              updateFaqThunk({ faqData: { ...faq, order: index }, isWithNotification: false }),
            );
          } else if (isNewFaq) {
            return thunkAPI.dispatch(
              updateFaqThunk({ faqData: { ...faq, order: index + 1 }, isWithNotification: false }),
            );
          }
        }),
      );
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update FAQs" });
    }
  },
);

// Tips

export const getTipsListThunk = createAsyncThunk(`content/getTipsList`, async (_, thunkAPI) => {
  try {
    const responseWithoutTextData = await getTipsList();
    const responseWithTextData = await Promise.all(
      responseWithoutTextData.data.map(tips => {
        return getCurrentTips({ id: tips.id });
      }),
    );

    return {
      data: responseWithTextData.map(el => el.data),
    };
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
});

export const addTipsThunk = createAsyncThunk(`content/addTips`, async ({ tipsData }, thunkAPI) => {
  try {
    const response = await addTips({
      tipsData: tipsData,
    });

    return {
      data: response.data,
      successMessage: "Tips added successfully",
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ errorMessage: "Failed to add Tips" });
  }
});

export const updateTipsThunk = createAsyncThunk(
  `content/updateTips`,
  async ({ tipsData, isWithNotification = true }, thunkAPI) => {
    try {
      const response = await updateTips({
        tipsData,
      });

      return {
        data: response.data,
        ...(isWithNotification && {
          successMessage: "Tips updated successfully",
        }),
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update Tips" });
    }
  },
);

export const deleteTipsThunk = createAsyncThunk(`content/deleteTips`, async ({ id }, thunkAPI) => {
  try {
    const response = await deleteTips({
      id,
    });

    return {
      data: response.data,
      successMessage: "Tips deleted successfully",
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ errorMessage: "Failed to delete Tips" });
  }
});

export const updateTipsListThunk = createAsyncThunk(
  `content/updateTipsList`,
  async ({ tipsList, isNewTips }, thunkAPI) => {
    try {
      await Promise.all(
        tipsList.map((tips, index) => {
          if (tips.order !== index && !isNewTips) {
            return thunkAPI.dispatch(
              updateTipsThunk({ tipsData: { ...tips, order: index }, isWithNotification: false }),
            );
          } else if (isNewTips) {
            return thunkAPI.dispatch(
              updateTipsThunk({
                tipsData: { ...tips, order: index + 1 },
                isWithNotification: false,
              }),
            );
          }
        }),
      );
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update Tips" });
    }
  },
);
