import React from "react";
import moment from "moment";

const ShortInfoBody = ({ shortInfoPatientData }) => {
  return (
    <ul className="list-unstyled mb-0 d-md-flex justify-content-between mt-2 col-12 flex-wrap">
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">First Name:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData?.first_name}</div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Last Name:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData?.last_name}</div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Middle Name:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData?.middle_name}</div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Birthday:</h6>
        <div className="fw-bold ms-2">
          {shortInfoPatientData.birth_date
            ? moment(shortInfoPatientData.birth_date).format("DD-MM-YYYY")
            : ""}
        </div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Age:</h6>
        <div className="fw-bold ms-2">
          {shortInfoPatientData.birth_date
            ? moment().diff(moment(shortInfoPatientData.birth_date, "DD-MM-YYYY"), "years")
            : ""}
        </div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Gender:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.gender}</div>
      </li>
      <hr className="col-12" />
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Passport Number:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.passport_number}</div>
      </li>
      {/*<li className="d-flex ms-0 col-12">*/}
      {/*  <h6 className="text-muted">Religion:</h6>*/}
      {/*  <div className="fw-bold ms-2">{shortInfoPatientData.religion}</div>*/}
      {/*</li>*/}
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Nationality:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.nationality}</div>
      </li>
      <hr className="col-12" />
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Address:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.address}</div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Postcode:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.postcode}</div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Primary Phone Number:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.phone}</div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Emergency Contact Phone Number:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.emergency_phone_number}</div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Emergency Contact Name:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.emergency_name}</div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Email:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.email}</div>
      </li>
      <hr className="col-12" />
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Medicare Number:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.medicare_number}</div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Medicare IRN:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.medicare_irn}</div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Medicare Valid To:</h6>
        <div className="fw-bold ms-2">
          {shortInfoPatientData.medicare_valid_to
            ? moment(shortInfoPatientData.medicare_valid_to).format("DD-MM-YYYY")
            : ""}
        </div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Private Insurance Number:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.private_insurance_number}</div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Private Insurance IRN:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.private_insurance_irn}</div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Private Insurance Valid To:</h6>
        <div className="fw-bold ms-2">
          {shortInfoPatientData.private_insurance_valid_to
            ? moment(shortInfoPatientData.private_insurance_valid_to).format("DD-MM-YYYY")
            : ""}
        </div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">GP Contact:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.gp_contact}</div>
      </li>
      <hr className="col-12" />
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Is Atsis:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.is_atsis ? "yes" : "no"}</div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Is War Veteran:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.is_war_veteran ? "yes" : "no"}</div>
      </li>
      <li className="d-flex ms-0 col-12">
        <h6 className="text-muted">Is Pensioner:</h6>
        <div className="fw-bold ms-2">{shortInfoPatientData.is_pensioner ? "yes" : "no"}</div>
      </li>
      <hr className="col-12" />
    </ul>
  );
};

export default ShortInfoBody;
