import { adminClient } from "../utils/axios";
import query from "querystringify";
import { getIfExist } from "../utils/getIfExist";

export const getStaffListApi = queryObj => {
  return adminClient.get(`admin/staff-profiles/${query.stringify(getIfExist(queryObj), true)}`);
};

export const getStaffProfileDetailsApi = id => {
  return adminClient.get(`admin/staff-profiles/${id}/`);
};

export const addStaffProfileApi = staffProfileData => {
  return adminClient.post("admin/staff-profiles/", getIfExist(staffProfileData));
};

export const updateStaffProfileApi = staffProfileData => {
  return adminClient.put(
    `admin/staff-profiles/${staffProfileData.id}/`,
    getIfExist(staffProfileData),
  );
};

export const checkUsernameApi = usernameData => {
  return adminClient.post(`admin/users/check_username/`, usernameData);
};

export const removeStaffProfileApi = id => {
  return adminClient.delete(`admin/staff-profiles/${id}/`);
};
