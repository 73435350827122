import React from "react";
import { Link } from "react-router-dom";

import ChatsList from "../../components/chat/chatsList";
import Chat from "../../components/chat/chat";

export default function Chats() {
  return (
    <>
      <div className="container-fluid">
        <div className="layout-specing">
          <div className="d-md-flex justify-content-between">
            <h5 className="mb-0">Chatbox</h5>

            <nav aria-label="breadcrumb" className="d-inline-block mt-4 mt-sm-0">
              <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">AOSmiles</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Chat
                </li>
              </ul>
            </nav>
          </div>

          <div className="row flex-grow-1">
            <ChatsList />

            <Chat />
          </div>
        </div>
      </div>
    </>
  );
}
