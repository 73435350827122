import { createAsyncThunk } from "@reduxjs/toolkit";
import { medicalConditionsSliceName } from "../reducers/medicalConditionsSlice";
import { getMedConditionsList } from "../../api/medicalConditions";

export const getMedicalConditionsList = createAsyncThunk(
  `${medicalConditionsSliceName}/list`,
  async ({ queryObj, isWithQueries = false }, thunkAPI) => {
    try {
      const { page, page_size } = thunkAPI.getState().medicalConditions;
      const query = {
        page,
        page_size,
        ...(queryObj && { ...queryObj }),
      };
      const response = await getMedConditionsList(isWithQueries && query);

      return {
        data: response.data,
        page: +query.page,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
