import { adminClient, oAuthClient } from "../utils/axios";

export const authLogin = formData => {
  return oAuthClient.post("token/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const authRefreshToken = formData => {
  return oAuthClient.post("token/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getUserPermissions = token => {
  return adminClient.get("permissions/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserData = token => {
  return adminClient.get("admin/me/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
