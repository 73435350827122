import React from "react";
import classNames from "classnames";
import { Button } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import Select from "../Select/Select";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import { useDispatch } from "react-redux";

const MedicalCheckoutServiceItemForm = ({
  services,
  order,
  name,
  control,
  rules,
  handleDeleteService,
}) => {
  const dispatch = useDispatch();
  const { register, setValue, watch } = useFormContext();

  const servicesForDelete = watch("deleted_services");

  const deleteService = item => {
    dispatch(
      showSubmitConfirmation({
        message: "Are you sure you want to delete this service?",
        onConfirm: () => {
          try {
            if (item?.id) {
              setValue("deleted_services", [...servicesForDelete, item], { shouldDirty: true });
            }

            handleDeleteService(order - 1);
          } catch (error) {
            console.error(error);
          }
        },
      }),
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error, isDirty } }) => {
        const handleServiceChange = (serviceName, serviceValue) => {
          const service = services.find(s => s.id === serviceValue);
          setValue(serviceName, serviceValue, { shouldDirty: true });

          if (!service) return;

          setValue(`${name}.price`, service.price, { shouldDirty: true });
          setValue(`${name}.duration_minutes`, service.duration_minutes, { shouldDirty: true });
        };

        const handleDeleteService = () => {
          deleteService(field.value);
        };

        return (
          <div className="rounded p-3 shadow">
            <div className="mb-3">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <label className="form-label mb-0">{`${order}. Service`}</label>
                {!watch(`${name}.id`) && (
                  <span className={`badge bg-soft-success`}>New added service</span>
                )}
                {watch(`${name}.id`) && isDirty && (
                  <span className={`badge bg-soft-warning`}>Service changed</span>
                )}
                <Button
                  size="sm"
                  className="btn-pills btn-soft-danger btn-sm mx-1"
                  onClick={handleDeleteService}
                >
                  Delete
                </Button>
              </div>
              <Select
                backspaceRemovesValue={false}
                className={classNames("form-control", "p-0", {
                  "is-invalid": error?.service,
                })}
                name={`${name}.service`}
                hideSelectedOptions={false}
                isClearable={false}
                isMulti={false}
                onChange={handleServiceChange}
                optionsData={services}
                placeholder="Select Service"
                tabSelectsValue={false}
                initialValue={watch(`${name}.service`)}
              />

              <div className="invalid-feedback">{error?.service?.message}</div>
            </div>
            <div className="mb-3 col-12 d-flex gap-2 flex-wrap">
              <div className="flex-grow-1">
                <label className="form-label">{`Duration Minutes`}</label>
                <input
                  id={`${name}.duration_minutes`}
                  type="text"
                  inputMode="numeric"
                  className={classNames("form-control", {
                    "is-invalid": error?.duration_minutes,
                  })}
                  placeholder="Duration minutes"
                  {...register(`${name}.duration_minutes`)}
                  onChange={e => {
                    const cleanedValue = e.target.value.replace(/\D/g, "");
                    setValue(`${name}.duration_minutes`, cleanedValue, { shouldDirty: true });
                  }}
                />
                <div className="invalid-feedback">{error?.duration_minutes?.message}</div>
              </div>
              <div className="flex-grow-1">
                <label className="form-label">{`Price`}</label>
                <input
                  id={`${name}.price`}
                  type="number"
                  step="any"
                  className={classNames("form-control", {
                    "is-invalid": error?.price,
                  })}
                  placeholder="Price"
                  {...register(`${name}.price`)}
                />
                <div className="invalid-feedback">{error?.price?.message}</div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-label">Comment</label>
                <textarea
                  rows="2"
                  className={classNames("form-control", {
                    "is-invalid": error?.comment,
                  })}
                  placeholder="Comment"
                  {...register(`${name}.comment`)}
                />
                <div className="invalid-feedback">{error?.comment?.message}</div>
              </div>
            </div>
            <div className="d-flex gap-1"></div>
          </div>
        );
      }}
    />
  );
};

export default MedicalCheckoutServiceItemForm;
