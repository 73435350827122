import React, { useEffect } from "react";
import DoctorUnavailableTimeItem from "./DoctorUnavailableTimeItem";
import { useDispatch, useSelector } from "react-redux";
import * as calendarActions from "../../store/actions/calendar";

const DoctorUnavailableTimeList = ({ handleOpenUnavailableTimeForm, isCanEdit }) => {
  const dispatch = useDispatch();

  const doctorProfile = useSelector(s => s.doctors.doctorProfile);
  const schedule = useSelector(s => s.calendar.schedule.list);
  useEffect(() => {
    dispatch(
      calendarActions.getCalendarList({
        isWithQueries: true,
        queryObj: {
          doctor: doctorProfile.id,
          entry_type: "DOCTOR_UNAVAILABLE",
          start: null,
          end: null,
          page: null,
          page_size: null,
        },
      }),
    );
  }, [dispatch, doctorProfile.id]);

  return (
    <>
      {schedule.map(item => {
        return (
          <DoctorUnavailableTimeItem
            key={item.id}
            item={item}
            handleOpenUnavailableTimeForm={handleOpenUnavailableTimeForm}
            doctorProfile={doctorProfile}
            isCanEdit={isCanEdit}
          />
        );
      })}
    </>
  );
};

export default DoctorUnavailableTimeList;
