export const isCheckTimeConflict = false;

export const pagesWithSearch = ["/doctors", "/patients", "/services", "/appointment", "/feedbacks"];

export const status = {
  ACTIVE: { label: "Active", value: "ACTIVE", badgeClassName: "bg-soft-success" },
  IN_PROGRESS: { label: "In progress", value: "IN_PROGRESS", badgeClassName: "bg-soft-success" },
  IS_ACCEPTED: { label: "Accepted", value: "ACCEPTED", badgeClassName: "bg-soft-success" },
  PENDING: { label: "Pending", value: "PENDING", badgeClassName: "bg-soft-warning" },
  IS_PENDING_APPROVAL: {
    label: "Pending approval",
    value: "PENDING APPROVAL",
    badgeClassName: "bg-soft-warning",
  },
  CANCELED: { label: "Canceled", value: "CANCELED", badgeClassName: "bg-soft-danger" },
  IS_REJECTED: { label: "Rejected", value: "REJECTED", badgeClassName: "bg-soft-danger" },
  DRAFT: { label: "Draft", value: "DRAFT", badgeClassName: "bg-soft-secondary" },
  COMPLETED: { label: "Completed", value: "COMPLETED", badgeClassName: "bg-soft-info" },
};
