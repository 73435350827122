import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { history } from "../../utils/history";
import { FaRegCalendarAlt } from "react-icons/fa";
import moment from "moment";
import Select from "../Select/Select";
import { addPatient, updatePatient } from "../../store/actions/patient";
import { normalizePhoneNumber } from "../../utils/normalizePhoneNumber";

const PatientEditForm = ({ patientProfile = {}, buttonText = "Submit" }) => {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters")
      .matches(/^[A-Za-z]+$/, "First Name can only contain letters"),
    last_name: Yup.string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters")
      .matches(/^[A-Za-z]+$/, "Last Name can only contain letters"),
    middle_name: Yup.string(),
    birth_date: Yup.date()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .max(new Date(), "Birthday cannot be in the future")
      .nullable(),
    email: Yup.string().email("Email is not the right format"),
    gender: Yup.string(),
    phone: Yup.string()
      .transform(value => (value ? value.replace(/[^\d+]/g, "") : ""))
      .nullable()
      .test("phone-length", "Phone number should not be less than 11 numbers", value => {
        return !value || (value.length >= 12 && value.length <= 15);
      })
      .test("phone-format", "Not correct mobile format", value => {
        return !value || value.startsWith("+61");
      })
      .optional(),
    emergency_phone_number: Yup.string()
      .transform(value => (value ? value.replace(/[^\d+]/g, "") : ""))
      .nullable()
      .test("phone-length", "Phone number should not be less than 11 numbers", value => {
        return !value || (value.length >= 12 && value.length <= 15);
      })
      .test("phone-format", "Not correct mobile format", value => {
        return !value || value.startsWith("+61");
      })
      .optional(),
    emergency_name: Yup.string(),
    passport_number: Yup.string()
      .transform(value => (value === "" ? null : value))
      .nullable()
      .matches(
        /^[PEDN]{1}\d{7}[A-Z]{1}$/,
        "Invalid Passport Number format. Expected format: P1234567A",
      ),
    medicare_number: Yup.string()
      .nullable()
      .transform(value => (value === "" ? null : value))
      .matches(
        /^\d{4}\s\d{5}\s\d$/,
        "Invalid Medicare Number format. Expected format: 1234 56789 1",
      )
      .test("is-valid-medicare", "Invalid Medicare Number", value => {
        if (!value) return true;

        return validateMedicareNumber(value);
      }),
    medicare_valid_to: Yup.string().when("medicare_number", {
      is: value => value?.trim()?.length > 0,
      then: schema => schema.required("Medicare valid to is required"),
      otherwise: schema => schema.notRequired(),
    }),
    medicare_irn: Yup.number()
      .nullable()
      .transform((value, originalValue) => (originalValue === "" ? null : value))
      .when("medicare_number", {
        is: value => Boolean(value) && value !== "",
        then: schema => schema.required("Medicare IRN is required"),
        otherwise: schema => schema.notRequired(),
      }),
    private_insurance_number: Yup.string()
      .nullable()
      .transform(value => (value === "" ? null : value))
      .matches(
        /^[A-Za-z0-9-]{4,14}$/,
        "Invalid Private Insurance Number format. It should be 4 to 14 characters, with letters, numbers, or a combination.",
      ),
    private_insurance_valid_to: Yup.string().when("private_insurance_number", {
      is: value => value?.trim()?.length > 0,
      then: schema => schema.required("Private insurance valid to is required"),
      otherwise: schema => schema.notRequired(),
    }),
    private_insurance_irn: Yup.number()
      .nullable()
      .transform((value, originalValue) => (originalValue === "" ? null : value))
      .when("private_insurance_number", {
        is: value => Boolean(value) && value !== "",
        then: schema => schema.required("Private insurance IRN is required"),
        otherwise: schema => schema.notRequired(),
      }),
    address: Yup.string(),
    // religion: Yup.string(),
    nationality: Yup.string(),
    is_atsis: Yup.boolean(),
    is_war_veteran: Yup.boolean(),
    is_pensioner: Yup.boolean(),
    gp: Yup.string(),
    postcode: Yup.string()
      .transform((value, originalValue) => (originalValue === "" ? null : value))
      .nullable()
      .matches(/^\d{4}$/, {
        message: "Postcode must contain up to 4 digits",
        excludeEmptyString: true,
      }),
  });

  const validateMedicareNumber = value => {
    const cleanedValue = value.replace(/\s/g, "");

    if (cleanedValue.length !== 10 || !/^\d{10}$/.test(cleanedValue)) {
      return false;
    }

    const mainNumber = cleanedValue.slice(0, 8);
    const checkDigit = parseInt(cleanedValue.slice(9, 10), 10);

    const weights = [1, 3, 7, 9, 1, 3, 7, 9];
    let sum = 0;

    for (let i = 0; i < 8; i++) {
      sum += parseInt(mainNumber[i], 10) * weights[i];
    }

    const calculatedCheckDigit = sum % 10;

    return calculatedCheckDigit === checkDigit;
  };

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState, setValue, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (patientProfile.id) {
      setValue("first_name", patientProfile.first_name || "");
      setValue("last_name", patientProfile.last_name || "");
      setValue("middle_name", patientProfile.middle_name || "");
      setValue(
        "birth_date",
        patientProfile.birth_date ? moment(patientProfile.birth_date).toDate() : null,
      );
      setValue("gender", patientProfile.gender || "");
      setValue("email", patientProfile.email || "");
      setValue("phone", normalizePhoneNumber(patientProfile.phone) || "");
      setValue(
        "emergency_phone_number",
        normalizePhoneNumber(patientProfile.emergency_phone_number) || "",
      );
      setValue("emergency_name", patientProfile.emergency_name || "");
      setValue("passport_number", patientProfile.passport_number || "");
      setValue("medicare_number", patientProfile.medicare_number || "");
      setValue("medicare_valid_to", patientProfile.medicare_valid_to || "");
      setValue("medicare_irn", patientProfile.medicare_irn || "");
      setValue("private_insurance_number", patientProfile.private_insurance_number || "");
      setValue("private_insurance_valid_to", patientProfile.private_insurance_valid_to || "");
      setValue("private_insurance_irn", patientProfile.private_insurance_irn || "");
      setValue("address", patientProfile.address || "");
      // setValue("religion", patientProfile.religion || "");
      setValue("postcode", patientProfile.postcode || "");
      setValue("nationality", patientProfile.nationality || "");
      setValue("is_atsis", patientProfile.is_atsis || false);
      setValue("is_war_veteran", patientProfile.is_war_veteran || false);
      setValue("is_pensioner", patientProfile.is_pensioner || false);
      setValue("gp", patientProfile.gp || "");
    }
  }, [patientProfile, setValue]);

  const checkIfRequired = fieldName => {
    const fieldSchema = validationSchema.describe().fields[fieldName];

    return fieldSchema.tests.some(test => test.name === "required" || test.name === "min");
  };

  const onSubmit = ({
    first_name,
    last_name,
    middle_name,
    birth_date,
    gender,
    phone,
    emergency_phone_number,
    emergency_name,
    passport_number,
    medicare_number,
    medicare_valid_to,
    medicare_irn,
    private_insurance_number,
    private_insurance_valid_to,
    private_insurance_irn,
    address,
    postcode,
    // religion,
    nationality,
    is_atsis,
    is_war_veteran,
    is_pensioner,
    gp,
    email,
  }) => {
    if (patientProfile.id) {
      dispatch(
        updatePatient({
          id: patientProfile.id,
          first_name,
          last_name,
          middle_name,
          birth_date: birth_date ? moment(birth_date).format("YYYY-MM-DD") : null,
          gender,
          phone,
          emergency_phone_number,
          emergency_name,
          passport_number,
          medicare_number,
          medicare_valid_to: medicare_valid_to
            ? moment(medicare_valid_to).format("YYYY-MM-DD")
            : null,
          medicare_irn,
          private_insurance_number,
          private_insurance_valid_to: private_insurance_valid_to
            ? moment(private_insurance_valid_to).format("YYYY-MM-DD")
            : null,
          private_insurance_irn,
          address,
          postcode,
          // religion,
          nationality,
          is_atsis,
          is_war_veteran,
          is_pensioner,
          gp,
          email,
        }),
      );
    } else {
      dispatch(
        addPatient({
          first_name,
          last_name,
          middle_name,
          birth_date: birth_date ? moment(birth_date).format("YYYY-MM-DD") : null,
          gender,
          phone,
          emergency_phone_number,
          emergency_name,
          passport_number,
          medicare_number,
          medicare_valid_to: medicare_valid_to
            ? moment(medicare_valid_to).format("YYYY-MM-DD")
            : null,
          medicare_irn,
          private_insurance_number,
          private_insurance_valid_to: private_insurance_valid_to
            ? moment(private_insurance_valid_to).format("YYYY-MM-DD")
            : null,
          private_insurance_irn,
          address,
          postcode,
          // religion,
          nationality,
          is_atsis,
          is_war_veteran,
          is_pensioner,
          gp,
          email,
        }),
      );
      history.navigate("/patients");
    }
  };

  const formatMedicareNumber = value => {
    const cleanedValue = value.replace(/\D/g, "");

    const part1 = cleanedValue.slice(0, 4);
    const part2 = cleanedValue.slice(4, 9);
    const part3 = cleanedValue.slice(9, 10);

    let formattedValue = part1;

    if (part2) formattedValue += ` ${part2}`;

    if (part3) formattedValue += ` ${part3}`;

    return formattedValue;
  };

  return (
    <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`First Name${checkIfRequired("first_name") ? " *" : ""}`}</label>
            <input
              name="first_name"
              id="first_name"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.first_name,
              })}
              placeholder="First Name"
              {...register("first_name")}
              onChange={e => {
                const cleanedValue = e.target.value.replace(/[^A-Za-z]/g, "");
                setValue("first_name", cleanedValue, { shouldDirty: true });
              }}
            />
            <div className="invalid-feedback">{errors.first_name?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Last Name${checkIfRequired("last_name") ? " *" : ""}`}</label>
            <input
              name="last_name"
              id="last_name"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.last_name,
              })}
              placeholder="Last Name"
              {...register("last_name")}
              onChange={e => {
                const cleanedValue = e.target.value.replace(/[^A-Za-z]/g, "");
                setValue("last_name", cleanedValue, { shouldDirty: true });
              }}
            />
            <div className="invalid-feedback">{errors.last_name?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Middle Name${checkIfRequired("middle_name") ? " *" : ""}`}</label>
            <input
              name="middle_name"
              id="middle_name"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.middle_name,
              })}
              placeholder="Middle Name"
              {...register("middle_name")}
            />
            <div className="invalid-feedback">{errors.middle_name?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3 d-flex flex-column">
            <label className="form-label">{`Birthday${checkIfRequired("birth_date") ? " *" : ""}`}</label>
            <DatePicker
              showIcon
              closeOnScroll={false}
              showYearDropdown
              yearDropdownItemNumber={110}
              scrollableYearDropdown
              maxDate={moment().subtract(1, "year").toDate()}
              minDate={moment().subtract(110, "years").toDate()}
              icon={<FaRegCalendarAlt />}
              selected={
                watch("birth_date") ? moment(watch("birth_date"), "YYYY-MM-DD").toDate() : null
              }
              onChange={date => {
                setValue("birth_date", date ? moment(date).format("YYYY-MM-DD") : "", {
                  shouldValidate: true,
                });
              }}
              dateFormat="dd.MM.yyyy"
              placeholderText="Select birth date"
              className={classNames("form-control", {
                "is-invalid": errors.birth_date,
              })}
            />
            {errors.birth_date && (
              <div className="invalid-feedback d-block">{errors.birth_date.message}</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Email${checkIfRequired("email") ? " *" : ""}`}</label>
            <input
              name="email"
              id="email"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.email,
              })}
              placeholder="Email"
              {...register("email")}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Contact Phone Number${checkIfRequired("phone") ? " *" : ""}`}</label>
            <input
              name="phone"
              id="phone"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.phone,
              })}
              placeholder="Contact Phone Number"
              {...register("phone")}
              onBlur={e => {
                const normalized = normalizePhoneNumber(e.target.value);
                setValue("phone", normalized, { shouldValidate: true });
              }}
            />
            <div className="invalid-feedback">{errors.phone?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Emergency Phone Number${checkIfRequired("emergency_phone_number") ? " *" : ""}`}</label>
            <input
              name="emergency_phone_number"
              id="emergency_phone_number"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.emergency_phone_number,
              })}
              placeholder="Emergency Phone Number"
              {...register("emergency_phone_number")}
              onBlur={e => {
                const normalized = normalizePhoneNumber(e.target.value);
                setValue("emergency_phone_number", normalized, { shouldValidate: true });
              }}
            />
            <div className="invalid-feedback">{errors.emergency_phone_number?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Emergency Contact Name${checkIfRequired("emergency_name") ? " *" : ""}`}</label>
            <input
              name="emergency_name"
              id="emergency_name"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.emergency_name,
              })}
              placeholder="Emergency Contact Name"
              {...register("emergency_name")}
            />
            <div className="invalid-feedback">{errors.emergency_name?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Passport Number${checkIfRequired("passport_number") ? " *" : ""}`}</label>
            <input
              name="passport_number"
              id="passport_number"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.passport_number,
              })}
              placeholder="Passport Number: P1234567A"
              {...register("passport_number")}
            />
            <div className="invalid-feedback">{errors.passport_number?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Address${checkIfRequired("address") ? " *" : ""}`}</label>
            <input
              name="address"
              id="address"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.address,
              })}
              placeholder="Address"
              {...register("address")}
            />
            <div className="invalid-feedback">{errors.address?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Postcode${checkIfRequired("postcode") ? " *" : ""}`}</label>
            <input
              name="postcode"
              id="postcode"
              type="text"
              inputMode="numeric"
              className={classNames("form-control", {
                "is-invalid": errors.postcode,
              })}
              placeholder="Postcode"
              {...register("postcode")}
              onChange={e => {
                const cleanedValue = e.target.value.replace(/\D/g, "");
                setValue("postcode", cleanedValue, { shouldDirty: true });
              }}
            />
            <div className="invalid-feedback">{errors.postcode?.message}</div>
          </div>
        </div>
        {/*<div className="col-md-6">*/}
        {/*  <div className="mb-3">*/}
        {/*    <label className="form-label">{`Religion${checkIfRequired("religion") ? " *" : ""}`}</label>*/}
        {/*    <input*/}
        {/*      name="religion"*/}
        {/*      id="religion"*/}
        {/*      type="text"*/}
        {/*      className={classNames("form-control", {*/}
        {/*        "is-invalid": errors.religion,*/}
        {/*      })}*/}
        {/*      placeholder="Religion"*/}
        {/*      {...register("religion")}*/}
        {/*    />*/}
        {/*    <div className="invalid-feedback">{errors.religion?.message}</div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Nationality${checkIfRequired("nationality") ? " *" : ""}`}</label>
            <input
              name="nationality"
              id="nationality"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.nationality,
              })}
              placeholder="Nationality"
              {...register("nationality")}
            />
            <div className="invalid-feedback">{errors.nationality?.message}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Gender${checkIfRequired("gender") ? " *" : ""}`}</label>
            <Select
              isSearchable={false}
              isMulti={false}
              onChange={setValue}
              name="gender"
              optionsData={[
                { value: "male", name: "Male" },
                { value: "female", name: "Female" },
                { value: "other", name: "Other" },
              ]}
              valueField="value"
              labelField="name"
              placeholder="Select gender"
              className={classNames("form-control", "p-0", {
                "is-invalid": errors.gender,
              })}
              initialValue={watch("gender")}
            />
            <div className="invalid-feedback">{errors.gender?.message}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Medicare Number${checkIfRequired("medicare_number") ? " *" : ""}`}</label>
            <input
              name="medicare_number"
              id="medicare_number"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.medicare_number,
              })}
              placeholder="Medicare Number: 1234 56789 1"
              {...register("medicare_number")}
              onChange={e => {
                const formattedValue = formatMedicareNumber(e.target.value);
                setValue("medicare_number", formattedValue, {
                  shouldValidate: true,
                });
              }}
            />
            <div className="invalid-feedback">{errors.medicare_number?.message}</div>
          </div>
        </div>
        {watch("medicare_number") && (
          <>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">{`IRN${checkIfRequired("medicare_irn") ? " *" : ""}`}</label>
                <input
                  name="medicare_irn"
                  id="medicare_irn"
                  type="text"
                  className={classNames("form-control", {
                    "is-invalid": errors.medicare_irn,
                  })}
                  inputMode="numeric"
                  placeholder="0"
                  {...register("medicare_irn")}
                  onChange={e => {
                    const cleanedValue = e.target.value.replace(/\D/g, "");
                    setValue("medicare_irn", cleanedValue, { shouldDirty: true });
                  }}
                />
                <div className="invalid-feedback">{errors.medicare_irn?.message}</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 d-flex flex-column">
                <label className="form-label">{`Medicare Valid To${checkIfRequired("medicare_valid_to") ? " *" : ""}`}</label>
                <DatePicker
                  showIcon
                  closeOnScroll={true}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  icon={<FaRegCalendarAlt />}
                  selected={
                    watch("medicare_valid_to")
                      ? moment(watch("medicare_valid_to"), "YYYY-MM-DD").toDate()
                      : null
                  }
                  onChange={date => {
                    setValue("medicare_valid_to", date ? moment(date).format("YYYY-MM-DD") : "", {
                      shouldValidate: true,
                    });
                  }}
                  dateFormat="dd.MM.yyyy"
                  placeholderText="Select medicare valid to"
                  className={classNames("form-control", {
                    "is-invalid": errors.medicare_valid_to,
                  })}
                />
                {errors.medicare_valid_to && (
                  <div className="invalid-feedback d-block">{errors.medicare_valid_to.message}</div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{`Private Insurance Number${checkIfRequired("private_insurance_number") ? " *" : ""}`}</label>
            <input
              name="private_insurance_number"
              id="private_insurance_number"
              type="text"
              className={classNames("form-control", {
                "is-invalid": errors.private_insurance_number,
              })}
              placeholder="Private Insurance Number"
              {...register("private_insurance_number")}
            />
            <div className="invalid-feedback">{errors.private_insurance_number?.message}</div>
          </div>
        </div>
        {watch("private_insurance_number") && (
          <>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">{`Private Insurance IRN${checkIfRequired("private_insurance_irn") ? " *" : ""}`}</label>
                <input
                  name="private_insurance_irn"
                  id="private_insurance_irn"
                  type="text"
                  inputMode="numeric"
                  className={classNames("form-control", {
                    "is-invalid": errors.private_insurance_irn,
                  })}
                  placeholder="0"
                  {...register("private_insurance_irn")}
                  onChange={e => {
                    const cleanedValue = e.target.value.replace(/\D/g, "");
                    setValue("private_insurance_irn", cleanedValue, { shouldDirty: true });
                  }}
                />
                <div className="invalid-feedback">{errors.private_insurance_irn?.message}</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 d-flex flex-column">
                <label className="form-label">{`Private Insurance valid to${checkIfRequired("private_insurance_valid_to") ? " *" : ""}`}</label>
                <DatePicker
                  showIcon
                  closeOnScroll={true}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  icon={<FaRegCalendarAlt />}
                  selected={
                    watch("private_insurance_valid_to")
                      ? moment(watch("private_insurance_valid_to"), "YYYY-MM-DD").toDate()
                      : null
                  }
                  onChange={date => {
                    setValue(
                      "private_insurance_valid_to",
                      date ? moment(date).format("YYYY-MM-DD") : "",
                      {
                        shouldValidate: true,
                      },
                    );
                  }}
                  dateFormat="dd.MM.yyyy"
                  placeholderText="Select private insurance valid valid to"
                  className={classNames("form-control", {
                    "is-invalid": errors.private_insurance_valid_to,
                  })}
                />
                {errors.private_insurance_valid_to && (
                  <div className="invalid-feedback d-block">
                    {errors.private_insurance_valid_to.message}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="row">
        <div className="form-check">
          <div className="px-3">
            <input
              className={classNames("form-check-input", {
                "is-invalid": errors.is_atsis,
              })}
              type="checkbox"
              value=""
              id="is_atsis"
              {...register("is_atsis")}
            />
            <label className="form-check-label" htmlFor="is_atsis">
              {`Is atsis${checkIfRequired("is_atsis") ? " *" : ""}`}
            </label>
          </div>
        </div>
        <div className="form-check">
          <div className="px-3">
            <input
              className={classNames("form-check-input", {
                "is-invalid": errors.is_war_veteran,
              })}
              type="checkbox"
              value=""
              id="is_war_veteran"
              {...register("is_war_veteran")}
            />
            <label className="form-check-label" htmlFor="is_war_veteran">
              {`Is war veteran${checkIfRequired("is_war_veteran") ? " *" : ""}`}
            </label>
          </div>
        </div>
        <div className="form-check">
          <div className="px-3">
            <input
              className={classNames("form-check-input", {
                "is-invalid": errors.is_pensioner,
              })}
              type="checkbox"
              value=""
              id="is_pensioner"
              {...register("is_pensioner")}
            />
            <label className="form-check-label" htmlFor="is_pensioner">
              {`Is pensioner${checkIfRequired("is_pensioner") ? " *" : ""}`}
            </label>
          </div>
        </div>
        <div className="col-md-12">
          <div className="mb-3">
            <label className="form-label">{`GP${checkIfRequired("nationality") ? " *" : ""}`}</label>
            <textarea
              name="gp"
              id="gp"
              rows="3"
              className={classNames("form-control", {
                "is-invalid": errors.gp,
              })}
              placeholder="GP"
              {...register("gp")}
            />
            <div className="invalid-feedback">{errors.gp?.message}</div>
          </div>
        </div>
      </div>
      <button disabled={isSubmitting} className="btn btn-primary">
        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
        {buttonText}
      </button>
    </form>
  );
};

export default PatientEditForm;
