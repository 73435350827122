import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { submitEventForm } from "../../store/actions/calendar";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import StatusSelect from "../Select/StatusSelect";
import { status } from "../../config/settings";

const StatusChangeForm = ({ isShowStatusEditForm, handleClose }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const eventItem = useSelector(s => s.calendar.dialog.selected);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    status: Yup.string(),
  });
  const defaultValues = {
    status: "ACTIVE",
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  };
  const methods = useForm(formOptions);
  const { handleSubmit, formState, setValue, reset, control } = methods;
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (eventItem?.id) {
      reset({
        status: eventItem?.status || "ACTIVE",
      });
      setIsEditMode(true);
    } else {
      setValue("entry_type", "APPOINTMENT", {
        shouldDirty: false,
      });

      setIsEditMode(false);
    }
  }, [eventItem, reset, setValue]);

  const onSubmit = ({ status }) => {
    return dispatch(
      submitEventForm({
        id: eventItem?.id || null,
        eventData: {
          ...eventItem,
          status,
        },
      }),
    ).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        reset(defaultValues);
        handleClose();
      } else {
        console.error("Submission failed:", response.payload);
      }
    });
  };

  const handleFormClose = () => {
    if (formState.isDirty) {
      dispatch(
        showSubmitConfirmation({
          message: "Are you sure you want to exit? All unsaved data will be lost.",
          onConfirm: () => {
            reset(defaultValues);
            handleClose();
          },
        }),
      );
    }

    if (!formState.isDirty) {
      reset(defaultValues);
      handleClose();
    }
  };

  return (
    <div>
      <Offcanvas
        show={isShowStatusEditForm}
        onHide={() => {
          if (isEditMode) {
            handleFormClose();
          }
        }}
        backdrop={isEditMode}
        keyboard={false}
        placement="end"
        className="offcanvasEventEditForm"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>{`${isEditMode ? "Edit" : "Create"} Status Form`}</Offcanvas.Title>
          <div className="d-flex gap-1">
            <Button variant="secondary" onClick={handleFormClose}>
              Close
            </Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FormProvider {...methods}>
            <form className="login-form mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Appointment status</label>
                    <StatusSelect
                      name="status"
                      placeholder="Select appointment status"
                      control={control}
                      statusFlags={status}
                      statusKeys={["ACTIVE", "PENDING", "CANCELED", "COMPLETED"]}
                      className={classNames("form-control", "p-0", {
                        "is-invalid": errors.status,
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-12 mb-0">
                  <div className="d-grid">
                    <button
                      disabled={isSubmitting || !formState.isDirty}
                      className="btn btn-primary"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default StatusChangeForm;
