import React, { useEffect } from "react";
import classNames from "classnames";
import { useForm, FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { history } from "../../../utils/history";
import StatusSelect from "../../Select/StatusSelect";

const SelectFilter = ({
  pageState,
  filterName,
  options,
  placeholder = "Select Filter",
  statusKeys,
}) => {
  const state = useSelector(state => {
    return get(state, pageState, {});
  });
  const defaultValues = {
    [filterName]:
      Object.fromEntries(new URLSearchParams(location.search))?.[filterName] || state[filterName],
  };

  const formOptions = {
    defaultValues: defaultValues,
  };
  const methods = useForm(formOptions);
  const { setValue, reset, control, watch } = methods;

  useEffect(() => {
    if (state[filterName]) {
      reset({
        [filterName]: state[filterName] || "",
      });
    }
  }, [filterName, reset, setValue, state]);

  const selectedValue = watch(filterName);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);

    if (selectedValue) {
      params.set(filterName, selectedValue);
    } else {
      params.delete(filterName);
    }

    history.navigate({
      pathname: history.location.pathname,
      search: params.toString(),
    });
  }, [filterName, selectedValue]);

  return (
    <FormProvider {...methods}>
      <form className="login-form">
        <div className="form-group">
          <StatusSelect
            name="status"
            placeholder={placeholder}
            control={control}
            statusFlags={options}
            statusKeys={statusKeys}
            isClearable={true}
            className={classNames("form-control", "p-0")}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default SelectFilter;
