import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFeedbacksList, removeFeedbackItem } from "../../api/feedbacks";

export const feedbackSliceName = "feedback";

export const getFeedbackList = createAsyncThunk(
  `${feedbackSliceName}/list`,
  async ({ queryObj, isWithQueries = false }, thunkAPI) => {
    try {
      const { page, page_size, ordering, patient } = thunkAPI.getState().feedback;
      const query = {
        page,
        page_size,
        ordering,
        patient,
        ...(queryObj && { ...queryObj }),
      };
      const response = await getFeedbacksList(isWithQueries && query);

      return {
        data: response.data,
        page: +query.page,
        ordering: query.ordering,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const removeFeedback = createAsyncThunk(
  `${feedbackSliceName}/deleteFeedback`,
  async (id, thunkAPI) => {
    try {
      const response = await removeFeedbackItem(id);

      return {
        data: response.data,
        successMessage: "Feedback removed successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to remove feedback" });
    }
  },
);
