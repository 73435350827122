import styles from "./Loader.module.scss";

const Loader = ({ size = 28, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      className={styles.loader}
      style={style}
    >
      <path
        fill="#5EB6C1"
        fillRule="evenodd"
        d="M14 2.577C7.691 2.577 2.577 7.69 2.577 14S7.69 25.423 14 25.423c2.293 0 4.425-.674 6.212-1.835a1.039 1.039 0 1 1 1.131 1.742A13.44 13.44 0 0 1 14 27.5C6.544 27.5.5 21.456.5 14S6.544.5 14 .5 27.5 6.544 27.5 14c0 1.427-.222 2.804-.634 4.098a1.038 1.038 0 1 1-1.979-.63c.348-1.092.536-2.257.536-3.468C25.423 7.691 20.31 2.577 14 2.577"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Loader;
