import React, { useEffect, useState } from "react";

const FileLoader = ({
  item = {},
  removeFile,
  isShowRemoveButton = true,
  width = 100,
  height = 100,
  fileSize = 100,
  spinnerOptions,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const attachmentUrl = item.safe_url;
  const isImage = item.mime_type?.startsWith("image/");
  const isPdf = item.mime_type === "application/pdf";

  useEffect(() => {
    if (isPdf) {
      setIsLoading(false);
    }
  }, [isPdf]);

  const handleRemoveFile = e => {
    removeFile(e, item.id);
  };

  return (
    <>
      {isImage ? (
        <>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#f8f9fa",
                borderRadius: "5px",
              }}
            >
              <Spinner
                as="span"
                variant="primary"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
                {...spinnerOptions}
              />
            </div>
          )}
          <img
            src={`${attachmentUrl}?x=${fileSize}`}
            alt="Preview"
            className="img-thumbnail"
            style={{
              width: `${width}px`,
              height: `${height}px`,
              objectFit: "cover",
              opacity: isLoading ? 0 : 1,
              transition: "opacity 0.3s ease-in-out",
            }}
            onLoad={() => setIsLoading(false)}
          />
        </>
      ) : isPdf ? (
        <div
          className="d-flex align-items-center justify-content-center bg-light border rounded"
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          <FaFilePdf size={40} className="text-danger" />
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center bg-light border rounded"
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          <FaFileAlt size={40} className="text-secondary" />
        </div>
      )}
      {!isLoading && (
        <>
          {isShowRemoveButton && (
            <button
              className="btn btn-danger position-absolute p-0 d-flex align-items-center justify-content-center"
              style={{
                top: "5px",
                right: "5px",
                width: "16px",
                height: "16px",
                fontSize: "10px",
                lineHeight: "1",
                borderRadius: "50%",
              }}
              type="button"
              onClick={handleRemoveFile}
            >
              ✖
            </button>
          )}
          <div className="small text-truncate" style={{ maxWidth: `${width}px` }}>
            {item.filename}
          </div>
        </>
      )}
    </>
  );
};

import { FaFileAlt, FaFilePdf } from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";

export default FileLoader;
