import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorsList } from "../../store/actions/doctor";
import query from "querystringify";
import DoctorItem from "../../components/doctors/DoctorItem";
import { getSpecializations } from "../../store/actions/specialization";
import Pagination from "../../components/Pagination/Pagination";
import { resetDoctorsState } from "../../store/reducers/doctorSlice";
import { history } from "../../utils/history";
import { useAccess } from "../../hooks/useAccess";
import { controlledPages, permissions } from "../../config/accessControlConfig";

export default function Doctors() {
  const dispatch = useDispatch();
  const doctorData = useSelector(s => s.doctors.list);
  const queries = useMemo(() => query.parse(history.location.search), [history.location.search]);
  const { hasPermission } = useAccess();
  const isCanAddDoctor = hasPermission(controlledPages.addDoctor, permissions.add);
  useEffect(() => {
    dispatch(resetDoctorsState());
    dispatch(getSpecializations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDoctorsList({ queryObj: queries }));
  }, [dispatch, queries]);

  return (
    <>
      <div className="container-fluid">
        <div className="layout-specing">
          <div className="row">
            <div className="col-xl-9 col-md-6">
              <h5 className="mb-0">Doctors</h5>

              <nav aria-label="breadcrumb" className="d-inline-block mt-2">
                <ul className="breadcrumb breadcrumb-muted bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/">AOSmiles</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Doctors
                  </li>
                </ul>
              </nav>
            </div>
            {isCanAddDoctor && (
              <div className="col-xl-3 col-md-6 mt-4 mt-md-0 text-md-end">
                <Link to="/add-doctor" className="btn btn-primary">
                  Add New Doctor
                </Link>
              </div>
            )}
          </div>

          <div className="row row-cols-md-2 row-cols-lg-5">
            {doctorData.map(item => {
              return <DoctorItem item={item} key={item.id} />;
            })}
          </div>
          <Pagination paginatedPageState={"doctors"} />
        </div>
      </div>
    </>
  );
}
