import { configureStore } from "@reduxjs/toolkit";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";
import { adminClient, allAuthClient } from "../utils/axios";
import { CLIENT_ID } from "../config/api";
import { changeToken, logout } from "./reducers/authSlice";
import { authRefreshToken } from "../api/auth";
import Cookies from "js-cookie";
import { toastMiddleware } from "./middlewares/toastMiddleware";
import { resetChatState } from "./reducers/chatSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
  transforms: [createBlacklistFilter("auth", ["error"])],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.ENV !== "prod",
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "submitConfirmation/showSubmitConfirmation",
        ],
        ignoredPaths: [`submits.onConfirm`, `submits.onCancel`],
      },
    }).concat(toastMiddleware),
});

export const persistor = persistStore(store);

allAuthClient.interceptors.request.use(config => {
  const auth = store.getState()?.auth;

  const accessToken = auth?.tokens?.access_token;

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

adminClient.interceptors.request.use(config => {
  const auth = store.getState()?.auth;
  const accessToken = auth?.tokens?.access_token;

  if (accessToken) {
    Cookies.set("token", accessToken, {
      domain: "dev-site.space",
      "Max-Age": String(60 * 60 * 24 * 365),
      sameSite: "None",
      secure: true,
    });
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

let isRefreshing = false;
let refreshSubscribers = [];

function onRefreshed(accessToken) {
  refreshSubscribers.forEach(callback => callback(accessToken));
  refreshSubscribers = [];
}

function addRefreshSubscriber(callback) {
  refreshSubscribers.push(callback);
}

adminClient.interceptors.response.use(
  config => {
    return config;
  },
  async error => {
    const originalRequest = error.config;
    const auth = store.getState()?.auth;
    const refreshToken = auth?.tokens?.refresh_token;

    if (error.response?.status === 401 && originalRequest && !originalRequest._isRetry) {
      originalRequest._isRetry = true;

      if (isRefreshing) {
        return new Promise(resolve => {
          addRefreshSubscriber(accessToken => {
            originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
            resolve(adminClient.request(originalRequest));
          });
        });
      }

      isRefreshing = true;

      try {
        const formData = new FormData();
        formData.append("grant_type", "refresh_token");
        formData.append("refresh_token", refreshToken);
        formData.append("client_id", CLIENT_ID);

        const response = await authRefreshToken(formData);
        const { access_token, refresh_token } = response.data;

        Cookies.set("token", access_token, {
          domain: "dev-site.space",
          "Max-Age": String(60 * 60 * 24 * 365),
          sameSite: "None",
          secure: true,
        });

        store.dispatch(changeToken({ access_token, refresh_token }));

        isRefreshing = false;
        onRefreshed(access_token);

        originalRequest.headers["Authorization"] = `Bearer ${access_token}`;

        return adminClient.request(originalRequest);
      } catch (e) {
        store.dispatch(logout());
        store.dispatch(resetChatState());
        isRefreshing = false;
        refreshSubscribers = [];
        throw e;
      }
    }

    throw error;
  },
);
