import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { uploadPatientFiles } from "../../api/files";
import { FiPaperclip } from "react-icons/fi";
import { Link } from "react-router-dom";
import FileLoader from "../FileLoader/FileLoader";
import { checkIfRequired } from "../../utils/requiredField";
import { useDispatch } from "react-redux";
import { getPatientProfile, updateMedicalRecords } from "../../store/actions/patient";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";

const MedicalForm = ({ patientProfile, medicalConditions }) => {
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);

  const handleFileChange = async event => {
    const selectedFiles = Array.from(event.target.files);

    const newFiles = selectedFiles.filter(
      file => !uploadedFiles.some(existingFile => existingFile?.file?.name === file.name),
    );

    if (newFiles.length === 0) {
      return;
    }

    try {
      setIsUploadingFiles(true);
      const newUploadedFiles = await uploadPatientFiles(newFiles, patientProfile.id);
      setUploadedFiles(prevFiles => [...prevFiles, ...newUploadedFiles]);
      console.log("Uploaded file IDs:", newUploadedFiles);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setIsUploadingFiles(false);
    }
  };

  const removeFile = (e, id) => {
    e.preventDefault();
    const currentFile = uploadedFiles.find(file => file.id === id);
    dispatch(
      showSubmitConfirmation({
        message: `Are you sure you want to delete file: ${currentFile.filename}?`,
        onConfirm: () => {
          setUploadedFiles(prevFiles => prevFiles.filter(file => file.id !== id));
        },
      }),
    );
  };

  const validationSchema = Yup.object().shape({
    is_taking_medications: Yup.mixed()
      .oneOf(["true", "false", ""])
      .transform(value => (value === undefined ? "" : value)),
    medications_list: Yup.string().when("is_taking_medications", {
      is: "true",
      then: schema => schema.required("Medications list is required"),
      otherwise: schema => schema.notRequired(),
    }),
    is_pregnant: Yup.mixed()
      .oneOf(["true", "false", ""])
      .transform(value => (value === undefined ? "" : value)),
    conditions: Yup.array().of(Yup.string()).default([]),
    other_conditions: Yup.string().nullable(),
  });

  const defaultValues = {
    is_taking_medications: "",
    medications_list: "",
    is_pregnant: "",
    conditions: [],
    other_conditions: "",
  };

  const formOptions = { resolver: yupResolver(validationSchema), defaultValues };

  const { register, formState, handleSubmit, watch, setValue } = useForm(formOptions);

  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (patientProfile.medical_record_detail) {
      const medical_record_detail = patientProfile.medical_record_detail;
      setValue(
        "is_taking_medications",
        medical_record_detail.is_taking_medications ? "true" : "false",
      );
      setValue("medications_list", medical_record_detail.medications_list);
      setValue("is_pregnant", medical_record_detail.is_pregnant ? "true" : "false");
      setValue("conditions", medical_record_detail.conditions);
      setValue("other_conditions", medical_record_detail.other_conditions);
      setUploadedFiles(medical_record_detail.files_detail);
    }
  }, [patientProfile, setValue]);

  const onSubmit = async data => {
    console.log("Form submitted:", data);
    const currentData = {
      ...(patientProfile.medical_record_detail && { ...patientProfile.medical_record_detail }),
      ...data,
      is_taking_medications: data.is_taking_medications === "true" ? true : false,
      is_pregnant: data.is_pregnant === "true" ? true : false,
      files: uploadedFiles.map(file => file.id),
      files_detail: uploadedFiles,
    };

    await dispatch(updateMedicalRecords(currentData));
    dispatch(getPatientProfile({ id: patientProfile.id }));
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <div className="mb-4 d-flex flex-column">
          <label className="form-label">{`Are you currently taking any medications?${checkIfRequired("is_taking_medications", validationSchema)}`}</label>
          <label>
            <input type="radio" {...register("is_taking_medications")} value={true} /> Yes
          </label>
          <label>
            <input type="radio" {...register("is_taking_medications")} value={false} /> No
          </label>
          <div
            className={classNames("invalid-feedback", { "d-block": errors.is_taking_medications })}
          >
            {errors.is_taking_medications?.message}
          </div>
        </div>

        {watch("is_taking_medications") === "true" && (
          <div className="mb-4">
            <label className="form-label">{`If "Yes", please list all medications, pills or drugs *`}</label>
            <input
              type="text"
              className="form-control"
              {...register("medications_list")}
              placeholder="Type medications"
            />
            <div className={classNames("invalid-feedback", { "d-block": errors.medications_list })}>
              {errors.medications_list?.message}
            </div>
          </div>
        )}
        {patientProfile.gender !== "male" && (
          <div className="mb-4 d-flex flex-column">
            <label className="form-label">{`Are you currently pregnant?${checkIfRequired("is_pregnant", validationSchema)}`}</label>
            <label>
              <input type="radio" {...register("is_pregnant")} value="true" /> Yes
            </label>
            <label>
              <input type="radio" {...register("is_pregnant")} value="false" /> No
            </label>
            <div className={classNames("invalid-feedback", { "d-block": errors.is_pregnant })}>
              {errors.is_pregnant?.message}
            </div>
          </div>
        )}

        <div className="mb-4 d-flex flex-column">
          <label className="form-label">{`Do you have a history of any of the following conditions?${checkIfRequired("conditions", validationSchema)}`}</label>
          {medicalConditions.map(condition => {
            const selectedConditions = watch("conditions");

            return (
              <div key={condition.id}>
                <input
                  className={classNames("form-check-input")}
                  type="checkbox"
                  value={condition.id}
                  id={condition.id}
                  checked={selectedConditions.includes(condition.id)} // Проверяем, есть ли ID в массиве
                  onChange={e => {
                    const updatedConditions = e.target.checked
                      ? [...selectedConditions, condition.id] // Добавляем ID в массив
                      : selectedConditions.filter(id => id !== condition.id); // Удаляем ID из массива

                    setValue("conditions", updatedConditions, { shouldValidate: true });
                  }}
                />
                <label className="form-check-label ps-2" htmlFor={condition.id}>
                  {condition.name}
                </label>
              </div>
            );
          })}
          <div className={classNames("invalid-feedback", { "d-block": errors.conditions })}>
            {errors.conditions?.message}
          </div>
        </div>

        <div className="mb-4">
          <label className="form-label">{`Other${checkIfRequired("other_conditions", validationSchema)}`}</label>
          <input
            type="text"
            className={classNames("form-control", {
              "is-invalid": errors.other_conditions,
            })}
            {...register("other_conditions")}
            placeholder="List any conditions"
          />
          <div className={classNames("invalid-feedback", { "d-block": errors.other_conditions })}>
            {errors.other_conditions?.message}
          </div>
        </div>

        <div className="col-lg-12">
          <div className="mb-3 form-group">
            <label className="form-label me-2">Attachments</label>
            <label className="btn btn-icon btn-primary">
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/jpeg, image/png, application/pdf"
                multiple
                onClick={e => (e.target.value = null)}
                onChange={handleFileChange}
              />
              <FiPaperclip />
            </label>
            <div className="mb-2 d-flex flex-wrap">
              {isUploadingFiles && <p style={{ width: "100%" }}>Uploading files...</p>}
              {uploadedFiles.map((item, index) => {
                const attachmentUrl = item.safe_url;

                return (
                  <Link
                    to={attachmentUrl}
                    key={index}
                    className="position-relative m-2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FileLoader item={item} removeFile={removeFile} />
                  </Link>
                );
              })}
            </div>
          </div>
        </div>

        <button disabled={isSubmitting} className="btn btn-primary">
          {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
          Save changes
        </button>
      </form>
    </div>
  );
};

export default MedicalForm;
