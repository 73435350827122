import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";

const ImageLoader = ({ src, alt, spinnerOptions = {}, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!src) return;

    setIsLoading(true);

    const img = new Image();
    img.src = src;

    const handleLoad = () => setIsLoading(false);
    img.onload = handleLoad;
    img.onerror = handleLoad;

    if (img.complete) handleLoad();

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src]);

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#f8f9fa",
            borderRadius: "5px",
          }}
        >
          <Spinner
            as="span"
            variant="primary"
            animation="border"
            size="lg"
            role="status"
            aria-hidden="true"
            {...spinnerOptions}
          />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        style={{
          opacity: isLoading ? 0 : 1,
          transition: "opacity 0.3s ease-in-out",
        }}
        {...rest}
      />
    </>
  );
};

export default ImageLoader;
