import React, { useEffect, useState } from "react";
import TreatmentPlansItem from "./TreatmentPlansItem";
import { ListGroup } from "react-bootstrap";
import TreatmentStageForm from "./TreatmentStageForm";
import { getPatientAppointments } from "../../store/actions/patient";
import { useDispatch } from "react-redux";
import { getTreatmentStage } from "../../store/actions/treatments";
import { resetTreatmentStageState } from "../../store/reducers/treatmentsSlice";

const PatientTreatmentPlansList = ({
  treatmentPlans,
  patient,
  handleOpenAddTreatmentPlanForm,
  handleDeleteTreatmentPlan,
  handleShowAppointmentForm,
}) => {
  const [isOpenTreatmentStageForm, setIsOpenTreatmentStageForm] = useState(false);
  const [treatmentPlan, setTreatmentPlan] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatientAppointments(patient.id));
  }, [dispatch, patient, treatmentPlans]);

  const handleCloseForm = () => {
    dispatch(resetTreatmentStageState());
    setTreatmentPlan(null);
    setIsOpenTreatmentStageForm(false);
  };

  const handleOpenTreatmentStageForm = async (treatmentPlan, treatmentStageId) => {
    if (treatmentStageId) {
      await dispatch(getTreatmentStage(treatmentStageId));
    }

    setTreatmentPlan(treatmentPlan);
    setIsOpenTreatmentStageForm(true);
  };

  return (
    <>
      <ListGroup>
        {treatmentPlans?.map(plan => {
          return (
            <TreatmentPlansItem
              treatmentPlan={plan}
              key={plan.id}
              handleOpenTreatmentStageForm={handleOpenTreatmentStageForm}
              handleShowAppointmentForm={handleShowAppointmentForm}
              handleOpenAddTreatmentPlanForm={handleOpenAddTreatmentPlanForm}
              handleDeleteTreatmentPlan={handleDeleteTreatmentPlan}
            />
          );
        })}
      </ListGroup>
      <TreatmentStageForm
        handleClose={handleCloseForm}
        isShowEditForm={isOpenTreatmentStageForm}
        treatmentPlan={treatmentPlan}
        patient={patient}
      />
    </>
  );
};

export default PatientTreatmentPlansList;
