import React from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { history } from "../../../utils/history";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const Ordering = ({ orderingPageState, orderName }) => {
  const state = useSelector(state => {
    return get(state, orderingPageState, {});
  });
  const order = state[orderName];

  const handleOrderClick = () => {
    const params = new URLSearchParams(history.location.search);
    const ordering = order.startsWith("-") ? order.slice(1) : `-${order}`;
    params.set(orderName, ordering);
    history.navigate({
      pathname: history.location.pathname,
      search: params.toString(),
    });
  };

  return (
    <span onClick={handleOrderClick} style={{ cursor: "pointer" }}>
      {order.startsWith("-") ? <TiArrowSortedDown /> : <TiArrowSortedUp />}
    </span>
  );
};

export default Ordering;
