import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./reducers/authSlice";
import mainReducer from "./reducers/mainSlice";
import calendarReducer from "./reducers/calendarSlice";
import doctorReducer from "./reducers/doctorSlice";
import patientReducer from "./reducers/patientSlice";
import submitsSlice from "./reducers/submitsSlice";
import specializationSlice from "./reducers/specializationSlice";
import servicesSlice from "./reducers/servicesSlice";
import chatSlice from "./reducers/chatSlice";
import feedbackSlice from "./reducers/feedbackSlice";
import medicalConditionsSlice from "./reducers/medicalConditionsSlice";
import treatmentsSlice from "./reducers/treatmentsSlice";
import faqSlice from "./reducers/content";
import staffSlice from "./reducers/staffSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  main: mainReducer,
  calendar: calendarReducer,
  doctors: doctorReducer,
  patients: patientReducer,
  submits: submitsSlice,
  specializations: specializationSlice,
  services: servicesSlice,
  chat: chatSlice,
  feedback: feedbackSlice,
  medicalConditions: medicalConditionsSlice,
  treatments: treatmentsSlice,
  content: faqSlice,
  staff: staffSlice,
});

export default rootReducer;
