import { createAsyncThunk } from "@reduxjs/toolkit";
import { authLogin, getUserData, getUserPermissions } from "../../api/auth";

export const login = createAsyncThunk(`auth/login`, async ({ formData }, thunkAPI) => {
  try {
    const response = await authLogin(formData);
    const { access_token } = response.data;
    const permissionsResponse = await getUserPermissions(access_token);
    const userData = await getUserData(access_token);

    return {
      data: { ...response.data, ...userData.data },
      permissions: permissionsResponse.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({
      message: "Username or password is incorrect",
    });
  }
});

export const getUserProfile = createAsyncThunk(`auth/getUserProfile`, async (token, thunkAPI) => {
  try {
    const response = await getUserData(token);

    return {
      data: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({
      message: "Can't get user profile",
    });
  }
});
