import React, { forwardRef, useEffect, useRef } from "react";
import Quill from "quill";

const Editor = forwardRef(({ defaultValue, onTextChange }, ref) => {
  const containerRef = useRef(null);
  const quillRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const editorContainer = document.createElement("div");
    container.appendChild(editorContainer);

    const quill = new Quill(editorContainer, { theme: "snow" });
    quillRef.current = quill;

    if (defaultValue) {
      quill.clipboard.dangerouslyPasteHTML(0, defaultValue);
    }

    quill.on("text-change", () => {
      onTextChange(quill.root.innerHTML);
    });

    if (ref) ref.current = quill;

    return () => {
      container.innerHTML = "";
    };
  }, []);

  return <div ref={containerRef}></div>;
});

Editor.displayName = "Editor";

export default Editor;
