import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

import blankImagePath from "../../assets/images/blank-image-icon.png";
import ImageLoader from "../../components/FileLoader/ImageLoader";
import { resetStaffState } from "../../store/reducers/staffSlice";
import { getStaffList } from "../../store/actions/staff";
import StaffEditForm from "../../components/staff/StaffEditForm";
import { useAccess } from "../../hooks/useAccess";
import { controlledPages, permissions } from "../../config/accessControlConfig";

export default function AddStaffPage() {
  const dispatch = useDispatch();
  const staffData = useSelector(s => s.staff.list);
  const { hasPermission } = useAccess();

  const isCanEdit = hasPermission(controlledPages.staffProfile, permissions.edit);
  const isCanDelete = hasPermission(controlledPages.staffProfile, permissions.delete);
  const isCanEditUsername = hasPermission(controlledPages.staffProfile, permissions.editUsername);

  useEffect(() => {
    dispatch(resetStaffState());
    dispatch(getStaffList({ isWithQueries: false }));
  }, [dispatch]);

  return (
    <>
      <div className="container-fluid">
        <div className="layout-specing">
          <div className="d-md-flex justify-content-between">
            <h5 className="mb-0">Add New Staff</h5>

            <nav aria-label="breadcrumb" className="d-inline-block mt-4 mt-sm-0">
              <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">AOSmiles</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/staff">Staff</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add Staff
                </li>
              </ul>
            </nav>
          </div>

          <div className="row">
            <div className="col-lg-8 mt-4">
              <div className="card border-0 p-4 rounded shadow">
                <StaffEditForm
                  buttonText="Add Doctor"
                  isCanEdit={isCanEdit}
                  isCanDelete={isCanDelete}
                  isCanEditUsername={isCanEditUsername}
                />
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className="card rounded border-0 shadow">
                <div className="p-4 border-bottom">
                  <h5 className="mb-0">Staff List</h5>
                </div>
                <SimpleBar style={{ height: "660px" }}>
                  <ul className="list-unstyled mb-0 p-4 pt-0">
                    {staffData.slice(0, 6).map(item => {
                      return (
                        <li
                          className="d-md-flex align-items-center text-center text-md-start mt-4 ms-0"
                          key={item.id}
                        >
                          <div className="position-relative">
                            <ImageLoader
                              src={item?.photo_details?.safe_url || blankImagePath}
                              className="avatar avatar-medium rounded-md shadow"
                              alt=""
                            />
                          </div>

                          <div className="ms-md-3 mt-4 mt-sm-0">
                            <Link to={`/staff/${item.id}`} className="text-dark h6">
                              {`${item.first_name} ${item.last_name}`}
                            </Link>
                          </div>
                        </li>
                      );
                    })}

                    <li className="mt-4">
                      <Link to="/staff" className="btn btn-primary">
                        All Staff
                      </Link>
                    </li>
                  </ul>
                </SimpleBar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
