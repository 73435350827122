import { createSlice } from "@reduxjs/toolkit";

import { getFaqListThunk, getTipsListThunk } from "../actions/content";

const initialState = {
  faqsList: [],
  tipsList: [],
};

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    resetState: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getFaqListThunk.fulfilled, (state, action) => {
        state.faqsList = action.payload.data;
      })
      .addCase(getTipsListThunk.fulfilled, (state, action) => {
        state.tipsList = action.payload.data;
      });
  },
});

export const { resetState } = contentSlice.actions;

export default contentSlice.reducer;
