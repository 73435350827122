import React from "react";
import MedicalCheckoutServiceItemForm from "./MedicalCheckoutServiceItemForm";
import { ListGroup } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import PopoutSelect from "../Select/PopoutSelect";
import classNames from "classnames";

const MedicalCheckoutServicesList = ({ control, name, services }) => {
  const {
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  const checkoutServices = useWatch({ control, name });

  const handleDeleteService = deleteIndex => {
    const currentCheckoutList = checkoutServices.filter(
      (fieldItem, index) => index !== deleteIndex,
    );
    setValue(name, currentCheckoutList);
  };

  const handleAddService = ({ service, serviceData }) => {
    setValue(
      name,
      [
        ...checkoutServices,
        {
          service: service,
          price: serviceData.price,
          duration_minutes: serviceData.duration_minutes,
          comment: "",
          id: "",
          checkout: "",
        },
      ],
      {
        shouldDirty: true,
      },
    );
    trigger(name);
  };

  return (
    <div className="d-flex flex-column gap-4">
      <ListGroup className="gap-3">
        {checkoutServices?.map((item, index) => (
          <MedicalCheckoutServiceItemForm
            key={item?.id || index}
            services={services}
            order={index + 1}
            name={`${name}.${index}`}
            control={control}
            handleDeleteService={handleDeleteService}
          />
        ))}
      </ListGroup>
      {errors?.[name] && (
        <div className="invalid-feedback d-block">{errors?.[name]?.root?.message}</div>
      )}
      <PopoutSelect
        name={name}
        placeholder="Select services"
        selectTitle="Add checkout service"
        control={control}
        optionsData={services}
        className={classNames("form-control", "form-control_transparent-border", "p-0")}
        isShowOptionsResult={false}
        isShowChevron={false}
        openVariantColor="success"
        closeVariantColor="outline-success"
        buttonProps={{
          className: "btn-sm d-flex gap-2 align-items-center",
        }}
        customOnChange={handleAddService}
      />
    </div>
  );
};

export default MedicalCheckoutServicesList;
