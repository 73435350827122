import Offcanvas from "react-bootstrap/Offcanvas";
import { isEqual } from "lodash";
import React, { useEffect, useMemo } from "react";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import { checkIfRequired } from "../../utils/requiredField";
import { addTreatmentStage, updateTreatmentStage } from "../../store/actions/treatments";
import { getServices } from "../../store/actions/services";
import PopoutSelect from "../Select/PopoutSelect";
import { getPatientTreatmentPlansList } from "../../store/actions/patient";
import { submitEventForm } from "../../store/actions/calendar";
import moment from "moment";

const TreatmentStageForm = ({ isShowEditForm, handleClose, treatmentPlan, patient }) => {
  const treatmentStageItem = useSelector(s => s.treatments.treatmentStages.treatmentStage);
  const appointmentList = useSelector(s => s.patients.appointments);
  const services = useSelector(s => s.services.list);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string()
      .nullable()
      .transform((value, originalValue) => (originalValue === "" ? null : value)),
    services: Yup.array()
      .min(1, "At least one service must be selected")
      .required("At least one service must be selected"),
    is_completed: Yup.boolean(),
  });

  const defaultValues = {
    name: "",
    description: "",
    services: [],
    is_completed: false,
  };

  useEffect(() => {
    if (isShowEditForm) {
      dispatch(getServices());
    }
  }, [dispatch, isShowEditForm]);

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues,
  };
  const methods = useForm(formOptions);
  const { handleSubmit, formState, watch, reset, control, register } = methods;
  const { errors, isSubmitting } = formState;

  const isCompleted = watch("is_completed");

  useEffect(() => {
    if (treatmentStageItem?.id) {
      reset({
        name: treatmentStageItem.name || "",
        description: treatmentStageItem.description || "",
        services:
          treatmentStageItem?.services.map(item => {
            return { service: item };
          }) || [],
        is_completed: treatmentStageItem.is_completed || false,
      });
    }
  }, [treatmentStageItem, reset]);

  const onSubmit = async formValues => {
    if (treatmentStageItem?.id) {
      const services = formValues.services.map(item => item.service);
      const currentAppointment = appointmentList?.results?.find(
        appointment => +appointment.treatment_stage === treatmentStageItem.id,
      );
      await dispatch(
        updateTreatmentStage({
          treatmentStageData: {
            ...treatmentStageItem,
            ...formValues,
            services,
          },
        }),
      );

      if (!isEqual(treatmentStageItem.services, services) && currentAppointment) {
        const eventData = { ...currentAppointment, entry_services: formValues.services };
        dispatch(
          submitEventForm({
            id: currentAppointment.id,
            eventData: {
              ...eventData,
              start: moment(eventData.start).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
              end: moment(eventData.end).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
              file_ids: eventData.files.map(file => file.id),
            },
          }),
        );
      }
    } else
      await dispatch(
        addTreatmentStage({
          ...formValues,
          plan: treatmentPlan.id,
          stage_number: treatmentPlan.stages_details.length + 1,
          services: formValues.services.map(item => item.service),
        }),
      );

    await dispatch(getPatientTreatmentPlansList(patient.id));
    handleClose();
    reset(defaultValues);
  };

  const handleFormClose = () => {
    if (formState.isDirty) {
      dispatch(
        showSubmitConfirmation({
          message: "Are you sure you want to exit? All unsaved data will be lost.",
          onConfirm: () => {
            handleClose();
            reset(defaultValues);
          },
        }),
      );
    }

    if (!formState.isDirty) {
      handleClose();
      reset(defaultValues);
    }
  };

  const memoizedInitialValue = useMemo(() => {
    return treatmentStageItem?.services?.map(item => ({ service: item })) || [];
  }, [treatmentStageItem?.services]);

  return (
    <div>
      <Offcanvas
        show={isShowEditForm}
        onHide={() => {
          handleFormClose();
        }}
        backdrop={true}
        keyboard={false}
        placement="end"
        className="offcanvasEventEditForm"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>{`${treatmentStageItem?.id ? "Edit" : "Create"} Treatment Stage`}</Offcanvas.Title>
          <div className="d-flex gap-1">
            <Button variant="secondary" onClick={handleFormClose}>
              Close
            </Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FormProvider {...methods}>
            <form className="login-form mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">{`Name${checkIfRequired("name", validationSchema)}`}</label>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.name,
                      })}
                      placeholder="Treatment Stage Name"
                      {...register("name")}
                    />
                    <div className="invalid-feedback">{errors.name?.message}</div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">{`Description${checkIfRequired("description", validationSchema)}`}</label>
                    <input
                      name="description"
                      id="description"
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.description,
                      })}
                      placeholder="Treatment Stage Description"
                      {...register("description")}
                    />
                    <div className="invalid-feedback">{errors.description?.message}</div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">{`Services${checkIfRequired("services", validationSchema)}`}</label>
                    <PopoutSelect
                      name="services"
                      placeholder="Select services"
                      control={control}
                      optionsData={services}
                      className={classNames(
                        "form-control",
                        "form-control_transparent-border",
                        "p-0",
                        {
                          "is-invalid": errors.services,
                        },
                      )}
                      {...(treatmentStageItem?.id && {
                        initialValue: memoizedInitialValue,
                      })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-check">
                    <div className="px-3">
                      <input
                        className={classNames("form-check-input", {
                          "is-invalid": errors.is_completed,
                        })}
                        type="checkbox"
                        checked={isCompleted}
                        id="is_completed"
                        {...register("is_completed")}
                      />
                      <label className="form-check-label" htmlFor="is_completed">
                        {`Is Completed?`}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mb-0">
                  <div className="d-grid">
                    <button disabled={isSubmitting} className="btn btn-primary">
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default TreatmentStageForm;
