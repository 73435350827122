import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { FaHeart } from "react-icons/fa";

// Styles
import styles from "./BlinkingHeart.module.scss";

const BlinkingHeart = ({ chatId }) => {
  const unreadLikesChatIds = useSelector(state => state.chat.unreadLikesChatIds);
  const isUnreadLike = useMemo(
    () => unreadLikesChatIds.includes(chatId),
    [chatId, unreadLikesChatIds],
  );

  if (!isUnreadLike) return null;

  return (
    <div className={styles.blinkingHeart}>
      <FaHeart color="red" size={16} />
    </div>
  );
};

export default BlinkingHeart;
