import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import query from "querystringify";
import Pagination from "../../components/Pagination/Pagination";
import { history } from "../../utils/history";
import { resetStaffState } from "../../store/reducers/staffSlice";
import StaffItem from "../../components/staff/StaffItem";
import { getStaffList } from "../../store/actions/staff";
import { useAccess } from "../../hooks/useAccess";
import { controlledPages, permissions } from "../../config/accessControlConfig";

export default function StaffPage() {
  const dispatch = useDispatch();
  const staffData = useSelector(s => s.staff.list);
  const queries = useMemo(() => query.parse(history.location.search), [history.location.search]);
  const { hasPermission } = useAccess();
  const isCanAddStaff = hasPermission(controlledPages.addStaff, permissions.add);

  useEffect(() => {
    dispatch(resetStaffState());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getStaffList({ queryObj: queries }));
  }, [dispatch, queries]);

  return (
    <>
      <div className="container-fluid">
        <div className="layout-specing">
          <div className="row">
            <div className="col-xl-9 col-md-6">
              <h5 className="mb-0">Staff</h5>

              <nav aria-label="breadcrumb" className="d-inline-block mt-2">
                <ul className="breadcrumb breadcrumb-muted bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/">AOSmiles</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Staff
                  </li>
                </ul>
              </nav>
            </div>
            {isCanAddStaff && (
              <div className="col-xl-3 col-md-6 mt-4 mt-md-0 text-md-end">
                <Link to="/add-staff" className="btn btn-primary">
                  Add New Staff
                </Link>
              </div>
            )}
          </div>

          <div className="row row-cols-md-2 row-cols-lg-5">
            {staffData.map(item => {
              return <StaffItem item={item} key={item.id} />;
            })}
          </div>
          <Pagination paginatedPageState={"doctors"} />
        </div>
      </div>
    </>
  );
}
