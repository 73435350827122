import React, { useState } from "react";
import Sidebar from "./sidebar";
import TopHeader from "./topHeader";
import Footer from "./footer";

export default function Wrapper({ children, isWithFooter = true }) {
  let [toggle, setToggle] = useState(false);

  return (
    <div className={`${toggle ? "" : "toggled"} page-wrapper doctris-theme`}>
      <Sidebar manuClass="sidebar-wrapper" />

      <main className="page-content bg-light">
        <TopHeader toggle={toggle} setToggle={setToggle} />

        {children}
        {isWithFooter && <Footer />}
      </main>
    </div>
  );
}
