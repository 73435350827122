import { adminClient } from "../utils/axios";
import query from "querystringify";
import { getIfExist } from "../utils/getIfExist";

export const getChatsList = queryObj => {
  return adminClient.get(`admin/chats/${query.stringify(getIfExist(queryObj), true)}`);
};

export const getChat = chatId => {
  return adminClient.get(`admin/chats/${chatId}/`);
};

export const getChatPatientWithDoctor = queryObj => {
  return adminClient.get(`admin/chats/get/${query.stringify(getIfExist(queryObj), true)}`);
};

export const getMessagesListFrom = queryObj => {
  return adminClient.get(`admin/chat-messages/${query.stringify(getIfExist(queryObj), true)}`);
};

export const getUnreadMessagesCount = () => {
  return adminClient.get(`/admin/chats/unread-messages-count/`);
};

export const getActiveChatDetail = activeChatId => {
  return adminClient.get(`/admin/chats/${activeChatId}/`);
};
