import React from "react";
import { Link } from "react-router-dom";
import { LuClock } from "react-icons/lu";
import { MdOutlinePowerSettingsNew } from "react-icons/md";
import { BsPencil } from "react-icons/bs";
import { FiTrash } from "react-icons/fi";

export default function ServicesListItem({
  service,
  setIsActiveService,
  updateService,
  deleteService,
}) {
  return (
    <tr>
      <th className="p-3">{service?.code}</th>
      <td className="py-3">
        <div className="d-flex align-items-center">
          <span className="ms-2">{service.name}</span>
        </div>
      </td>
      <td className="p-3">{service.description}</td>
      <td className="p-3">$ {parseInt(service.price)}</td>
      <td className="p-3">
        <LuClock className="me-2 d-inline-block mb-1 icon" />
        {service.duration_minutes}
      </td>
      <td className="text-end p-3">
        <div className="d-flex patientsActions">
          <Link
            to="#"
            className={`btn btn-icon btn-pills ${service.is_active ? "btn-soft-success" : "btn-soft-danger"}`}
            onClick={() => setIsActiveService(service)}
          >
            <MdOutlinePowerSettingsNew />
          </Link>
          <Link
            className="btn btn-icon btn-pills btn-soft-success mx-1"
            to={`#`}
            onClick={() => updateService(service)}
          >
            <BsPencil />
          </Link>
          <Link
            to="#"
            className="btn btn-icon btn-pills btn-soft-danger"
            onClick={() => deleteService(service)}
          >
            <FiTrash />
          </Link>
        </div>
      </td>
    </tr>
  );
}
