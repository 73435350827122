import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import { updatePatientFamilyAccessItem } from "../../store/actions/patient";

const PermissionsForm = ({ isShowEditForm, handleClose }) => {
  const familyAccessItem = useSelector(s => s.patients.patientFamilyAccess.familyAccessItem);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    can_access: Yup.boolean(),
    can_access_files: Yup.boolean(),
    is_full_managed: Yup.boolean(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const methods = useForm(formOptions);
  const { handleSubmit, formState, setValue, reset, control, register } = methods;
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (familyAccessItem?.id) {
      reset({
        can_access: familyAccessItem.can_access || false,
        can_access_files: familyAccessItem.can_access_files || false,
        is_full_managed: familyAccessItem.is_full_managed || false,
      });
    }
  }, [familyAccessItem, reset]);

  const onSubmit = async formValues => {
    await dispatch(
      updatePatientFamilyAccessItem({
        accessId: familyAccessItem.id,
        data: {
          ...familyAccessItem,
          ...formValues,
        },
      }),
    );
    handleClose();
  };

  const handleFormClose = () => {
    if (formState.isDirty) {
      dispatch(
        showSubmitConfirmation({
          message: "Are you sure you want to exit? All unsaved data will be lost.",
          onConfirm: () => {
            handleClose();
          },
        }),
      );
    }

    if (!formState.isDirty) {
      handleClose();
    }
  };

  const checkIfRequired = fieldName => {
    const fieldSchema = validationSchema.describe().fields[fieldName];

    return fieldSchema.tests.some(test => test.name === "required" || test.name === "min");
  };

  return (
    <div>
      <Offcanvas
        show={isShowEditForm}
        onHide={() => {
          handleFormClose();
        }}
        backdrop={true}
        keyboard={false}
        placement="end"
        className="offcanvasEventEditForm"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>Edit Permissions</Offcanvas.Title>
          <div className="d-flex gap-1">
            <Button variant="secondary" onClick={handleFormClose}>
              Close
            </Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FormProvider {...methods}>
            <form className="login-form mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <input
                      className={classNames("form-check-input", {
                        "is-invalid": errors.can_access,
                      })}
                      type="checkbox"
                      value=""
                      id="can_access"
                      {...register("can_access")}
                    />
                    <label className="form-check-label ps-2" htmlFor="can_access">
                      {`Is can access${checkIfRequired("can_access") ? " *" : ""}`}
                    </label>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <input
                      className={classNames("form-check-input", {
                        "is-invalid": errors.can_access_files,
                      })}
                      type="checkbox"
                      value=""
                      id="can_access_files"
                      {...register("can_access_files")}
                    />
                    <label className="form-check-label ps-2" htmlFor="can_access_files">
                      {`Is can access files${checkIfRequired("can_access_files") ? " *" : ""}`}
                    </label>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <input
                      className={classNames("form-check-input", {
                        "is-invalid": errors.is_full_managed,
                      })}
                      type="checkbox"
                      value=""
                      id="is_full_managed"
                      {...register("is_full_managed")}
                    />
                    <label className="form-check-label ps-2" htmlFor="is_full_managed">
                      {`Is full managed${checkIfRequired("is_full_managed") ? " *" : ""}`}
                    </label>
                  </div>
                </div>
                <div className="col-lg-12 mb-0">
                  <div className="d-grid">
                    <button
                      disabled={isSubmitting || !formState.isDirty}
                      className="btn btn-primary"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default PermissionsForm;
