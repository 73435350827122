import React from "react";
import { Button, Collapse } from "react-bootstrap";
import { BiChevronDown } from "react-icons/bi";
import { useFormContext, useWatch } from "react-hook-form";
import TreatmentStageField from "./TreatmentStageField";
import TreatmentPlanField from "./TreatmentPlanField";

const TreatmentStageFieldBlock = ({
  control,
  className,
  onClickTreatmentButtonHandler,
  initialStageOption,
  initialTreatmentPlanOption,
  setInitialStageOption,
  setInitialDoctorOption,
}) => {
  const { watch } = useFormContext();
  const isOpenField = "isTreatmentStage";
  const isOpenTreatmentFields = watch(isOpenField);
  const patientId = useWatch({ control, name: "patient_id" });
  const treatmentPlan = useWatch({ control, name: "treatment_plan" });

  return (
    <>
      <div className={className}>
        <Button
          variant={`${isOpenTreatmentFields ? "success" : "light"}`}
          onClick={onClickTreatmentButtonHandler}
          aria-controls="collapse-area"
          size="sm"
          aria-expanded={isOpenTreatmentFields}
        >
          <span className="fs-6">
            Select Treatment Stage <BiChevronDown />
          </span>
        </Button>
      </div>
      <Collapse in={isOpenTreatmentFields}>
        <div id="collapse-area">
          <div className="row p-2">
            {patientId ? (
              <div>
                <TreatmentPlanField
                  name="treatment_plan"
                  control={control}
                  patientId={patientId}
                  initialPlanOption={initialTreatmentPlanOption}
                  setInitialStageOption={setInitialStageOption}
                  setInitialDoctorOption={setInitialDoctorOption}
                />
                {treatmentPlan && (
                  <TreatmentStageField
                    name="treatment_stage"
                    control={control}
                    initialStageOption={initialStageOption}
                  />
                )}
              </div>
            ) : (
              <div>To choose treatment stage, You need to select patient firstly</div>
            )}
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default TreatmentStageFieldBlock;
