import { adminClient } from "../utils/axios";
import query from "querystringify";
import { getIfExist } from "../utils/getIfExist";

export const getTreatmentPlansListApi = queryObj => {
  return adminClient.get(`admin/treatment-plans/${query.stringify(getIfExist(queryObj), true)}`);
};

export const addPatientTreatmentPlanApi = treatmentPlanData => {
  return adminClient.post("admin/treatment-plans/", getIfExist(treatmentPlanData));
};

export const getTreatmentPlanDetailsApi = id => {
  return adminClient.get(`admin/treatment-plans/${id}/`);
};

export const updateTreatmentPlanApi = treatmentPlanData => {
  return adminClient.put(`admin/treatment-plans/${treatmentPlanData.id}/`, treatmentPlanData);
};

export const removeTreatmentPlanApi = id => {
  return adminClient.delete(`admin/treatment-plans/${id}/`);
};

export const getTreatmentStagesListApi = queryObj => {
  return adminClient.get(`admin/treatment-stages/${query.stringify(getIfExist(queryObj), true)}`);
};

export const addTreatmentStageToPlanApi = treatmentStageData => {
  return adminClient.post("admin/treatment-stages/", getIfExist(treatmentStageData));
};

export const getTreatmentStageDetailsApi = id => {
  return adminClient.get(`admin/treatment-stages/${id}/`);
};

export const updateTreatmentStageApi = treatmentStageData => {
  return adminClient.put(`admin/treatment-stages/${treatmentStageData.id}/`, treatmentStageData);
};

export const removeTreatmentStageApi = id => {
  return adminClient.delete(`admin/treatment-stages/${id}/`);
};

export const sendTreatmentPlanForApproveApi = id => {
  return adminClient.post(`admin/treatment-plans/${id}/send_for_approval/`);
};
