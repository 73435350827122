import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import query from "querystringify";

import { getPatientsList, removePatient } from "../../store/actions/patient";
import PatientListItem from "../../components/partients/PatientListItem";
import ShortInfoProfileModal from "../../components/partients/ShortInfoProfileModal";
import { resetPatientsState } from "../../store/reducers/patientSlice";
import { history } from "../../utils/history";
import Pagination from "../../components/Pagination/Pagination";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import TableHeaderItem from "../../components/table/TableHeaderItem";

const headerItems = [
  {
    id: crypto.randomUUID(),
    title: "Id",
    styles: { minWidth: "50px" },
    isFilterEnable: true,
    filterField: "id",
  },

  {
    id: crypto.randomUUID(),
    title: "Last Name",
    styles: { minWidth: "90px" },
    isFilterEnable: true,
    filterField: "last_name",
  },
  {
    id: crypto.randomUUID(),
    title: "First Name",
    styles: { minWidth: "90px" },
    isFilterEnable: true,
    filterField: "first_name",
  },
  { id: crypto.randomUUID(), title: "Age", isFilterEnable: false },
  {
    id: crypto.randomUUID(),
    title: "Birthday",
    styles: { minWidth: "135px" },
    isFilterEnable: true,
    filterField: "birth_date",
  },
  { id: crypto.randomUUID(), title: "Gender", isFilterEnable: true, filterField: "gender" },
  {
    id: crypto.randomUUID(),
    title: "Address",
    isFilterEnable: true,
    filterField: "address",
  },
  {
    id: crypto.randomUUID(),
    title: "Contact No.",
    isFilterEnable: true,
    filterField: "phone",
  },
  {
    id: crypto.randomUUID(),
    title: "Email",
    isFilterEnable: true,
    filterField: "email",
  },
  {
    id: crypto.randomUUID(),
    title: "Medicare No.",
    isFilterEnable: true,
    filterField: "medicare_number",
  },
  {
    id: crypto.randomUUID(),
    title: "Private Insurance No.",
    isFilterEnable: true,
    filterField: "private_insurance_number",
  },
  {
    id: crypto.randomUUID(),
    title: "",
    isFilterEnable: false,
    styles: { minWidth: "100px" },
  },
];

export default function Patients() {
  const dispatch = useDispatch();
  const [isViewProfile, setIsViewProfile] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [shortInfoPatientData, setShortInfoPatientData] = useState(null);
  const patientsData = useSelector(s => s.patients.list);

  const queries = useMemo(() => query.parse(history.location.search), [history.location.search]);

  useEffect(() => {
    dispatch(resetPatientsState());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPatientsList({ queryObj: queries }));
  }, [dispatch, queries]);

  const handleSetShortInfoId = id => {
    const currentPatient = patientsData.find(patient => patient.id === id);
    setShortInfoPatientData(currentPatient);
    setIsViewProfile(true);
  };

  const handleCloseShortProfile = () => {
    setShortInfoPatientData(null);
    setIsViewProfile(false);
  };

  const handleDeletePatientProfile = async patientProfile => {
    dispatch(
      showSubmitConfirmation({
        message: `Are you sure want to delete patient: ${patientProfile?.first_name} ${patientProfile?.last_name}?`,
        onConfirm: async () => {
          await dispatch(removePatient(patientProfile.id));
          dispatch(getPatientsList({ queryObj: queries }));
        },
      }),
    );
  };

  return (
    <>
      <div className="container-fluid">
        <div className="layout-specing">
          <div className="d-md-flex justify-content-between">
            <h5 className="mb-0">Patients List</h5>

            <nav aria-label="breadcrumb" className="d-inline-block mt-4 mt-sm-0">
              <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">AOSmiles</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Patients
                </li>
              </ul>
            </nav>
          </div>

          <div className="row">
            <div className="col-12 mt-4">
              <div className="table-responsive shadow rounded">
                <table className="table table-center bg-white mb-0">
                  <thead>
                    <tr>
                      {headerItems.map(headerItem => (
                        <TableHeaderItem key={headerItem.id} {...headerItem} />
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {patientsData?.map(item => {
                      return (
                        <PatientListItem
                          key={item.id}
                          patient={item}
                          showShortInfoId={handleSetShortInfoId}
                          deletePatientProfile={handleDeletePatientProfile}
                        />
                        // <tr key={index}>
                        //   <th className="p-3">{item.id}</th>
                        //   <td className="py-3">
                        //     <Link to="#" className="text-dark">
                        //       <div className="d-flex align-items-center">
                        //         <img
                        //           src={item.image}
                        //           className="avatar avatar-md-sm rounded-circle shadow"
                        //           alt=""
                        //         />
                        //         <span className="ms-2">{item.name}</span>
                        //       </div>
                        //     </Link>
                        //   </td>
                        //   <td className="p-3">{item.age}</td>
                        //   <td className="p-3">{item.gender}</td>
                        //   <td className="p-3">{item.address}</td>
                        //   <td className="p-3">{item.mobileNo}</td>
                        //   <td className="p-3">{item.Department}</td>
                        //   <td className="p-3">{item.date}</td>
                        //   <td className="p-3">{item.time}</td>
                        //   <td className="p-3">
                        //     {item.status === "Approved" ? (
                        //       <span className="badge bg-soft-success">Approved</span>
                        //     ) : (
                        //       <span className="badge bg-soft-warning">Pending</span>
                        //     )}
                        //   </td>
                        //   <td className="text-end p-3">
                        //     <Link
                        //       to="#"
                        //       className="btn btn-icon btn-pills btn-soft-primary"
                        //       onClick={() => setViewProfile(true)}
                        //     >
                        //       <FiEye />
                        //     </Link>
                        //     <Link
                        //       to="#"
                        //       className="btn btn-icon btn-pills btn-soft-success mx-1"
                        //       onClick={() => setEditProfile(true)}
                        //     >
                        //       <BsPencil />
                        //     </Link>
                        //     <Link to="#" className="btn btn-icon btn-pills btn-soft-danger">
                        //       <FiTrash />
                        //     </Link>
                        //   </td>
                        // </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <ShortInfoProfileModal
            isShowShortProfileInfo={isViewProfile}
            handleCloseShortProfile={handleCloseShortProfile}
            shortInfoPatientData={shortInfoPatientData}
          />
          {/*<Modal show={viewProfile} onHide={() => setViewProfile(false)} centered>*/}
          {/*  <Modal.Header closeButton>*/}
          {/*    <h5 className="modal-title" id="exampleModalLabel1">*/}
          {/*      Profile*/}
          {/*    </h5>*/}
          {/*  </Modal.Header>*/}
          {/*  <Modal.Body>*/}
          {/*    <div className="d-flex align-items-center">*/}
          {/*      <img src={client} className="avatar avatar-small rounded-pill" alt="" />*/}
          {/*      <h5 className="mb-0 ms-3">Howard Tanner</h5>*/}
          {/*    </div>*/}
          {/*    <ul className="list-unstyled mb-0 d-md-flex justify-content-between mt-4">*/}
          {/*      <li>*/}
          {/*        <ul className="list-unstyled mb-0">*/}
          {/*          <li className="d-flex ms-0">*/}
          {/*            <h6>Age:</h6>*/}
          {/*            <p className="text-muted ms-2">25 year old</p>*/}
          {/*          </li>*/}

          {/*          <li className="d-flex ms-0">*/}
          {/*            <h6>Gender:</h6>*/}
          {/*            <p className="text-muted ms-2">Male</p>*/}
          {/*          </li>*/}

          {/*          <li className="d-flex ms-0">*/}
          {/*            <h6 className="mb-0">Department:</h6>*/}
          {/*            <p className="text-muted ms-2 mb-0">Cardiology</p>*/}
          {/*          </li>*/}
          {/*        </ul>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <ul className="list-unstyled mb-0">*/}
          {/*          <li className="d-flex ms-0">*/}
          {/*            <h6>Date:</h6>*/}
          {/*            <p className="text-muted ms-2">20th Dec 2020</p>*/}
          {/*          </li>*/}

          {/*          <li className="d-flex ms-0">*/}
          {/*            <h6>Time:</h6>*/}
          {/*            <p className="text-muted ms-2">11:00 AM</p>*/}
          {/*          </li>*/}

          {/*          <li className="d-flex ms-0">*/}
          {/*            <h6 className="mb-0">Doctor:</h6>*/}
          {/*            <p className="text-muted ms-2 mb-0">Dr. Calvin Carlo</p>*/}
          {/*          </li>*/}
          {/*        </ul>*/}
          {/*      </li>*/}
          {/*    </ul>*/}
          {/*  </Modal.Body>*/}
          {/*</Modal>*/}
          {/*<Modal show={editProfile} onHide={() => setEditProfile(false)} centered size="lg">*/}
          {/*  <Modal.Header closeButton>*/}
          {/*    <h5 className="modal-title" id="exampleModalLabel1">*/}
          {/*      Profile Settings*/}
          {/*    </h5>*/}
          {/*  </Modal.Header>*/}
          {/*  <Modal.Body>*/}
          {/*    <div className="row align-items-center">*/}
          {/*      <div className="col-lg-2 col-md-4">*/}
          {/*        <img*/}
          {/*          src={doctor}*/}
          {/*          className="avatar avatar-md-md rounded-pill shadow mx-auto d-block"*/}
          {/*          alt=""*/}
          {/*        />*/}
          {/*      </div>*/}

          {/*      <div className="col-lg-5 col-md-8 text-center text-md-start mt-4 mt-sm-0">*/}
          {/*        <h6 className="">Upload your picture</h6>*/}
          {/*        <p className="text-muted mb-0">*/}
          {/*          For best results, use an image at least 256px by 256px in either .jpg or .png*/}
          {/*          format*/}
          {/*        </p>*/}
          {/*      </div>*/}

          {/*      <div className="col-lg-5 col-md-12 text-lg-end text-center mt-4 mt-lg-0">*/}
          {/*        <Link to="#" className="btn btn-primary">*/}
          {/*          Upload*/}
          {/*        </Link>*/}
          {/*        <Link to="#" className="btn btn-soft-primary ms-2">*/}
          {/*          Remove*/}
          {/*        </Link>*/}
          {/*      </div>*/}
          {/*    </div>*/}

          {/*    <form className="mt-4">*/}
          {/*      <div className="row">*/}
          {/*        <div className="col-md-6">*/}
          {/*          <div className="mb-3">*/}
          {/*            <label className="form-label">First Name</label>*/}
          {/*            <input*/}
          {/*              name="name"*/}
          {/*              id="name"*/}
          {/*              type="text"*/}
          {/*              className="form-control"*/}
          {/*              placeholder="First Name :"*/}
          {/*            />*/}
          {/*          </div>*/}
          {/*        </div>*/}

          {/*        <div className="col-md-6">*/}
          {/*          <div className="mb-3">*/}
          {/*            <label className="form-label">Last Name</label>*/}
          {/*            <input*/}
          {/*              name="name"*/}
          {/*              id="name2"*/}
          {/*              type="text"*/}
          {/*              className="form-control"*/}
          {/*              placeholder="Last Name :"*/}
          {/*            />*/}
          {/*          </div>*/}
          {/*        </div>*/}

          {/*        <div className="col-md-6">*/}
          {/*          <div className="mb-3">*/}
          {/*            <label className="form-label">Your Email</label>*/}
          {/*            <input*/}
          {/*              name="email"*/}
          {/*              id="email"*/}
          {/*              type="email"*/}
          {/*              className="form-control"*/}
          {/*              placeholder="Your email :"*/}
          {/*            />*/}
          {/*          </div>*/}
          {/*        </div>*/}

          {/*        <div className="col-md-6">*/}
          {/*          <div className="mb-3">*/}
          {/*            <label className="form-label">Phone no.</label>*/}
          {/*            <input*/}
          {/*              name="number"*/}
          {/*              id="number"*/}
          {/*              type="text"*/}
          {/*              className="form-control"*/}
          {/*              placeholder="Phone no. :"*/}
          {/*            />*/}
          {/*          </div>*/}
          {/*        </div>*/}

          {/*        <div className="col-md-12">*/}
          {/*          <div className="mb-3">*/}
          {/*            <label className="form-label">Your Bio Here</label>*/}
          {/*            <textarea*/}
          {/*              name="comments"*/}
          {/*              id="comments"*/}
          {/*              rows="4"*/}
          {/*              className="form-control"*/}
          {/*              placeholder="Bio :"*/}
          {/*            ></textarea>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}

          {/*      <div className="row">*/}
          {/*        <div className="col-sm-12">*/}
          {/*          <input*/}
          {/*            type="submit"*/}
          {/*            id="submit"*/}
          {/*            name="send"*/}
          {/*            className="btn btn-primary"*/}
          {/*            value="Save changes"*/}
          {/*          />*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </form>*/}
          {/*  </Modal.Body>*/}
          {/*</Modal>*/}
          <Pagination paginatedPageState={"patients"} />
          {/*<div className="row text-center">*/}
          {/*  <div className="col-12 mt-4">*/}
          {/*    <div className="d-md-flex align-items-center text-center justify-content-between">*/}
          {/*      <span className="text-muted me-3">Showing 1 - 10 out of 50</span>*/}
          {/*      <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">*/}
          {/*        <li className="page-item">*/}
          {/*          <Link className="page-link" to="#" aria-label="Previous">*/}
          {/*            Prev*/}
          {/*          </Link>*/}
          {/*        </li>*/}
          {/*        <li className="page-item active">*/}
          {/*          <Link className="page-link" to="#">*/}
          {/*            1*/}
          {/*          </Link>*/}
          {/*        </li>*/}
          {/*        <li className="page-item">*/}
          {/*          <Link className="page-link" to="#">*/}
          {/*            2*/}
          {/*          </Link>*/}
          {/*        </li>*/}
          {/*        <li className="page-item">*/}
          {/*          <Link className="page-link" to="#">*/}
          {/*            3*/}
          {/*          </Link>*/}
          {/*        </li>*/}
          {/*        <li className="page-item">*/}
          {/*          <Link className="page-link" to="#" aria-label="Next">*/}
          {/*            Next*/}
          {/*          </Link>*/}
          {/*        </li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
}
