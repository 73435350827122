import React, { useState, useCallback, useRef, useEffect } from "react";
import { useDrop, useDrag } from "react-dnd";
import { FiArrowRight, FiPlus, FiTrash } from "react-icons/fi";
import { CiCalendar } from "react-icons/ci";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BsPencil } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  removeTreatmentStage,
  sendTreatmentPlanForApprove,
  updatePositionsStagesAfterRemoveItem,
  updateTreatmentStages,
} from "../../store/actions/treatments";
import { LiaFileMedicalAltSolid } from "react-icons/lia";
import { getPatientTreatmentPlansList } from "../../store/actions/patient";
import moment from "moment";
import { FaUserDoctor } from "react-icons/fa6";
import { getDoctorProfileDetails } from "../../api/doctor";
import { getEventDetails } from "../../store/actions/calendar";
import { getServiceDetails } from "../../api/services";
import { status } from "../../config/settings";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ItemType = "STAGE";

// Draggable stage component
const DraggableStageItem = ({
  stage,
  index,
  moveStage,
  handleShowAppointmentForm,
  handleOpenTreatmentStageEditForm,
  handleDeleteTreatmentStage,
}) => {
  const [doctor, setDoctor] = useState({});
  const [service, setService] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDoctorData = async () => {
      if (!stage.appointment_details.length) return;

      try {
        const result = await getDoctorProfileDetails({ id: stage.appointment_details[0].doctor });
        setDoctor(result.data);
      } catch (error) {
        console.error("Error to load doctor data:", error);
      }
    };

    if (stage.appointment_details?.[0]) {
      fetchDoctorData();
    }
  }, [stage.appointment_details]);

  useEffect(() => {
    const fetchServiceData = async () => {
      if (!stage.appointment_details.length) return;

      try {
        const result = await getServiceDetails(
          stage.appointment_details[0].entry_services?.[0].service,
        );
        setService(result.data);
      } catch (error) {
        console.error("Error to load service data:", error);
      }
    };

    if (stage.appointment_details?.[0]) {
      fetchServiceData();
    }
  }, [stage.appointment_details]);

  const ref = useRef(null);

  const [{ isDragging }, dragRef] = useDrag({
    type: ItemType,
    item: { index, stage },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: ItemType,
    hover: (draggedItem, monitor) => {
      if (!ref.current) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (draggedItem.index < index && hoverClientY < hoverMiddleY) {
        return;
      }

      if (draggedItem.index > index && hoverClientY > hoverMiddleY) {
        return;
      }

      moveStage(draggedItem.index, index);
      draggedItem.index = index;
    },
  });

  const handleShowEditAppointmentForm = async () => {
    if (stage.appointment_details?.length) {
      await dispatch(getEventDetails({ id: stage.appointment_details?.[0].id }));
    }

    handleShowAppointmentForm(stage);
  };

  const handleDeleteStage = () => {
    dispatch(
      showSubmitConfirmation({
        message: `Are you sure you want to delete: Stage-${stage.stage_number} ${stage.name}?`,
        onConfirm: async () => {
          await handleDeleteTreatmentStage(stage.id);
        },
      }),
    );
  };

  dragRef(dropRef(ref));

  return (
    <li
      ref={ref}
      className="d-flex gap-2 ms-0"
      style={{ opacity: isDragging ? 0.5 : 1, cursor: "grab" }}
    >
      <div className="d-flex flex-column align-items-center gap-2">
        <div
          className={`btn btn-icon btn-pills ${stage.is_completed ? "btn-success" : "btn-outline-dark"} disabled`}
          style={{ width: "16px", height: "16px" }}
        />
        <div
          className="flex-grow-1"
          style={{ border: `1px solid ${stage.is_completed ? "#53c797" : "#111"}` }}
        />
      </div>
      <div className="d-flex shadow-sm flex-grow-1 p-2">
        <div className="flex-1 overflow-hidden ms-2">
          <h6 className="mb-0 d-flex gap-2">
            <span>{`Stage - ${stage.stage_number}:`}</span>
            <span className="text-decoration-underline">{stage.name}</span>
          </h6>
          <p className="text-muted mb-0 text-truncate small">{stage.description}</p>
          {stage.appointment_details?.length ? (
            <div className="mt-2 mb-2 d-flex gap-1 flex-column">
              <div className="d-flex gap-2 align-items-center">
                <span
                  className={`badge ${status[stage.appointment_details?.[0].status].badgeClassName}`}
                >
                  {status[stage.appointment_details?.[0].status].label}
                </span>
              </div>
              <div className="d-flex gap-2 align-items-center" style={{ fontSize: "14px" }}>
                <CiCalendar />
                <span>{moment(stage.appointment_details?.[0]?.start).format("HH:mm")}</span>-
                <span>{moment(stage.appointment_details?.[0]?.end).format("HH:mm")}</span>
                <span className="text-muted">
                  {moment(stage.appointment_details?.[0]?.start).format("D MMMM YYYY")}
                </span>
              </div>
              <div className="ms-0 d-flex align-items-center" style={{ fontSize: "14px" }}>
                {Object.entries(service).length ? (
                  <div className="col-11 d-flex align-items-center gap-1">
                    <FiArrowRight className="text-primary" />
                    <span>{service.name}</span>
                    <span className="text-muted">{`(${moment
                      .utc(
                        moment.duration(service.duration_minutes || 0, "minutes").asMilliseconds(),
                      )
                      .format("HH:mm")})`}</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex gap-2 align-items-center" style={{ fontSize: "14px" }}>
                <FaUserDoctor />
                <span>
                  {Object.entries(doctor).length ? `${doctor.first_name} ${doctor.last_name}` : ""}
                </span>
              </div>
            </div>
          ) : null}
          <div className="mt-1">
            <Button
              className="btn-sm"
              variant="outline-primary"
              onClick={handleShowEditAppointmentForm}
            >
              {stage.appointment_details?.length ? "Change an appointment" : "Make an appointment"}
            </Button>
          </div>
        </div>
        <div className="d-flex gap-1 align-self-center">
          <Button
            to="#"
            className="btn-icon btn-pills btn-soft-success mx-1"
            onClick={() => handleOpenTreatmentStageEditForm(stage.id)}
          >
            <BsPencil />
          </Button>
          <Button className="btn-icon btn-pills btn-soft-danger mx-1" onClick={handleDeleteStage}>
            <FiTrash />
          </Button>
        </div>
      </div>
    </li>
  );
};

// Droppable container component
const StagesList = ({
  stages,
  moveStage,
  handleShowAppointmentForm,
  handleOpenTreatmentStageEditForm,
  handleDrop,
  handleDeleteTreatmentStage,
  handleAddTreatmentStage,
}) => {
  const [, dropRef] = useDrop({
    accept: ItemType,
    hover: (draggedItem, monitor) => {
      if (!monitor.isOver({ shallow: true })) return;

      const { index: fromIndex } = draggedItem;
      const toIndex = monitor.getItem()?.index;

      if (fromIndex !== toIndex) moveStage(fromIndex, toIndex);
    },
    drop: droppableItem => {
      if (droppableItem.index + 1 !== droppableItem.stage.stage_number) {
        handleDrop(droppableItem, stages);
      }
    },
  });

  return (
    <div>
      <ul ref={dropRef} className="list-unstyled p-3 pb-0 d-flex flex-column gap-2">
        {stages.length ? (
          stages.map((stage, index) => {
            return (
              <DraggableStageItem
                key={stage.id}
                stage={stage}
                index={index}
                moveStage={moveStage}
                handleShowAppointmentForm={handleShowAppointmentForm}
                handleOpenTreatmentStageEditForm={handleOpenTreatmentStageEditForm}
                handleDeleteTreatmentStage={handleDeleteTreatmentStage}
              />
            );
          })
        ) : (
          <li className="text-muted">No any stages for this treatment plan</li>
        )}
      </ul>
      <div>
        <Button
          className="btn-sm d-flex gap-2 align-items-center"
          variant="outline-success"
          onClick={handleAddTreatmentStage}
        >
          <FiPlus />
          <span>Add Treatment Stage</span>
        </Button>
      </div>
    </div>
  );
};

// Main component with DndProvider
const TreatmentPlansItem = ({
  treatmentPlan,
  handleOpenTreatmentStageForm,
  handleShowAppointmentForm,
  handleOpenAddTreatmentPlanForm,
  handleDeleteTreatmentPlan,
}) => {
  const [isOpenItem, setIsOpenItem] = useState(false);
  const patient = useSelector(state => state.patients.patientProfile);
  const [stages, setStages] = useState(treatmentPlan.stages_details);
  const dispatch = useDispatch();

  useEffect(() => {
    setStages(treatmentPlan.stages_details);
  }, [treatmentPlan]);

  const moveStage = useCallback(
    (fromIndex, toIndex) => {
      const updatedStages = [...stages];
      const [movedItem] = updatedStages.splice(fromIndex, 1);
      updatedStages.splice(toIndex, 0, movedItem);

      setStages(updatedStages);
    },
    [stages],
  );

  const handleDrop = async (stage, updatedStages) => {
    await dispatch(updateTreatmentStages({ currenStage: stage, updatedStages }));
    dispatch(getPatientTreatmentPlansList(patient.id));
  };

  const handleOpenEditTreatmentStage = stageId => {
    handleOpenTreatmentStageForm(treatmentPlan, stageId);
  };

  const handleDeleteTreatmentStage = async stageId => {
    await dispatch(removeTreatmentStage(stageId));
    await dispatch(
      updatePositionsStagesAfterRemoveItem(stages.filter(stage => stage.id !== stageId)),
    );
    dispatch(getPatientTreatmentPlansList(patient.id));
  };

  const handleAddTreatmentStage = () => {
    handleOpenTreatmentStageForm(treatmentPlan);
  };

  const handleSendTreatmentPlanForApprove = async () => {
    await dispatch(sendTreatmentPlanForApprove(treatmentPlan.id));
    dispatch(getPatientTreatmentPlansList(patient.id));
  };

  return (
    <div className="rounded p-3 shadow mt-3">
      <div className="d-flex justify-content-between align-items-center">
        <div
          className="flex-1 d-flex align-items-center col-12 gap-2"
          style={{ cursor: "pointer" }}
          onClick={() => setIsOpenItem(!isOpenItem)}
        >
          {isOpenItem ? <IoIosArrowUp /> : <IoIosArrowDown />}
          <LiaFileMedicalAltSolid className="h3 fw-normal text-success mb-0" />
          <div className="flex-1 overflow-hidden ms-2 d-flex align-items-center gap-3">
            <div className="col-3">
              <h6 className="mb-0">{treatmentPlan.name}</h6>
              <p className="text-muted mb-0 text-truncate small">{treatmentPlan.description}</p>
            </div>
            {treatmentPlan?.main_doctor && (
              <div className="d-flex align-self-start flex-column col-2">
                <h6 className="mb-0">Attending Doctor</h6>
                <div className="d-flex gap-2 text-muted mb-0 text-truncate small">
                  <span>{treatmentPlan.main_doctor_details.first_name}</span>
                  <span>{treatmentPlan.main_doctor_details.last_name}</span>
                </div>
              </div>
            )}
            <div className={`badge ${status[treatmentPlan.status]?.badgeClassName}`}>
              {treatmentPlan.status}
            </div>
            {(treatmentPlan.is_accepted ||
              treatmentPlan.is_rejected ||
              treatmentPlan.is_pending_approval) && (
              <OverlayTrigger
                placement="bottom"
                container={document.body}
                overlay={
                  <Tooltip id={`tooltip-${treatmentPlan.id}`} className="custom-tooltip">
                    <div className="d-flex flex-column gap-2">
                      {treatmentPlan?.sent_for_approval_at && (
                        <div className="d-flex align-items-center gap-1">
                          <span className="fw-bold">Sent for approval at:</span>
                          <span>
                            {moment(treatmentPlan.sent_for_approval_at).format("D MMMM YYYY")}
                          </span>
                          <span>{`(${moment(treatmentPlan.sent_for_approval_at).format("HH:mm")})`}</span>
                        </div>
                      )}
                      {treatmentPlan?.approval_resolved_at ? (
                        <div className="d-flex align-items-center gap-1">
                          <span className="fw-bold">Received last resolve at:</span>
                          <span>
                            {moment(treatmentPlan.approval_resolved_at).format("D MMMM YYYY")}
                          </span>
                          <span>{`(${moment(treatmentPlan.approval_resolved_at).format("HH:mm")})`}</span>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center gap-1">
                          <span className="fw-bold">Received last resolve at:</span>
                          <span>Not received yet</span>
                        </div>
                      )}
                    </div>
                  </Tooltip>
                }
              >
                <div
                  className={classNames("badge", {
                    [status["IS_ACCEPTED"]?.badgeClassName]: treatmentPlan.is_accepted,
                    [status["IS_REJECTED"]?.badgeClassName]: treatmentPlan.is_rejected,
                    [status["IS_PENDING_APPROVAL"]?.badgeClassName]:
                      treatmentPlan.is_pending_approval,
                  })}
                >
                  {treatmentPlan.is_accepted && status["IS_ACCEPTED"]?.value}
                  {treatmentPlan.is_rejected && status["IS_REJECTED"]?.value}
                  {treatmentPlan.is_pending_approval && status["IS_PENDING_APPROVAL"]?.value}
                </div>
              </OverlayTrigger>
            )}
          </div>
        </div>
        <div className="d-flex gap-1">
          <Button
            to="#"
            className="btn-pills btn-soft-primary btn-sm mx-1"
            onClick={handleSendTreatmentPlanForApprove}
          >
            <span>Send for approval</span>
          </Button>
          <Button
            to="#"
            className="btn-pills btn-soft-success btn-sm mx-1"
            onClick={() => handleOpenAddTreatmentPlanForm(treatmentPlan.id)}
          >
            <span>Edit</span>
          </Button>
          <Button
            className="btn-pills btn-soft-danger btn-sm mx-1"
            onClick={() => handleDeleteTreatmentPlan(treatmentPlan)}
          >
            <span>Delete</span>
          </Button>
        </div>
      </div>
      {isOpenItem && (
        <StagesList
          stages={stages}
          moveStage={moveStage}
          handleShowAppointmentForm={handleShowAppointmentForm}
          handleDeleteTreatmentStage={handleDeleteTreatmentStage}
          handleOpenTreatmentStageEditForm={handleOpenEditTreatmentStage}
          handleDrop={handleDrop}
          handleAddTreatmentStage={handleAddTreatmentStage}
        />
      )}
    </div>
  );
};

export default TreatmentPlansItem;
