import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addScheduleEvent,
  getClinicWorkingHours,
  getScheduleEventDetails,
  getScheduleList,
  removeScheduleEvent,
  updateScheduleEvent,
} from "../../api/calendar";
import { getUnavailableDates } from "../../api/unavailableDates";
import { calendarSliceName } from "../reducers/calendarSlice";

export const getCalendarList = createAsyncThunk(
  `${calendarSliceName}/list`,
  async ({ queryObj, isWithQueries = false }, thunkAPI) => {
    try {
      const { period, doctor, page, page_size, ordering } = thunkAPI.getState().calendar.schedule;
      const query = {
        page,
        page_size,
        ordering,
        ...(queryObj && { ...queryObj }),
      };
      const response = await getScheduleList({
        start: period.start,
        end: period.end,
        doctor: doctor,
        ...(isWithQueries && { ...query }),
      });

      return {
        data: response.data,
        page: +query.page,
        ordering: query.ordering,
        start: query.start,
        end: query.end,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getUnavailableList = createAsyncThunk(
  `${calendarSliceName}/unavailableList`,
  async (_, thunkAPI) => {
    try {
      const { period, doctor } = thunkAPI.getState().calendar.schedule;
      const response = await getUnavailableDates({
        start: period.start,
        end: period.end,
        doctor,
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getEventDetails = createAsyncThunk(
  `${calendarSliceName}/itemDetails`,
  async ({ id }, thunkAPI) => {
    try {
      const response = await getScheduleEventDetails({ id });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateCalendarEvent = createAsyncThunk(
  `${calendarSliceName}/eventUpdate`,
  async ({ id, eventData }, thunkAPI) => {
    try {
      const event = thunkAPI.getState().calendar.schedule.list.find(item => item.id === +id);
      const { patient, ...rest } = event;
      const response = await updateScheduleEvent({
        id,
        eventData: {
          ...rest,
          ...eventData,
          patient_id: eventData?.patient_id || event.patient.id,
          file_ids: event.files.map(file => file.id),
        },
      });

      return {
        data: response.data,
        successMessage: `Appointment successfully updated`,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: `Failed to update appointment` });
    }
  },
);

export const submitEventForm = createAsyncThunk(
  `${calendarSliceName}/eventSubmitForm`,
  async ({ id, eventData }, thunkAPI) => {
    try {
      let response;

      if (id) {
        const event = thunkAPI.getState().calendar.schedule.list.find(item => item.id === +id);
        const { patient, ...rest } = event;
        response = await updateScheduleEvent({
          id,
          eventData: {
            ...rest,
            ...eventData,
            patient_id: eventData?.patient_id || event?.patient.id,
          },
        });
      } else {
        response = await addScheduleEvent({
          eventData,
        });
      }

      return {
        data: response.data,
        successMessage: `Appointment successfully ${id ? "updated" : "created"}`,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        errorMessage: `Failed to ${id ? "update" : "create"} appointment`,
      });
    }
  },
);

export const addEventSchedule = createAsyncThunk(
  `${calendarSliceName}/addEventSchedule`,
  async (eventData, thunkAPI) => {
    try {
      const response = await addScheduleEvent({ eventData });

      return {
        data: response.data,
        successMessage: `Unavailable time successfully add`,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to add unavailable time" });
    }
  },
);

export const updateEventSchedule = createAsyncThunk(
  `${calendarSliceName}/updateEventSchedule`,
  async ({ id, eventData }, thunkAPI) => {
    try {
      const response = await updateScheduleEvent({
        id,
        eventData,
      });

      return {
        data: response.data,
        successMessage: `Unavailable time successfully updated`,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update unavailable time" });
    }
  },
);

export const deleteCalendarEvent = createAsyncThunk(
  `${calendarSliceName}/eventDelete`,
  async ({ id }, thunkAPI) => {
    try {
      await removeScheduleEvent(id);

      return {
        data: id,
        successMessage: `Appointment successfully deleted`,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: `Failed to delete appointment` });
    }
  },
);

export const deleteEventSchedule = createAsyncThunk(
  `${calendarSliceName}/deleteEventSchedule`,
  async ({ id }, thunkAPI) => {
    try {
      await removeScheduleEvent(id);

      return {
        data: id,
        successMessage: `Unavailable time successfully deleted`,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: `Failed to delete unavailable time` });
    }
  },
);

export const getBusinessHours = createAsyncThunk(
  `${calendarSliceName}/getBusinessHours`,
  async (_, thunkAPI) => {
    try {
      const response = await getClinicWorkingHours();

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
