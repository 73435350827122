import { createSlice } from "@reduxjs/toolkit";
import {
  getFamilyAccessItemDetails,
  getPatientAppointments,
  getPatientFamilyAccessList,
  getPatientProfile,
  getPatientsList,
  getPatientTreatmentPlansList,
  updatePatient,
} from "../actions/patient";

const initialState = {
  count: 0,
  page: 1,
  page_size: 20,
  next: null,
  previous: null,
  list: [],
  patientProfile: {},
  appointments: null,
  patientFamilyAccess: {
    list: [],
    familyAccessItem: {},
  },
  treatmentsPlans: null,
};

export const patientsSliceName = "patients";

export const patientSlice = createSlice({
  name: patientsSliceName,
  initialState,
  reducers: {
    resetPatientsState: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPatientsList.fulfilled, (state, action) => {
        state.list = action.payload.data.results;
        state.count = action.payload.data.count;
        state.next = action.payload.data.next;
        state.previous = action.payload.data.previous;
        state.page = action.payload.page;
      })
      .addCase(getPatientProfile.fulfilled, (state, action) => {
        state.patientProfile = action.payload.data;
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        state.patientProfile = action.payload.data;
      })
      .addCase(getPatientAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload.data;
      })
      .addCase(getPatientTreatmentPlansList.fulfilled, (state, action) => {
        state.treatmentsPlans = action.payload.data;
      })
      .addCase(getPatientFamilyAccessList.fulfilled, (state, action) => {
        state.patientFamilyAccess.list = action.payload.data;
      })
      .addCase(getFamilyAccessItemDetails.fulfilled, (state, action) => {
        state.patientFamilyAccess.familyAccessItem = action.payload.data;
      });
  },
});

export const { resetPatientsState } = patientSlice.actions;

export default patientSlice.reducer;
