import { adminClient } from "../utils/axios";

export const uploadPatientFiles = async (files, patientId) => {
  const uploadedFiles = [];

  for (const file of files) {
    try {
      const formData = new FormData();
      formData.append("file", file);

      if (patientId) {
        formData.append("patient", patientId);
      }

      const serverResponse = await adminClient.post(`admin/patient-files/`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const filename = serverResponse.data.filename;
      const mime_type = serverResponse.data.mime_type;
      uploadedFiles.push({
        ...serverResponse.data,
        ...(!filename && { filename: file.name }),
        ...(!mime_type && { mime_type: file.type }),
      });
    } catch (error) {
      console.error("File upload error:", error);
    }
  }

  return uploadedFiles;
};

export const uploadPublicFiles = async files => {
  const uploadedFiles = [];

  for (const file of files) {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const serverResponse = await adminClient.post(`admin/public-files/`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const filename = serverResponse.data.filename;
      const mime_type = serverResponse.data.mime_type;

      uploadedFiles.push({
        ...serverResponse.data,
        ...(!filename && { filename: file.name }),
        ...(!mime_type && { mime_type: file.type }),
      });
    } catch (error) {
      console.error("File upload error:", error);
    }
  }

  return uploadedFiles;
};
