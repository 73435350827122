import React, { useEffect, useRef, useState } from "react";
import { useController } from "react-hook-form";
import { SketchPicker } from "react-color";
import { Button, Overlay, Popover } from "react-bootstrap";

const ColorPicker = ({ control, name, disabled }) => {
  const [isShowColorPicker, setIsShowColorPicker] = useState(false);
  const [target, setTarget] = useState(null);
  const pickerRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsShowColorPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClick = event => {
    event.stopPropagation(); // Предотвращаем всплытие клика

    if (disabled) {
      return;
    }

    setIsShowColorPicker(prev => !prev);
    setTarget(event.target);
  };

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    defaultValue: "",
  });

  const handleOnChangeColor = color => {
    onChange(color.hex);
  };

  return (
    <div>
      <Button
        ref={buttonRef}
        onClick={handleClick}
        style={{
          backgroundColor: value || "transparent",
          borderColor: value,
          color: "#111",
        }}
      >
        {value || "Select Color"}
      </Button>
      <Overlay
        show={isShowColorPicker}
        target={target}
        placement="right"
        containerPadding={20}
        rootClose
        onHide={() => setIsShowColorPicker(false)}
      >
        <Popover id="popover-contained" ref={pickerRef}>
          <Popover.Header as="h3">Current colors</Popover.Header>
          <Popover.Body onClick={e => e.stopPropagation()}>
            <SketchPicker
              color={value}
              onChangeComplete={handleOnChangeColor}
              presetColors={[
                "#f8d7da",
                "#d1ecf1",
                "#d4edda",
                "#fff3cd",
                "#fce8b2",
                "#e2e3e5",
                "#e0c3fc",
                "#c8e6c9",
                "#ffe0b2",
                "#f3e5f5",
              ]}
            />
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default ColorPicker;
