import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addPatientProfile,
  addPatientToFamilyAccess,
  getFamilyAccessDetails,
  getPatientFamilyAccessDetails,
  getPatientProfileDetails,
  getPatientProfilesList,
  removePatientFromFamilyAccess,
  removePatientProfile,
  updateMedRecords,
  updatePatientFamilyAccess,
  updatePatientProfile,
} from "../../api/patient";
import { patientsSliceName } from "../reducers/patientSlice";
import { getScheduleList } from "../../api/calendar";
import { getTreatmentPlansListApi } from "../../api/treatments";

export const getPatientsList = createAsyncThunk(
  `${patientsSliceName}/list`,
  async ({ queryObj, isWithQueries = true }, thunkAPI) => {
    try {
      const { page, page_size } = thunkAPI.getState().patients;
      const query = {
        page,
        page_size,
        ...(queryObj && { ...queryObj }),
      };
      const response = await getPatientProfilesList(isWithQueries && query);

      return {
        data: response.data,
        page: +query.page,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getPatientProfile = createAsyncThunk(
  `${patientsSliceName}/getProfile`,
  async ({ id }, thunkAPI) => {
    try {
      const response = await getPatientProfileDetails({
        id,
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const addPatient = createAsyncThunk(
  `${patientsSliceName}/add`,
  async (patientData, thunkAPI) => {
    try {
      const response = await addPatientProfile({
        patientProfileData: patientData,
      });

      return {
        data: response.data,
        successMessage: "Patient added successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to add patient" });
    }
  },
);

export const updatePatient = createAsyncThunk(
  `${patientsSliceName}/update`,
  async (patientData, thunkAPI) => {
    try {
      const response = await updatePatientProfile({
        id: patientData.id,
        patientProfileData: patientData,
      });

      return {
        data: response.data,
        successMessage: "Patient updated successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update patient" });
    }
  },
);

export const updateMedicalRecords = createAsyncThunk(
  `${patientsSliceName}/update`,
  async (data, thunkAPI) => {
    try {
      const response = await updateMedRecords({
        id: data.id,
        data,
      });

      return {
        data: response.data,
        successMessage: "Medical records updated successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update medical record" });
    }
  },
);

export const removePatient = createAsyncThunk(
  `${patientsSliceName}/deleteProfile`,
  async (id, thunkAPI) => {
    try {
      const response = await removePatientProfile(id);

      return {
        data: response.data,
        successMessage: "Patient removed successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to remove patient" });
    }
  },
);

export const getPatientAppointments = createAsyncThunk(
  `${patientsSliceName}/getAppointments`,
  async (patient, thunkAPI) => {
    try {
      const response = await getScheduleList({
        patient,
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getPatientTreatmentPlansList = createAsyncThunk(
  `${patientsSliceName}/getTreatmentPlansList`,
  async (patientId, thunkAPI) => {
    const { ordering } = thunkAPI.getState().treatments.treatmentPlans;
    const query = {
      patient: patientId,
      ordering,
    };

    try {
      const response = await getTreatmentPlansListApi(query);

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getPatientFamilyAccessList = createAsyncThunk(
  `${patientsSliceName}/getFamilyAccessList`,
  async (patientId, thunkAPI) => {
    try {
      const response = await getPatientFamilyAccessDetails(patientId);

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getFamilyAccessItemDetails = createAsyncThunk(
  `${patientsSliceName}/getFamilyAccessItem`,
  async (accessId, thunkAPI) => {
    try {
      const response = await getFamilyAccessDetails(accessId);

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const createPatientFamilyAccess = createAsyncThunk(
  `${patientsSliceName}/addPatientToFamilyAccess`,
  async (data, thunkAPI) => {
    try {
      const response = await addPatientToFamilyAccess(data);

      return {
        data: response.data,
        successMessage: "Family access created successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to create family access" });
    }
  },
);

export const updatePatientFamilyAccessItem = createAsyncThunk(
  `${patientsSliceName}/updatePatientFamilyAccess`,
  async ({ accessId, data }, thunkAPI) => {
    try {
      const response = await updatePatientFamilyAccess(accessId, data);

      return {
        data: response.data,
        successMessage: "Family access item updated successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update family access item" });
    }
  },
);

export const deletePatientFamilyAccessItem = createAsyncThunk(
  `${patientsSliceName}/deletePatientFamilyAccessItem`,
  async (id, thunkAPI) => {
    try {
      const response = await removePatientFromFamilyAccess(id);

      return {
        data: response.data,
        successMessage: "Family access item deleted successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to delete family access item" });
    }
  },
);
