import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Button } from "react-bootstrap";
import MedicalCheckoutServicesList from "./MedicalCheckoutServicesList";
import * as Yup from "yup";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { uploadPatientFiles } from "../../api/files";
import { FiPaperclip } from "react-icons/fi";
import { Link } from "react-router-dom";
import FileLoader from "../FileLoader/FileLoader";
import { checkIfRequired } from "../../utils/requiredField";
import { FaRegCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import { addMedicalCheckout, deleteMedicalCheckout } from "../../store/actions/medicalCheckout";
import * as calendarActions from "../../store/actions/calendar";

const MedicalCheckoutEditForm = ({
  delayedShow,
  isShowEditCheckoutModal,
  setIsShowEditCheckoutModal,
  eventItem,
  currentService,
}) => {
  const dispatch = useDispatch();
  const services = useSelector(s => s.services.list);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const validationSchema = Yup.object().shape({
    checkout_services: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number()
            .transform((value, originalValue) => (originalValue === "" ? null : value))
            .nullable(),
          checkout: Yup.number()
            .transform((value, originalValue) => (originalValue === "" ? null : value))
            .nullable(),
          service: Yup.string().required("Service is required"),
          duration_minutes: Yup.number()
            .transform((value, originalValue) => (originalValue === "" ? null : value))
            .nullable(false)
            .min(1, "Duration is required")
            .required("Duration is required"),
          price: Yup.number()
            .transform((value, originalValue) => (originalValue === "" ? null : value))
            .nullable(false)
            .min(1, "Price is required")
            .required("Price is required"),
          comment: Yup.string().nullable(),
        }),
      )
      .min(1, "At least one service must be selected")
      .required("At least one service must be selected"),
    files: Yup.array(),
    price: Yup.string(),
    name: Yup.string(),
    created_at: Yup.date()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .nullable(),
    fee: Yup.string(),
    deleted_services: Yup.array(),
  });

  const handleFileChange = async event => {
    const selectedFiles = Array.from(event.target.files);

    const newFiles = selectedFiles.filter(
      file => !uploadedFiles.some(existingFile => existingFile?.file?.name === file.name),
    );

    if (newFiles.length === 0) {
      return;
    }

    try {
      setIsUploadingFiles(true);
      const newUploadedFiles = await uploadPatientFiles(
        newFiles,
        isEditMode ? eventItem.patient.id : undefined,
      );
      setUploadedFiles(prevFiles => [...prevFiles, ...newUploadedFiles]);
      console.log("Uploaded file IDs:", newUploadedFiles);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setIsUploadingFiles(false);
    }
  };

  const removeFile = (e, id) => {
    e.preventDefault();
    const currentFile = uploadedFiles.find(file => file.id === id);
    dispatch(
      showSubmitConfirmation({
        message: `Are you sure you want to delete file: ${currentFile.filename}?`,
        onConfirm: () => {
          setUploadedFiles(prevFiles => prevFiles.filter(file => file.id !== id));
        },
      }),
    );
  };

  const defaultValues = {
    checkout_services: [],
    deleted_services: [],
    files: [],
    price: "",
    name: "",
    created_at: "",
    fee: "",
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const methods = useForm(formOptions);

  const { register, trigger, handleSubmit, formState, setValue, reset, control, watch } = methods;
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (eventItem?.checkout) {
      const checkoutInfo = eventItem.checkout;
      setTimeout(() => {
        reset({
          files: [],
          price: checkoutInfo.price,
          name: checkoutInfo.name,
          fee: checkoutInfo.fee,
          created_at: checkoutInfo.created_at ? moment(checkoutInfo.created_at).toDate() : null,
          checkout_services: checkoutInfo.checkout_services_details,
          deleted_services: [],
        });

        if (checkoutInfo.files_details) {
          setUploadedFiles(checkoutInfo.files_details);
        }

        setIsEditMode(true);
      }, 0);
    } else {
      if (services?.length && currentService) {
        const service = services.find(service => service.id === currentService.service);

        reset(defaultValues);

        if (service) {
          setValue(
            "checkout_services",
            [
              {
                service: service.id,
                price: service.price,
                duration_minutes: service.duration_minutes,
                comment: "",
                id: "",
                checkout: "",
              },
            ],
            {
              shouldDirty: true,
            },
          );
        }
      }

      setIsEditMode(false);
    }
  }, [eventItem, delayedShow, reset, setValue, currentService]);

  const submitDeleteActions = async () => {
    await dispatch(calendarActions.getEventDetails({ id: eventItem.id }));
    dispatch(calendarActions.getCalendarList({}));
    setIsShowEditCheckoutModal(false);
    handleCloseEvents();
  };

  const onSubmit = async formValues => {
    const { price, ...rest } = formValues;
    const checkoutData = {
      ...rest,
      files: uploadedFiles.map(file => file.id),
    };
    await dispatch(
      addMedicalCheckout({
        eventId: eventItem.id,
        checkoutData,
      }),
    );
    await submitDeleteActions();
  };

  const checkoutServices = useWatch({ control, name: "checkout_services" });
  const createdAt = watch("created_at");
  useEffect(() => {
    if (checkoutServices) {
      const totalAmount = checkoutServices.reduce((acc, item) => acc + +item.price, 0);
      setValue("price", totalAmount, { shouldDirty: false });
    }
  }, [checkoutServices, setValue]);

  const handleCloseEvents = () => {
    setUploadedFiles([]);
    reset(defaultValues);
    setIsEditMode(false);
  };

  const isDirtyState = formState.isDirty;

  const handleFormClose = async () => {
    if (isDirtyState) {
      dispatch(
        showSubmitConfirmation({
          message: "Are you sure you want to exit? All unsaved data will be lost.",
          onConfirm: async () => {
            await submitDeleteActions();
          },
        }),
      );
    }

    if (!isDirtyState) {
      await submitDeleteActions();
    }
  };

  const handleFormDelete = async () => {
    dispatch(
      showSubmitConfirmation({
        message: "Are you sure you want to delete medical checkout?",
        onConfirm: async () => {
          await dispatch(deleteMedicalCheckout(eventItem.id));
          await submitDeleteActions();
        },
      }),
    );
  };

  return (
    <Offcanvas
      show={delayedShow}
      placement="end"
      className={classNames("offcanvasEventEditForm", {
        offcanvasEventAddCheckoutForm: isShowEditCheckoutModal,
      })}
      backdrop={true}
    >
      <Offcanvas.Header>
        <Offcanvas.Title>{`${isEditMode ? "Edit Medical Checkout" : "Create Medical Checkout"} Form`}</Offcanvas.Title>
        <div className="d-flex gap-1">
          {isEditMode && (
            <Button variant="danger" onClick={handleFormDelete}>
              Delete
            </Button>
          )}
          <Button variant="secondary" onClick={handleFormClose}>
            Close
          </Button>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <FormProvider {...methods}>
          <form className="login-form mt-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-lg-12">
              <div className="mb-3 form-group">
                <label className="form-label">Medical Checkout Services</label>
                <MedicalCheckoutServicesList
                  control={control}
                  services={services}
                  name="checkout_services"
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-3 mb-3">
              {isEditMode && (
                <div className="col-lg-12">
                  <label className="form-label">{`Checkout Name${checkIfRequired("name", validationSchema)}`}</label>
                  <input
                    name="name"
                    id="name"
                    type="text"
                    className={classNames("form-control", {
                      "is-invalid": errors.name,
                    })}
                    placeholder="Checkout Name"
                    {...register("name")}
                  />
                  <div className="invalid-feedback">{errors.name?.message}</div>
                </div>
              )}
              <div className="col-lg-12">
                <label className="form-label">{`Total Amount${checkIfRequired("price", validationSchema)}`}</label>
                <input
                  name="price"
                  id="price"
                  type="number"
                  disabled={true}
                  className={classNames("form-control", {
                    "is-invalid": errors.price,
                  })}
                  placeholder="Total Amount"
                  {...register("price")}
                />
                <div className="invalid-feedback">{errors.price?.message}</div>
              </div>
              {isEditMode && (
                <>
                  <div className="col-lg-12">
                    <label className="form-label">{`Total Fee${checkIfRequired("fee", validationSchema)}`}</label>
                    <input
                      name="fee"
                      id="fee"
                      type="number"
                      className={classNames("form-control", {
                        "is-invalid": errors.fee,
                      })}
                      placeholder="Total Fee"
                      {...register("fee")}
                    />
                    <div className="invalid-feedback">{errors.fee?.message}</div>
                  </div>
                  <div className="col-lg-12">
                    <label className="form-label">{`Created date${checkIfRequired("created_at", validationSchema)}`}</label>
                    <DatePicker
                      showIcon
                      closeOnScroll={true}
                      icon={<FaRegCalendarAlt />}
                      timeIntervals={1}
                      selected={createdAt ? createdAt : null}
                      onChange={date => {
                        setValue("created_at", date ? moment(date).toDate() : "", {
                          shouldValidate: true,
                        });
                      }}
                      showTimeSelect
                      dateFormat="dd.MM.yyyy (HH:mm)"
                      placeholderText="Select created date"
                      className={classNames("form-control", {
                        "is-invalid": errors.created_at,
                      })}
                    />
                    {errors.created_at && (
                      <div className="invalid-feedback d-block">{errors.created_at.message}</div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-12">
              <div className="mb-3 form-group">
                <label className="form-label me-2">Attachments</label>
                <label className="btn btn-icon btn-primary">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png, application/pdf"
                    multiple
                    onClick={e => (e.target.value = null)}
                    onChange={handleFileChange}
                  />
                  <FiPaperclip />
                </label>
                <div className="mb-2 d-flex flex-wrap">
                  {isUploadingFiles && <p style={{ width: "100%" }}>Uploading files...</p>}
                  {uploadedFiles.map((item, index) => {
                    const attachmentUrl = item.safe_url;

                    return (
                      <Link
                        to={attachmentUrl}
                        key={index}
                        className="position-relative m-2"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FileLoader item={item} removeFile={removeFile} />
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-12 mb-0">
              <div className="d-grid">
                <button
                  disabled={isSubmitting || isUploadingFiles}
                  className="btn btn-primary"
                  type="submit"
                >
                  {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                  Submit
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MedicalCheckoutEditForm;
