import Modal from "react-bootstrap/Modal";
import React from "react";
import moment from "moment/moment";
import ShortInfoBody from "./ShortInfoBody";

const ShortInfoProfileModal = ({
  isShowShortProfileInfo,
  handleCloseShortProfile,
  shortInfoPatientData,
}) => {
  return (
    <>
      {shortInfoPatientData && (
        <Modal show={isShowShortProfileInfo} onHide={() => handleCloseShortProfile()} centered>
          <Modal.Header closeButton>
            <h5 className="modal-title" id="exampleModalLabel1">
              Patient Profile
            </h5>
          </Modal.Header>
          <Modal.Body>
            <ShortInfoBody shortInfoPatientData={shortInfoPatientData} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ShortInfoProfileModal;
