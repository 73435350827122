import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import moment from "moment";
import DatePicker from "react-datepicker";
import { FaRegCalendarAlt } from "react-icons/fa";
import { addEventSchedule, updateEventSchedule } from "../../store/actions/calendar";
import * as calendarActions from "../../store/actions/calendar";

const DoctorUnavailableForm = ({ isShowEditForm, handleClose }) => {
  const doctorProfile = useSelector(s => s.doctors.doctorProfile);
  const eventItem = useSelector(s => s.calendar.dialog.selected);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    comment: Yup.string(),
    start: Yup.string().required("Start is required"),
    end: Yup.string().required("End is required"),
    entry_type: Yup.string().required("Entry type is required"),
    doctor: Yup.number().required("Doctor is required"),
  });

  const defaultValues = {
    comment: "",
    start: moment().startOf("day").toISOString(),
    end: moment().add(1, "day").startOf("day").toISOString(),
    entry_type: "DOCTOR_UNAVAILABLE",
    doctor: doctorProfile?.id,
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues,
  };
  const methods = useForm(formOptions);
  const { handleSubmit, formState, setValue, reset, watch, register } = methods;
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (eventItem?.id) {
      reset({
        comment: eventItem.comment || "",
        start: eventItem?.start || "",
        end: eventItem?.end || "",
        entry_type: eventItem?.entry_type || "DOCTOR_UNAVAILABLE",
        doctor: eventItem?.doctor || "",
      });
    }
  }, [eventItem, reset]);

  const onSubmit = async formValues => {
    if (eventItem?.id) {
      const { patient, ...otherEventData } = eventItem;
      await dispatch(
        updateEventSchedule({ id: eventItem.id, eventData: { ...otherEventData, ...formValues } }),
      );
    } else {
      await dispatch(addEventSchedule(formValues));
    }

    await dispatch(
      calendarActions.getCalendarList({
        isWithQueries: true,
        queryObj: {
          doctor: doctorProfile.id,
          entry_type: "DOCTOR_UNAVAILABLE",
          start: null,
          end: null,
          page: null,
          page_size: null,
        },
      }),
    );

    handleClose();
    reset(defaultValues);
  };

  const handleFormClose = () => {
    if (formState.isDirty) {
      dispatch(
        showSubmitConfirmation({
          message: "Are you sure you want to exit? All unsaved data will be lost.",
          onConfirm: () => {
            handleClose();
            reset(defaultValues);
          },
        }),
      );
    }

    if (!formState.isDirty) {
      handleClose();
      reset(defaultValues);
    }
  };

  return (
    <div>
      <Offcanvas
        show={isShowEditForm}
        onHide={() => {
          handleFormClose();
        }}
        backdrop={true}
        keyboard={false}
        placement="end"
        className="offcanvasEventEditForm"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>{`${eventItem?.id ? "Edit" : "Create"} ${doctorProfile?.first_name} ${doctorProfile?.last_name} Unavailable Plan`}</Offcanvas.Title>
          <div className="d-flex gap-1">
            <Button variant="secondary" onClick={handleFormClose}>
              Close
            </Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FormProvider {...methods}>
            <form className="login-form mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Date & Time</label>
                    <div className="d-flex gap-2 align-items-center">
                      <span className="text-muted col-1">{"from: "}</span>
                      <div style={{ maxWidth: 130 }}>
                        <DatePicker
                          showIcon
                          closeOnScroll={true}
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          minDate={moment().subtract(100, "years").toDate()}
                          icon={<FaRegCalendarAlt />}
                          selected={
                            watch("start") ? moment.parseZone(watch("start")).toDate() : null
                          }
                          onChange={date => {
                            const newDateData = {
                              year: moment(date).year(),
                              month: moment(date).month(),
                              date: moment(date).date(),
                            };
                            setValue(
                              "start",
                              moment(watch("start"), moment.ISO_8601)
                                .set(newDateData)
                                .format("YYYY-MM-DDTHH:mm:ssZ"),
                              {
                                shouldDirty: true,
                              },
                            );
                          }}
                          dateFormat="dd.MM.yyyy"
                          placeholderText="Select date"
                          className={classNames("form-control", {
                            "is-invalid": errors.start,
                          })}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="text-muted">{"time: "}</span>
                        <DatePicker
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          selected={
                            watch("start") ? moment.parseZone(watch("start")).toDate() : null
                          }
                          onChange={time => {
                            setValue(
                              "start",
                              moment(watch("start"), moment.ISO_8601)
                                .set({
                                  hour: moment(time).hour(),
                                  minute: moment(time).minute(),
                                })
                                .format("YYYY-MM-DDTHH:mm:ssZ"),
                              {
                                shouldDirty: true,
                              },
                            );
                          }}
                          placeholderText="Select start time"
                          className={classNames("form-control", "d-inline-block", {
                            "is-invalid": errors.start,
                          })}
                        />
                      </div>
                    </div>
                    {errors.start && (
                      <div className="invalid-feedback d-block">{errors.start.message}</div>
                    )}
                    <div className="d-flex gap-2 align-items-center">
                      <span className="text-muted col-1">{"to: "}</span>
                      <div style={{ maxWidth: 130 }}>
                        <DatePicker
                          showIcon
                          closeOnScroll={true}
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          minDate={moment().subtract(100, "years").toDate()}
                          icon={<FaRegCalendarAlt />}
                          selected={watch("end") ? moment.parseZone(watch("end")).toDate() : null}
                          onChange={date => {
                            const newDateData = {
                              year: moment(date).year(),
                              month: moment(date).month(),
                              date: moment(date).date(),
                            };
                            setValue(
                              "end",
                              moment(watch("end"), moment.ISO_8601)
                                .set(newDateData)
                                .format("YYYY-MM-DDTHH:mm:ssZ"),
                              {
                                shouldDirty: true,
                              },
                            );
                          }}
                          dateFormat="dd.MM.yyyy"
                          placeholderText="Select date"
                          className={classNames("form-control", {
                            "is-invalid": errors.start,
                          })}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="text-muted">{"time: "}</span>
                        <DatePicker
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          selected={watch("end") ? moment.parseZone(watch("end")).toDate() : null}
                          onChange={time => {
                            setValue(
                              "end",
                              moment(watch("end"), moment.ISO_8601)
                                .set({
                                  hour: moment(time).hour(),
                                  minute: moment(time).minute(),
                                })
                                .format("YYYY-MM-DDTHH:mm:ssZ"),
                              {
                                shouldDirty: true,
                              },
                            );
                          }}
                          placeholderText="Select end time"
                          className={classNames("form-control", "d-inline-block", {
                            "is-invalid": errors.end,
                          })}
                        />
                      </div>
                    </div>

                    {errors.end && (
                      <div className="invalid-feedback d-block">{errors.end.message}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Comment</label>
                    <textarea
                      name="comment"
                      rows="3"
                      className={classNames("form-control", {
                        "is-invalid": errors.comment,
                      })}
                      placeholder="Comment"
                      {...register("comment")}
                    />
                    <div className="invalid-feedback">{errors.comment?.message}</div>
                  </div>
                </div>

                <div className="col-lg-12 mb-0">
                  <div className="d-grid">
                    <button disabled={isSubmitting} className="btn btn-primary">
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default DoctorUnavailableForm;
