import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addDoctorProfile,
  getDoctorProfileDetails,
  getDoctorProfilesList,
  getDoctorServices,
  removeDoctorProfile,
  updateDoctorProfile,
  updateMeDoctorProfile,
} from "../../api/doctor";
import { doctorSliceName } from "../reducers/doctorSlice";

export const getDoctorsList = createAsyncThunk(
  `${doctorSliceName}/list`,
  async ({ queryObj, isWithQueries = true }, thunkAPI) => {
    try {
      const { page, page_size, ordering } = thunkAPI.getState().doctors;
      const query = {
        page,
        page_size,
        ordering,
        ...(queryObj && { ...queryObj }),
      };
      const response = await getDoctorProfilesList(isWithQueries && query);

      return {
        data: response.data,
        page: +query.page,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getDoctorProfile = createAsyncThunk(
  `${doctorSliceName}/getProfile`,
  async ({ id }, thunkAPI) => {
    try {
      const response = await getDoctorProfileDetails({
        id,
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const addDoctor = createAsyncThunk(
  `${doctorSliceName}/add`,
  async (
    {
      first_name,
      last_name,
      specializations,
      additional_services,
      photo,
      calendar_color,
      can_participate_in_treatment_plans,
      username,
      password,
    },
    thunkAPI,
  ) => {
    try {
      const response = await addDoctorProfile({
        doctorProfileData: {
          first_name,
          last_name,
          specializations,
          additional_services,
          photo,
          calendar_color,
          can_participate_in_treatment_plans,
          username,
          password,
        },
      });

      return {
        data: response.data,
        successMessage: "Doctor added successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to add doctor" });
    }
  },
);

export const updateDoctor = createAsyncThunk(
  `${doctorSliceName}/update`,
  async (
    {
      id,
      first_name,
      last_name,
      specializations,
      additional_services,
      calendar_color,
      photo,
      can_participate_in_treatment_plans,
      username,
      password,
      files,
    },
    thunkAPI,
  ) => {
    try {
      const response = await updateDoctorProfile({
        id,
        doctorProfileData: {
          id,
          first_name,
          last_name,
          specializations,
          additional_services,
          calendar_color,
          photo,
          can_participate_in_treatment_plans,
          username,
          password,
          files,
        },
      });

      return {
        data: response.data,
        successMessage: "Doctor updated successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update doctor" });
    }
  },
);

export const updateDoctorMe = createAsyncThunk(
  `${doctorSliceName}/updateDoctorMe`,
  async (doctorProfileData, thunkAPI) => {
    try {
      const response = await updateMeDoctorProfile(doctorProfileData);

      return {
        data: response.data,
        successMessage: "My Profile updated successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update my profile" });
    }
  },
);

export const removeDoctor = createAsyncThunk(
  `${doctorSliceName}/deleteProfile`,
  async (id, thunkAPI) => {
    try {
      const response = await removeDoctorProfile(id);

      return {
        data: response.data,
        successMessage: "Doctor removed successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to remove doctor" });
    }
  },
);

export const getDoctorAllServices = createAsyncThunk(
  `${doctorSliceName}/getAllServices`,
  async ({ id }, thunkAPI) => {
    try {
      const response = await getDoctorServices({
        id,
      });

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
