import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addPatientTreatmentPlanApi,
  addTreatmentStageToPlanApi,
  getTreatmentPlanDetailsApi,
  getTreatmentPlansListApi,
  getTreatmentStageDetailsApi,
  removeTreatmentPlanApi,
  removeTreatmentStageApi,
  sendTreatmentPlanForApproveApi,
  updateTreatmentPlanApi,
  updateTreatmentStageApi,
} from "../../api/treatments";

export const treatmentsSliceName = "treatments";

export const getTreatmentPlansList = createAsyncThunk(
  `${treatmentsSliceName}/list`,
  async ({ queryObj, isWithQueries = false }, thunkAPI) => {
    try {
      const { page, page_size } = thunkAPI.getState().treatments.treatmentPlans;
      const query = {
        page,
        page_size,
        ...(queryObj && { ...queryObj }),
      };
      const response = await getTreatmentPlansListApi(isWithQueries && query);

      return {
        data: response.data,
        page: +query.page,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const addTreatmentPlan = createAsyncThunk(
  `${treatmentsSliceName}/addTreatmentPlan`,
  async (treatmentPlanData, thunkAPI) => {
    try {
      const response = await addPatientTreatmentPlanApi(treatmentPlanData);

      return {
        data: response.data,
        successMessage: "Treatment plan added successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to add treatment plan" });
    }
  },
);

export const updateTreatmentPlan = createAsyncThunk(
  `${treatmentsSliceName}/updateTreatmentPlan`,
  async (treatmentPlanData, thunkAPI) => {
    try {
      const response = await updateTreatmentPlanApi(treatmentPlanData);

      return {
        data: response.data,
        successMessage: "Treatment plan updated successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update treatment plan" });
    }
  },
);

export const removeTreatmentPlan = createAsyncThunk(
  `${treatmentsSliceName}/removeTreatmentPlan`,
  async (id, thunkAPI) => {
    try {
      const response = await removeTreatmentPlanApi(id);

      return {
        data: response.data,
        successMessage: "Treatment plan removed successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to remove treatment plan" });
    }
  },
);

export const sendTreatmentPlanForApprove = createAsyncThunk(
  `${treatmentsSliceName}/sendTreatmentPlanForApprove`,
  async (id, thunkAPI) => {
    try {
      const response = await sendTreatmentPlanForApproveApi(id);

      return {
        data: response.data,
        successMessage: "Treatment plan send for approve successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        errorMessage: "Failed to send for approve treatment plan",
      });
    }
  },
);

export const getTreatmentPlan = createAsyncThunk(
  `${treatmentsSliceName}/getTreatmentPlan`,
  async (id, thunkAPI) => {
    try {
      const response = await getTreatmentPlanDetailsApi(id);

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to load treatment plan" });
    }
  },
);

export const addTreatmentStage = createAsyncThunk(
  `${treatmentsSliceName}/addTreatmentStage`,
  async (treatmentStageData, thunkAPI) => {
    try {
      const response = await addTreatmentStageToPlanApi(treatmentStageData);

      return {
        data: response.data,
        successMessage: "Treatment stage added successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to add treatment stage" });
    }
  },
);

export const getTreatmentStage = createAsyncThunk(
  `${treatmentsSliceName}/getTreatmentStage`,
  async (id, thunkAPI) => {
    try {
      const response = await getTreatmentStageDetailsApi(id);

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to load treatment stage" });
    }
  },
);

export const updateTreatmentStage = createAsyncThunk(
  `${treatmentsSliceName}/updateTreatmentStage`,
  async ({ treatmentStageData, isWithNotification = true }, thunkAPI) => {
    try {
      const response = await updateTreatmentStageApi(treatmentStageData);

      return {
        data: response.data,
        ...(isWithNotification && {
          successMessage: "Treatment stage updated successfully",
        }),
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update treatment stage" });
    }
  },
);

export const updateTreatmentStages = createAsyncThunk(
  `${treatmentsSliceName}/updateTreatmentStages`,
  async ({ currenStage, updatedStages }, thunkAPI) => {
    try {
      await Promise.all(
        updatedStages.map((stage, index) => {
          if (stage.stage_number !== index + 1) {
            return thunkAPI.dispatch(
              updateTreatmentStage({
                treatmentStageData: { ...stage, stage_number: index + 1 },
                isWithNotification: stage.id === currenStage.stage.id,
              }),
            );
          }
        }),
      );
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update treatment stages" });
    }
  },
);

export const removeTreatmentStage = createAsyncThunk(
  `${treatmentsSliceName}/removeTreatmentStage`,
  async (id, thunkAPI) => {
    try {
      const response = await removeTreatmentStageApi(id);

      return {
        data: response.data,
        successMessage: "Treatment stage removed successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to remove treatment stage" });
    }
  },
);

export const updatePositionsStagesAfterRemoveItem = createAsyncThunk(
  `${treatmentsSliceName}/updatePositionsStagesAfterRemoveItem`,
  async (updatedStages, thunkAPI) => {
    try {
      await Promise.all(
        updatedStages.map((stage, index) => {
          if (stage.stage_number !== index + 1) {
            return thunkAPI.dispatch(
              updateTreatmentStage({
                treatmentStageData: { ...stage, stage_number: index + 1 },
                isWithNotification: false,
              }),
            );
          }
        }),
      );
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update treatment stages" });
    }
  },
);
