import { history } from "../../utils/history";
import { useCallback, useEffect, useRef, useState } from "react";
import { pagesWithSearch } from "../../config/settings";
import { DebounceInput } from "react-debounce-input";

const Search = () => {
  const [isShowSearch, setIsShowSearch] = useState(false);
  const location = history.location;
  const initialSearchRef = useRef(new URLSearchParams(location.search).get("search"));
  const [currentLocation, setCurrentLocation] = useState(location.pathname);
  const [searchValue, setSearchValue] = useState(
    initialSearchRef.current
      ? Object.fromEntries(new URLSearchParams(location.search))?.search
      : "",
  );

  useEffect(() => {
    const showSearch = pagesWithSearch.some(path => {
      const regex = new RegExp(`^${path}$`);

      return regex.test(location.pathname);
    });

    if (initialSearchRef.current) {
      setSearchValue(initialSearchRef.current);
      initialSearchRef.current = null;
    } else if (location.pathname !== currentLocation) {
      initialSearchRef.current = null;
      setSearchValue("");
      setCurrentLocation(location.pathname);
      const params = new URLSearchParams(location.search);

      if (params.has("search")) {
        params.delete("search");
        history.navigate(
          { pathname: location.pathname, search: params.toString() },
          { replace: true },
        );
      }
    }

    setIsShowSearch(showSearch);
  }, [currentLocation, location.pathname]);

  const updateSearchParams = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const currentParams = Object.fromEntries(params.entries());

    if ((searchValue && location.pathname === currentLocation) || currentParams.search) {
      if (searchValue !== currentParams.search) {
        if (searchValue) {
          params.set("search", searchValue);
        } else {
          params.delete("search");
        }

        if (currentParams.page && searchValue && currentParams.page !== "1") {
          params.set("page", "1");
        }

        history.navigate(
          { pathname: location.pathname, search: params.toString() },
          { replace: true },
        );
      }
    }
  }, [searchValue, location.pathname, location.search]);

  useEffect(() => {
    if (isShowSearch) updateSearchParams();
  }, [searchValue, updateSearchParams, isShowSearch]);

  const handleInputChange = event => {
    setSearchValue(event.target.value);
  };

  return (
    <>
      {isShowSearch && (
        <div className="search-bar p-0 ms-2">
          <div id="search" className="menu-search mb-0">
            <form role="search" id="searchform" className="searchform">
              <div>
                <DebounceInput
                  type="text"
                  debounceTimeout={700}
                  className="form-control border rounded-pill"
                  placeholder="Search Keywords..."
                  value={searchValue}
                  onChange={handleInputChange}
                />
                <input type="submit" id="searchsubmit" defaultValue="Search" disabled />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Search;
