import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import Wrapper from "../components/wrapper";

import FeedbackItem from "../components/feedback/FeedbackItem";
import query from "querystringify";
import { history } from "../utils/history";
import { useDispatch, useSelector } from "react-redux";
import { getFeedbackList, removeFeedback } from "../store/actions/feedback";
import { showSubmitConfirmation } from "../store/reducers/submitsSlice";
import Pagination from "../components/Pagination/Pagination";
import FeedbackInfoModal from "../components/feedback/FeedbackInfoModal";
import Ordering from "../components/queries/Ordering/Ordering";
import SelectAsyncFilter from "../components/queries/FIlter/SelectAsyncFilter";
import { getAsyncSelectOption, getPatientsData } from "../utils/getAsyncSelectOptions";
import { getPatientsList } from "../store/actions/patient";
import { resetFeedbackState } from "../store/reducers/feedbackSlice";
import { getDoctorsList } from "../store/actions/doctor";
import { getPatientProfileDetails } from "../api/patient";

export default function Feedbacks() {
  const queries = useMemo(() => query.parse(history.location.search), [history.location.search]);
  const dispatch = useDispatch();

  const [isShowModal, setIsShowModal] = useState(false);
  const [feedbackInfo, setFeedbackInfo] = useState(null);
  const feedbacksList = useSelector(s => s.feedback.list);

  useEffect(() => {
    dispatch(resetFeedbackState());
    dispatch(getDoctorsList({ isWithQueries: false }));
    dispatch(getPatientsList({ isWithQueries: false }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFeedbackList({ queryObj: queries, isWithQueries: true }));
  }, [dispatch, queries]);

  const deleteFeedback = useCallback(item => {
    dispatch(
      showSubmitConfirmation({
        message: `Are you sure want to delete feedback: ${item?.text}?`,
        onConfirm: () => {
          dispatch(removeFeedback(item.id));
          setTimeout(() => {
            dispatch(getFeedbackList({ queryObj: queries, isWithQueries: true }));
          }, 500);
        },
      }),
    );
  }, []);

  const handleCloseModal = () => {
    setIsShowModal(false);
    setFeedbackInfo(null);
  };

  const handleShowModal = id => {
    setFeedbackInfo(feedbacksList.find(item => item.id === id));
    setIsShowModal(true);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="layout-specing">
          <div className="d-md-flex justify-content-between">
            <h5 className="mb-0">Feedbacks</h5>

            <nav aria-label="breadcrumb" className="d-inline-block mt-4 mt-sm-0">
              <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/index">AOSmiles</Link>
                </li>
                {/*<li className="breadcrumb-item">*/}
                {/*  <Link to="#">Pages</Link>*/}
                {/*</li>*/}
                <li className="breadcrumb-item active" aria-current="page">
                  Feedbacks
                </li>
              </ul>
            </nav>
          </div>

          <div className="d-flex row gy-2 gx-2 w-100 mt-2">
            <div className="col-sm-12 col-lg-3">
              <div className="mb-0 position-relative">
                <SelectAsyncFilter
                  pageState="feedback"
                  filterName="patient"
                  getOptions={getPatientsData}
                  placeholder="Select patient"
                  getInitialOption={async id => {
                    const result = await getPatientProfileDetails({ id });

                    return getAsyncSelectOption(result.data);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive bg-white shadow rounded">
                    <table className="table mb-0 table-center">
                      <thead>
                        <tr>
                          <th className="border-bottom p-3" style={{ minWidth: "50px" }}>
                            #
                          </th>
                          <th className="border-bottom p-3" style={{ minWidth: "200px" }}>
                            Name
                          </th>
                          <th className="border-bottom p-3" style={{ minWidth: "150px" }}>
                            Contact No.
                          </th>
                          <th className="border-bottom p-3" style={{ minWidth: "150px" }}>
                            Email
                          </th>
                          {/*<th className="border-bottom p-3" style={{ minWidth: "150px" }}>*/}
                          {/*  Stars*/}
                          {/*</th>*/}
                          <th className="border-bottom p-3" style={{ minWidth: "350px" }}>
                            Comments
                          </th>
                          <th className="border-bottom p-3" style={{ minWidth: "150px" }}>
                            Date
                            <Ordering orderingPageState={"feedback"} orderName="ordering" />
                          </th>
                          <th className="border-bottom p-3">Time</th>
                          <th className="border-bottom p-3" style={{ minWidth: "350px" }}>
                            Files
                          </th>
                          <th className="border-bottom p-3">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {feedbacksList.map(item => {
                          return (
                            <FeedbackItem
                              key={item.id}
                              item={item}
                              removeItem={deleteFeedback}
                              showShortInfoModal={handleShowModal}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Pagination paginatedPageState="feedback" />
            </div>
          </div>
          <FeedbackInfoModal
            isShowModal={isShowModal}
            infoData={feedbackInfo}
            handleCloseModal={handleCloseModal}
          />
        </div>
      </div>
    </>
  );
}
