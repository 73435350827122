import { Link } from "react-router-dom";
import { FiEye, FiTrash } from "react-icons/fi";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import * as calendarActions from "../../store/actions/calendar";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import { status } from "../../config/settings";
import { TbStatusChange } from "react-icons/tb";

const AppointmentsListItem = ({
  appointment,
  showEditModal,
  showEditStatusModal,
  handleDelete,
}) => {
  const dispatch = useDispatch();
  const doctors = useSelector(s => s.doctors.list);
  const currentDoctor = doctors.find(doctor => doctor.id === appointment.doctor);

  const handleShowEditModal = () => {
    dispatch(calendarActions.getEventDetails({ id: appointment.id }));
    showEditModal();
  };

  const handleShowStatusModal = () => {
    dispatch(calendarActions.getEventDetails({ id: appointment.id }));
    showEditStatusModal();
  };

  const handleDeleteAppointment = () => {
    dispatch(
      showSubmitConfirmation({
        message: "Are you sure you want to delete event?",
        onConfirm: () => {
          handleDelete(appointment.id);
        },
      }),
    );
  };

  return (
    <tr>
      <th className="p-3">
        <span onClick={handleShowEditModal} style={{ cursor: "pointer" }}>
          {appointment.id}
        </span>
      </th>
      <td className="py-3">
        <Link to={`/patients/${appointment?.patient?.id}`} className="text-dark">
          <div className="d-flex align-items-center">
            <span className="ms-2">
              {appointment?.patient ? (
                `${appointment?.patient?.first_name} ${appointment?.patient?.last_name}`
              ) : (
                <span className="text-muted text-decoration-underline">
                  {appointment?.patient?.id ? "View Patient Profile" : ""}
                </span>
              )}
            </span>
          </div>
        </Link>
      </td>
      <td className="p-3">
        {appointment?.patient?.birth_date
          ? moment().diff(moment(appointment?.patient?.birth_date, "YYYY-MM-DD"), "years")
          : ""}
      </td>
      <td className="p-3">
        {appointment?.patient?.birth_date
          ? moment(appointment?.patient?.birth_date).format("DD-MM-YYYY")
          : ""}
      </td>
      <td className="p-3">{appointment?.patient?.gender}</td>
      <td className="p-3">
        <div>{appointment?.patient?.phone}</div>
        <div>{appointment?.patient?.emergency_phone_number}</div>
      </td>
      <td className="p-3">{appointment?.patient?.medicare_number}</td>
      <td className="p-3">{moment.parseZone(appointment.start).format("DD-MM-YYYY")}</td>
      <td className="p-3">{moment.parseZone(appointment.start).format("HH:mm")}</td>
      <td className="p-3">
        <Link to={`/doctors/${currentDoctor?.id}`} className="text-dark">
          <div className="d-flex align-items-center">
            <span className="ms-2">
              {currentDoctor ? `${currentDoctor?.first_name} ${currentDoctor?.last_name}` : ""}
            </span>
          </div>
        </Link>
      </td>
      <td className="p-3">
        <span className={`badge ${status[appointment.status].badgeClassName}`}>
          {status[appointment.status].label}
        </span>
      </td>
      <td className="text-end p-3">
        <div className="d-flex patientsActions">
          <div
            className="btn btn-icon btn-pills btn-soft-success mx-1"
            onClick={handleShowStatusModal}
          >
            <TbStatusChange />
          </div>
          <div className="btn btn-icon btn-pills btn-soft-primary" onClick={handleShowEditModal}>
            <FiEye />
          </div>
          <div className="btn btn-icon btn-pills btn-soft-danger" onClick={handleDeleteAppointment}>
            <FiTrash />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default AppointmentsListItem;
