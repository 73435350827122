import React, { useEffect, useRef, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { FiCornerUpLeft, FiPaperclip, FiSmile } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import EmojiPicker from "emoji-picker-react";
import { FaFilePdf, FaFileAlt } from "react-icons/fa";
import { uploadPatientFiles } from "../../api/files";

export default function ChatInput({
  newMessageText,
  setNewMessageText,
  addMessage,
  messageToWhichAnswer,
  setMessageToWhichAnswer,
  patientId,
}) {
  const [isActiveEmojiPicker, setIsActiveEmojiPicker] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [filesIds, setFilesIds] = useState([]);
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const emojiPickerOpenBtnRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target) &&
        emojiPickerOpenBtnRef.current &&
        !emojiPickerOpenBtnRef.current.contains(event.target)
      ) {
        setIsActiveEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFileChange = async event => {
    const selectedFiles = Array.from(event.target.files);

    const newFiles = selectedFiles.filter(
      file => !files.some(existingFile => existingFile.file.name === file.name),
    );

    if (newFiles.length === 0) {
      return;
    }

    setUploadingFiles(true);

    try {
      const uploadedFiles = await uploadPatientFiles(newFiles, patientId);
      setFilesIds(prevFiles => [...prevFiles, ...uploadedFiles.map(file => file.id)]);
    } catch (error) {
      console.error("Error uploading files:", error);
    }

    setUploadingFiles(false);

    const filesWithPreview = newFiles.map(file => ({
      file,
      previewUrl: file.type.startsWith("image/") ? URL.createObjectURL(file) : null,
    }));

    setFiles(prevFiles => [...prevFiles, ...filesWithPreview]);
  };

  const removeFile = index => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setFilesIds(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const textarea = inputRef.current;

    if (textarea) {
      textarea.style.height = "auto";
      const maxHeight = 120;
      textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;
    }
  }, [newMessageText]);

  return (
    <div className="p-2 rounded-bottom shadow">
      {messageToWhichAnswer && (
        <div className="row align-items-center mb-2">
          <div className="col-1 d-flex align-items-center justify-content-center">
            <FiCornerUpLeft style={{ transform: "scale(1.5)" }} />
          </div>
          <div className="col-10">
            {(messageToWhichAnswer.text.length > 150
              ? messageToWhichAnswer.text.slice(0, 150) + "..."
              : messageToWhichAnswer.text) || messageToWhichAnswer.files[0]?.filename}
            <IoClose
              className="ms-3"
              style={{ cursor: "pointer", transform: "scale(1.5)" }}
              onClick={() => setMessageToWhichAnswer(null)}
            />
          </div>
        </div>
      )}

      <div className="mb-2 d-flex flex-wrap">
        {uploadingFiles && <p>Uploading files...</p>}
        {files.map((item, index) => (
          <div key={index} className="position-relative m-2">
            {item.previewUrl ? (
              <img
                src={item.previewUrl}
                alt="Preview"
                className="img-thumbnail"
                style={{ width: "100px", height: "100px", objectFit: "cover" }}
              />
            ) : item.file.type === "application/pdf" ? (
              <div
                className="d-flex align-items-center justify-content-center bg-light border rounded"
                style={{ width: "100px", height: "100px" }}
              >
                <FaFilePdf size={40} className="text-danger" />
              </div>
            ) : (
              <div
                className="d-flex align-items-center justify-content-center bg-light border rounded"
                style={{ width: "100px", height: "100px" }}
              >
                <FaFileAlt size={40} className="text-secondary" />
              </div>
            )}
            <button
              className="btn btn-danger position-absolute p-0 d-flex align-items-center justify-content-center"
              style={styles.closeButton}
              onClick={() => removeFile(index)}
            >
              ✖
            </button>
            <div className="small text-truncate" style={{ maxWidth: "100px" }}>
              {item.file.name}
            </div>
          </div>
        ))}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/*,application/pdf"
          multiple
          onChange={handleFileChange}
        />
      </div>

      <div className="row">
        <div className="col">
          <textarea
            ref={inputRef}
            className="form-control border"
            placeholder="Enter Message..."
            value={newMessageText}
            onChange={e => {
              setNewMessageText(e.target.value);
            }}
            onKeyDown={e => {
              if (e.key === "Enter" && !e.shiftKey && (newMessageText.trim() || filesIds.length)) {
                e.preventDefault();

                if (uploadingFiles) return;

                addMessage(filesIds);
                setFiles([]);
                setFilesIds([]);
              }
            }}
            rows={1}
            style={{
              resize: "none",
              overflowY: "auto",
              maxHeight: "120px",
            }}
          />
        </div>
        <div className="col-auto position-relative d-flex align-items-center">
          {isActiveEmojiPicker && (
            <div style={{ position: "absolute", right: 0, bottom: "100%" }} ref={emojiPickerRef}>
              <EmojiPicker
                onEmojiClick={({ emoji }) => {
                  setNewMessageText(prev => prev + emoji);
                  inputRef.current.focus();
                }}
                lazyLoadEmojis={true}
              />
            </div>
          )}
          <div
            className="btn btn-icon btn-primary"
            onClick={() => {
              if (newMessageText.trim() || filesIds.length) {
                if (uploadingFiles) return;

                addMessage(filesIds);
                setFiles([]);
                setFilesIds([]);
              }
            }}
          >
            <AiOutlineSend />
          </div>
          <div
            className="btn btn-icon btn-primary mx-1"
            onClick={() => setIsActiveEmojiPicker(prev => !prev)}
            ref={emojiPickerOpenBtnRef}
          >
            <FiSmile />
          </div>
          <div className="btn btn-icon btn-primary" onClick={() => fileInputRef.current.click()}>
            <FiPaperclip />
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  closeButton: {
    top: "5px",
    right: "5px",
    width: "16px",
    height: "16px",
    fontSize: "10px",
    lineHeight: "1",
    borderRadius: "50%",
  },
};
