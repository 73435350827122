import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import blankImagePath from "../../assets/images/blank-image-icon.png";
import ImageLoader from "../FileLoader/ImageLoader";

const DoctorItem = ({ item }) => {
  const specializations = useSelector(s => s.specializations.list);
  const [isLoading, setIsLoading] = useState(true);

  const getSpecializationsDoctorData = list => {
    return list.reduce((acc, specializationId, index) => {
      const specialization = specializations.find(item => item.id === specializationId)?.name;

      if (specialization) {
        acc += specialization + (index < list.length - 1 ? ", " : "");
      }

      return acc;
    }, "");
  };

  return (
    <Link to={`/doctors/${item.id}`} className="col mt-4 d-flex">
      <div className="card team border-0 rounded shadow overflow-hidden flex-grow-1">
        <div
          className="team-img position-relative d-flex justify-content-center align-items-center"
          style={{ aspectRatio: "1" }}
        >
          <ImageLoader
            src={
              item?.photo_details?.safe_url
                ? `${item?.photo_details?.safe_url}?x=500&y=500`
                : blankImagePath
            }
            className="img-fluid"
            alt=""
          />
          {/*<ul className="list-unstyled team-social mb-0">*/}
          {/*  <li>*/}
          {/*    <Link to="#" className="btn btn-icon btn-pills btn-soft-primary">*/}
          {/*      <FiFacebook className="icons" />*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*  <li className="mt-2">*/}
          {/*    <Link to="#" className="btn btn-icon btn-pills btn-soft-primary">*/}
          {/*      <FiLinkedin className="icons" />*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*  <li className="mt-2">*/}
          {/*    <Link to="#" className="btn btn-icon btn-pills btn-soft-primary">*/}
          {/*      <FiInstagram className="icons" />*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*  <li className="mt-2">*/}
          {/*    <Link to="#" className="btn btn-icon btn-pills btn-soft-primary">*/}
          {/*      <FiTwitter className="icons" />*/}
          {/*    </Link>*/}
          {/*  </li>*/}
          {/*</ul>*/}
        </div>
        <div className="card-body content text-center">
          <p className="title text-dark h5 d-block mb-0">
            {`${item.first_name} ${item.last_name}`}
          </p>
          <small className="text-muted speciality">
            {getSpecializationsDoctorData(item.specializations)}
          </small>
          {/*{item.additional_services.map((service, index) => {*/}
          {/*  return (*/}
          {/*    <small key={service} className="text-muted speciality">*/}
          {/*      {`${service}${index !== item.additional_services.length - 1 && ", "}`}*/}
          {/*    </small>*/}
          {/*  );*/}
          {/*})}*/}
        </div>
      </div>
    </Link>
  );
};

export default DoctorItem;
