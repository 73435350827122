import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useForm, FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import CustomAsyncSelect from "../../Select/CustomAsyncSelect";
import { get } from "lodash";
import { history } from "../../../utils/history";

const SelectAsyncFilter = ({
  pageState,
  filterName,
  getOptions,
  placeholder = "Select Filter",
  getInitialOption,
}) => {
  const [initialOption, setInitialOption] = useState(null);
  const isFetched = useRef(false);
  const location = history.location;
  const state = useSelector(state => {
    return get(state, pageState, {});
  });
  const defaultValues = {
    [filterName]:
      +Object.fromEntries(new URLSearchParams(location.search))?.[filterName] || state[filterName],
  };

  const formOptions = {
    defaultValues: defaultValues,
  };
  const methods = useForm(formOptions);
  const { control, watch } = methods;

  const selectedValue = watch(filterName);

  useEffect(() => {
    if (!isFetched.current && defaultValues[filterName] && getInitialOption) {
      isFetched.current = true;

      (async () => {
        try {
          const option = await getInitialOption(defaultValues[filterName]);
          setInitialOption(option);
        } catch (error) {
          console.error("Error to load initialOption:", error);
        }
      })();
    }
  }, [defaultValues, filterName, getInitialOption]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentParams = Object.fromEntries(params.entries());

    if (
      currentParams.page &&
      selectedValue &&
      currentParams.page !== "1" &&
      selectedValue !== currentParams[filterName]
    ) {
      params.set("page", "1");
    }

    if (selectedValue) {
      params.set(filterName, selectedValue);
    } else {
      params.delete(filterName);
    }

    history.navigate({
      pathname: history.location.pathname,
      search: params.toString(),
    });
  }, [filterName, location.search, selectedValue]);

  return (
    <FormProvider {...methods}>
      <form className="login-form">
        <div className="form-group">
          <CustomAsyncSelect
            name={filterName}
            placeholder={placeholder}
            control={control}
            getOptions={getOptions}
            initialOption={initialOption}
            limit={10}
            isClearable={true}
            className={classNames("form-control", "p-0")}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default SelectAsyncFilter;
