import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addStaffProfileApi,
  getStaffListApi,
  getStaffProfileDetailsApi,
  removeStaffProfileApi,
  updateStaffProfileApi,
} from "../../api/staff";

export const staffSliceName = "staff";

export const getStaffList = createAsyncThunk(
  `${staffSliceName}/list`,
  async ({ queryObj, isWithQueries = true }, thunkAPI) => {
    try {
      const { page, page_size, ordering } = thunkAPI.getState().staff;
      const query = {
        page,
        page_size,
        ordering,
        ...(queryObj && { ...queryObj }),
      };
      const response = await getStaffListApi(isWithQueries && query);

      return {
        data: response.data,
        page: +query.page,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getStaffProfile = createAsyncThunk(
  `${staffSliceName}/getStaffProfile`,
  async (id, thunkAPI) => {
    try {
      const response = await getStaffProfileDetailsApi(id);

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const removeStaffProfile = createAsyncThunk(
  `${staffSliceName}/removeStaffProfile`,
  async (id, thunkAPI) => {
    try {
      const response = await removeStaffProfileApi(id);

      return {
        data: response.data,
        successMessage: "Staff removed successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to remove staff" });
    }
  },
);

export const updateStaffProfile = createAsyncThunk(
  `${staffSliceName}/updateStaffProfile`,
  async (staffData, thunkAPI) => {
    try {
      const response = await updateStaffProfileApi(staffData);

      return {
        data: response.data,
        successMessage: "Staff updated successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update staff" });
    }
  },
);

export const addStaffProfile = createAsyncThunk(
  `${staffSliceName}/addStaffProfile`,
  async (staffData, thunkAPI) => {
    try {
      const response = await addStaffProfileApi(staffData);

      return {
        data: response.data,
        successMessage: "Staff added successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to add staff" });
    }
  },
);
