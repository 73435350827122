import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShow: false,
  message: "",
  onConfirm: null,
  onCancel: null,
};

export const submitConfirmationSliceName = "submitConfirmation";

export const submitsSlice = createSlice({
  name: submitConfirmationSliceName,
  initialState,
  reducers: {
    showSubmitConfirmation: (state, action) => ({
      isShow: true,
      ...action.payload,
    }),
    closeSubmitConfirmation: () => ({
      ...initialState,
    }),
  },
});

export const { showSubmitConfirmation, closeSubmitConfirmation } = submitsSlice.actions;

export default submitsSlice.reducer;
