import React, { useMemo } from "react";
import Select from "react-select";
import { useController } from "react-hook-form";

const StatusSelect = ({
  control,
  name,
  statusFlags,
  placeholder = "Choose appointment status",
  className,
  isClearable = false,
  statusKeys,
}) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    defaultValue: null,
  });

  const options = useMemo(() => {
    if (statusKeys) {
      return Object.entries(statusFlags).reduce((acc, [key, value]) => {
        if (statusKeys.includes(key)) {
          acc.push(value);
        }

        return acc;
      }, []);
    }

    return Object.values(statusFlags);
  }, [statusFlags, statusKeys]);

  return (
    <div className="select">
      <Select
        options={options}
        value={options.find(option => option.value === value) || null}
        className={className}
        styles={{
          control: styles => ({
            ...styles,
            border: 0,
          }),
          singleValue: styles => ({
            ...styles,
            display: "flex",
            alignItems: "center",
          }),
          option: styles => ({
            ...styles,
            display: "flex",
            alignItems: "center",
          }),
        }}
        placeholder={placeholder}
        onChange={selectedOption => {
          onChange(selectedOption ? selectedOption.value : null);
        }}
        isClearable={isClearable}
      />
    </div>
  );
};

export default StatusSelect;
