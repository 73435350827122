import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";

import logoDark from "../../assets/images/aosLogo.png";
import bg1 from "../../assets/images/bg/bg-lines-one.png";
import { history } from "../../utils/history";
import * as authActions from "../../store/actions/auth";
import { CLIENT_ID } from "../../config/api";

export default function Login() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(s => s.auth.isLoggedIn);
  const authError = useSelector(s => s.auth.error);

  useEffect(() => {
    if (isLoggedIn) history.navigate("/");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("User name is required"),
    password: Yup.string().required("Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  function onSubmit({ username, password }) {
    const formData = new FormData();
    formData.append("grant_type", "password");
    formData.append("username", username);
    formData.append("password", password);
    formData.append("client_id", CLIENT_ID);

    return dispatch(authActions.login({ formData }));
  }

  return (
    <>
      {!isLoggedIn && (
        <section
          className="bg-home d-flex bg-light align-items-center"
          style={{ backgroundImage: `url(${bg1})`, backgroundPosition: "center" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-8">
                <img src={logoDark} height="60" className="mx-auto d-block" alt="" />
                <div className="card login-page shadow mt-4 rounded border-0">
                  <div className="card-body">
                    <h4 className="text-center">Sign In</h4>
                    <form className="login-form mt-4" onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb-3 form-group">
                            <label className="form-label">
                              User Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className={classNames("form-control", {
                                "is-invalid": errors.username,
                              })}
                              placeholder="User Name"
                              name="username"
                              {...register("username")}
                            />
                            <div className="invalid-feedback">{errors.username?.message}</div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="mb-3 form-group">
                            <label className="form-label">
                              Password <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="password"
                              className={classNames("form-control", {
                                "is-invalid": errors.password,
                              })}
                              placeholder="Password"
                              {...register("password")}
                            />
                            <div className="invalid-feedback">{errors.password?.message}</div>
                          </div>
                        </div>

                        {/*<div className="col-lg-12">*/}
                        {/*  <div className="d-flex justify-content-between">*/}
                        {/*    <div className="mb-3">*/}
                        {/*      <div className="form-check">*/}
                        {/*        <input*/}
                        {/*          className="form-check-input align-middle"*/}
                        {/*          type="checkbox"*/}
                        {/*          value=""*/}
                        {/*          id="remember-check"*/}
                        {/*        />*/}
                        {/*        <label className="form-check-label" htmlFor="remember-check">*/}
                        {/*          Remember me*/}
                        {/*        </label>*/}
                        {/*      </div>*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        <div className="col-lg-12 mb-0">
                          <div className="d-grid">
                            <button disabled={isSubmitting} className="btn btn-primary">
                              {isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                              )}
                              Sign in
                            </button>
                          </div>
                        </div>
                        {authError && (
                          <div className="col-lg-12">
                            <div className="invalid-feedback d-flex justify-content-center">
                              {authError}
                            </div>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
