import React, { useEffect, useState } from "react";

import Select from "react-select";
import { defaultTheme } from "react-select";
import { Button } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { FiArrowRight, FiPlus, FiTrash } from "react-icons/fi";
import moment from "moment";

const { colors } = defaultTheme;

const selectStyles = {
  control: provided => ({
    ...provided,
    minWidth: 240,
    margin: 8,
    border: 0,
  }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
};

const PopoutSelect = ({
  optionsData,
  valueField = "id",
  labelField = "name",
  initialValue,
  control,
  rules,
  name,
  className,
  selectTitle = "Select Services",
  buttonProps,
  openVariantColor = "dark",
  closeVariantColor = "light",
  isShowOptionsResult = true,
  isShowChevron = true,
  customOnChange,
}) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    if (initialValue && initialValue.length > 0 && isShowOptionsResult) {
      const initialOptions = initialValue.map(item =>
        optionsData.find(optionData => optionData.id === item.service),
      );
      setOptions(initialOptions);
    }
  }, [initialValue, isShowOptionsResult, optionsData]);

  useEffect(() => {
    const formattedOptions = optionsData.map(option => ({
      value: option[valueField],
      label: option[labelField],
    }));
    setSelectOptions(formattedOptions);
  }, [labelField, optionsData, valueField]);

  const handleOnButtonClick = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const onChange = option => {
          const currentOption = optionsData.find(optionData => optionData.id === option.value);

          if (isShowOptionsResult) {
            setValue(
              "end",
              moment(getValues("start")).add(currentOption.duration_minutes, "minutes"),
            );

            setOptions([currentOption]);
          }

          if (customOnChange) {
            customOnChange({ service: option.value, serviceData: currentOption });
          } else {
            field.onChange([{ service: option.value, serviceData: currentOption }], {
              shouldDirty: true,
            });
          }

          setIsOpen(false);
        };

        const handleDelete = index => {
          if (isShowOptionsResult) {
            setOptions([...options.filter((_, i) => i !== index)]);
          }

          field.onChange([...field.value.filter((_, i) => i !== index)]);
        };

        return (
          <>
            <Dropdown
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              className={className}
              target={
                <div className={className}>
                  <Button
                    variant={`${isOpen ? openVariantColor : closeVariantColor}`}
                    onClick={handleOnButtonClick}
                    className="btn-sm d-flex gap-2 align-items-center"
                    size="sm"
                    {...(buttonProps && buttonProps)}
                  >
                    <FiPlus />
                    <span className="fs-6">
                      {selectTitle} {isShowChevron && <ChevronDown />}
                    </span>
                  </Button>
                </div>
              }
            >
              <Select
                autoFocus
                backspaceRemovesValue={false}
                components={{ DropdownIndicator, IndicatorSeparator: null }}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                menuIsOpen
                onChange={onChange}
                options={selectOptions}
                placeholder="Search..."
                styles={selectStyles}
                tabSelectsValue={false}
              />
            </Dropdown>
            {error && <div className="invalid-feedback d-block">{error.message}</div>}
            {isShowOptionsResult && (
              <ul className="list-unstyled">
                {options.map((item, index) => {
                  return (
                    <li
                      className="mt-1 mb-2 ms-0 col-12 d-flex align-items-center"
                      key={`${item?.id}-${index}`}
                    >
                      <div className="col-11 d-flex align-items-center">
                        <FiArrowRight className="text-primary" />
                        <span className="ms-1">{item?.name ? item.name : ""}</span>
                        <span className="text-muted">{`(${moment
                          .utc(
                            moment
                              .duration(item?.duration_minutes || 0, "minutes")
                              .asMilliseconds(),
                          )
                          .format("HH:mm")})`}</span>
                      </div>
                      <div
                        className="btn btn-icon btn-pills btn-soft-danger col-1"
                        onClick={() => handleDelete(index)}
                      >
                        <FiTrash />
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      }}
    />
  );
};

const Menu = props => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  );
};

const Blanket = props => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div className="position-relative">
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = p => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
);
const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);

export default PopoutSelect;
