import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { history } from "../../utils/history";
import blankImagePath from "../../assets/images/blank-image-icon.png";
import { Button } from "react-bootstrap";
import { uploadPublicFiles } from "../../api/files";
import ImageLoader from "../FileLoader/ImageLoader";
import { DebounceInput } from "react-debounce-input";
import { checkUsernameApi } from "../../api/staff";
import { generateCustomUserName } from "../../utils/generateCustomUserName";
import { addStaffProfile, getStaffProfile, updateStaffProfile } from "../../store/actions/staff";
import { checkIfRequired } from "../../utils/requiredField";
import Spinner from "react-bootstrap/Spinner";

const StaffEditForm = ({
  staffProfile = {},
  buttonText = "Submit",
  isCanEdit,
  isCanDelete,
  isCanEditUsername,
  handleDelete,
  isDisableDeleteButton = false,
}) => {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters")
      .matches(/^[A-Za-z]+$/, "First Name can only contain letters"),
    last_name: Yup.string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters")
      .matches(/^[A-Za-z]+$/, "Last Name can only contain letters"),
    is_superuser: Yup.boolean(),
    username: Yup.string()
      .transform(value => (value === "" ? null : value))
      .nullable()
      .min(4, "Username must be at least 4 characters")
      .test("unique", "Checking username...", async (value, context) => {
        if (!value || value === staffProfile?.username) return true;

        const fieldName = context.path;

        try {
          await checkUsernameApi({ [fieldName]: value });

          return true;
        } catch (err) {
          if (err?.response?.data?.[fieldName]?.[0]) {
            return context.createError({
              message: err.response.data.username[0] || "Username is already taken",
            });
          }
        }

        return true;
      }),
    is_change_password: Yup.boolean(),
    password: Yup.string()
      .transform(value => (value === "" ? null : value))
      .nullable(),
  });

  const defaultValues = {
    first_name: "",
    last_name: "",
    is_superuser: false,
    username: "",
    is_change_password: false,
    password: "",
  };
  const formOptions = { resolver: yupResolver(validationSchema), defaultValues };
  const fileInputRef = useRef(null);
  const [isFilesUploading, setIsFilesUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(staffProfile.photo_details || {});
  const { register, clearErrors, handleSubmit, formState, setValue, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const isSuperuser = watch("is_superuser");
  const isChangePassword = watch("is_change_password");
  const username = watch("username");
  const firstName = watch("first_name");
  const lastName = watch("last_name");

  useEffect(() => {
    if (staffProfile.id) {
      setValue("first_name", staffProfile.first_name || "");
      setValue("last_name", staffProfile.last_name || "");
      setValue("username", staffProfile.username || "");
      setValue("is_superuser", staffProfile.is_superuser || false);
      setValue("is_change_password", false);
      setValue("password", null);

      if (staffProfile.photo_details) {
        setUploadedFile(staffProfile.photo_details);
      }
    }
  }, [staffProfile, setValue]);

  const handleFileChange = async event => {
    const selectedFile = Array.from(event.target.files)[0];

    if (!selectedFile) return;

    setIsFilesUploading(true);

    try {
      const uploadedFiles = await uploadPublicFiles([selectedFile]);
      setUploadedFile(uploadedFiles[0] || {});

      console.log("Uploaded file IDs:", uploadedFiles);
    } catch (error) {
      console.error("Error uploading files:", error);

      return;
    } finally {
      setIsFilesUploading(false);
    }

    setIsFilesUploading(false);
  };

  const handleRemoveFile = () => {
    setUploadedFile({});
  };

  const onSubmit = async ({ first_name, last_name, username, password, is_superuser }) => {
    const staffData = {
      first_name,
      last_name,
      is_superuser,
      photo: uploadedFile.id || null,
      username,
      password,
    };

    if (staffProfile.id) {
      await dispatch(updateStaffProfile({ id: staffProfile.id, ...staffData }));
      await dispatch(getStaffProfile(staffProfile.id));
    } else {
      await dispatch(addStaffProfile(staffData));

      history.navigate("/staff");
    }
  };

  useEffect(() => {
    const generateUsername = async () => {
      if (!staffProfile.id && firstName && lastName) {
        try {
          const username = await generateCustomUserName({ firstName, lastName });
          setValue("username", username);
          clearErrors("username");
        } catch (error) {}
      }
    };

    generateUsername();
  }, [staffProfile.id, firstName, lastName]);

  return (
    <>
      <div className={`${staffProfile?.id ? "col-lg-6" : ""}`}>
        <div className="rounded shadow mt-4">
          <div className="p-4 border-bottom">
            <h6 className="mb-0">Personal Staff Information:</h6>
          </div>
          <div className="p-4">
            <form className="" onSubmit={handleSubmit(onSubmit)}>
              <div className="row align-items-center mb-4">
                <div className="col-lg-2 col-md-4 position-relative">
                  <>
                    {isFilesUploading && (
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          background: "#f8f9fa",
                          borderRadius: "5px",
                        }}
                      >
                        <Spinner
                          as="span"
                          variant="primary"
                          animation="border"
                          size="lg"
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                    <ImageLoader
                      src={
                        isFilesUploading
                          ? ""
                          : uploadedFile.safe_url
                            ? `${uploadedFile.safe_url}?x=80`
                            : blankImagePath
                      }
                      className="avatar avatar-md-md rounded-pill shadow mx-auto d-block"
                      alt=""
                    />
                  </>
                </div>
                {isCanEdit && (
                  <>
                    <div className="col-lg-5 col-md-8 text-center text-md-start mt-4 mt-sm-0">
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept="image/jpeg, image/png"
                        onClick={e => (e.target.value = null)}
                        onChange={handleFileChange}
                      />
                      <h5 className="">Upload your picture</h5>
                      <p className="text-muted mb-0">
                        For best results, use an image at least 600px by 600px in either .jpg or
                        .png format
                      </p>
                    </div>

                    <div className="col-lg-5 col-md-12 text-lg-end text-center mt-4 mt-lg-0">
                      <Button
                        disabled={isFilesUploading}
                        className="btn btn-primary"
                        onClick={() => fileInputRef.current.click()}
                      >
                        {isFilesUploading ? "Uploading..." : "Upload"}
                      </Button>
                      <Button
                        className="btn btn-soft-primary ms-2"
                        disabled={isFilesUploading}
                        onClick={handleRemoveFile}
                      >
                        Remove
                      </Button>
                    </div>
                  </>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">{`First Name${checkIfRequired("first_name", validationSchema)}`}</label>
                    <input
                      name="first_name"
                      id="first_name"
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.first_name,
                      })}
                      disabled={!isCanEdit}
                      placeholder="First Name"
                      {...register("first_name")}
                      onChange={e => {
                        const cleanedValue = e.target.value.replace(/[^A-Za-z]/g, "");
                        setValue("first_name", cleanedValue, { shouldDirty: true });
                      }}
                    />
                    <div className="invalid-feedback">{errors.first_name?.message}</div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">{`Last Name${checkIfRequired("last_name", validationSchema)}`}</label>
                    <input
                      name="last_name"
                      id="last_name"
                      type="text"
                      disabled={!isCanEdit}
                      className={classNames("form-control", {
                        "is-invalid": errors.last_name,
                      })}
                      placeholder="Last Name"
                      {...register("last_name")}
                      onChange={e => {
                        const cleanedValue = e.target.value.replace(/[^A-Za-z]/g, "");
                        setValue("last_name", cleanedValue, { shouldDirty: true });
                      }}
                    />
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">{`Username`}</label>
                      <DebounceInput
                        name="username"
                        id="username"
                        type="text"
                        disabled={!isCanEdit || !isCanEditUsername}
                        debounceTimeout={700}
                        value={username || ""}
                        className={classNames("form-control", {
                          "is-invalid": errors.username,
                        })}
                        placeholder="Username"
                        {...register("username")}
                        onChange={e => {
                          setValue("username", e.target.value, { shouldValidate: true });
                        }}
                      />
                      <div className="invalid-feedback">{errors.username?.message}</div>
                    </div>
                  </div>
                </div>
                {isCanEdit && isCanEditUsername && (
                  <div className="row">
                    <div className="form-check">
                      <div className="px-3">
                        <input
                          className={classNames("form-check-input", {
                            "is-invalid": errors.is_change_password,
                          })}
                          type="checkbox"
                          checked={isChangePassword}
                          id="is_change_password"
                          {...register("is_change_password")}
                        />
                        <label className="form-check-label" htmlFor="is_change_password">
                          {`Is set new password?`}
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                {isChangePassword && (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{`Password`}</label>
                        <input
                          name="password"
                          id="password"
                          type="text"
                          className={classNames("form-control", {
                            "is-invalid": errors.password,
                          })}
                          placeholder="Password"
                          {...register("password")}
                        />
                        <div className="invalid-feedback">{errors.password?.message}</div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="form-check">
                    <div className="px-3">
                      <input
                        className={classNames("form-check-input", {
                          "is-invalid": errors.is_superuser,
                        })}
                        type="checkbox"
                        disabled={!isCanEdit || !isCanEditUsername || isDisableDeleteButton}
                        checked={isSuperuser}
                        id="is_s"
                        {...register("is_superuser")}
                      />
                      <label className="form-check-label" htmlFor="is_superuser">
                        {`Is superuser?`}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {isCanEdit && (
                <button disabled={isSubmitting || isFilesUploading} className="btn btn-primary">
                  {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                  {buttonText}
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
      {staffProfile?.id && (
        <div className="col-lg-6">
          <div className="rounded shadow mt-4">
            <div className="p-4 border-bottom">
              <h6 className="mb-0">General Notifications :</h6>
            </div>
            {isCanDelete && (
              <div className="p-4">
                <div className="p-4 border-bottom">
                  <h5 className="mb-0 text-danger">Delete Account :</h5>
                </div>

                <div className="p-4">
                  <h6 className="mb-0 fw-normal">
                    {isDisableDeleteButton
                      ? "You can't delete your own account"
                      : 'Do you want to delete the account? Please press below "Delete" button'}
                  </h6>
                  <div className="mt-4">
                    <button
                      className="btn btn-danger"
                      onClick={handleDelete}
                      disabled={isDisableDeleteButton}
                    >
                      Delete Account
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default StaffEditForm;
