import Modal from "react-bootstrap/Modal";
import React from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import FileLoader from "../FileLoader/FileLoader";

const FeedbackInfoModal = ({ isShowModal, handleCloseModal, infoData }) => {
  return (
    <>
      {infoData && (
        <Modal show={isShowModal} onHide={() => handleCloseModal()} centered>
          <Modal.Header closeButton>
            <h5 className="modal-title" id="exampleModalLabel1">
              Feedback
            </h5>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center">
              <h5 className="mb-0">{`From ${infoData?.patient_detail?.first_name} ${infoData?.patient_detail?.last_name} (${moment(infoData.created_at).format("YYYY-MM-DD")}: ${moment(infoData.created_at).format("HH:mm")})`}</h5>
            </div>
            <ul className="list-unstyled mb-0 d-md-flex flex-column mt-4">
              <li>
                <div>{infoData.text}</div>
              </li>
              {infoData?.files_detail.length ? (
                <li>
                  <div className="mt-2 mb-2 text-muted">Files:</div>
                  <div className="d-flex g-1">
                    {infoData?.files_detail.map((item, index) => {
                      const attachmentUrl = item.safe_url;

                      return (
                        <Link
                          to={attachmentUrl}
                          key={index}
                          className="position-relative m-2"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FileLoader
                            item={item}
                            isShowRemoveButton={false}
                            width={70}
                            height={70}
                            fileSize={70}
                          />
                        </Link>
                      );
                    })}
                  </div>
                </li>
              ) : null}
            </ul>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default FeedbackInfoModal;
