export const DB_NAME = {
  chatDB: "ChatDB",
  chatsListDB: "ChatsListDB",
};
export const STORE_NAME = {
  messages: "messages",
  chatsList: "chatsList",
};
export const KEY_PATH = {
  chatId: "chatId",
  chatsList: "chatsList",
};

const openIndexDB = ({ dbName, storeName, keyPath }) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = event => {
      const db = event.target.result;

      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath });
      }
    };

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

export const getDataFromIndexDB = async ({ dbName, storeName, keyPath, requestSelector }) => {
  const db = await openIndexDB({ dbName, storeName, keyPath });

  return new Promise((resolve, reject) => {
    const tx = db.transaction(storeName, "readonly");
    const store = tx.objectStore(storeName);
    const request = store.get(requestSelector);

    request.onsuccess = () => resolve(request.result?.[storeName] || []);
    request.onerror = () => reject(request.error);
  });
};

export const saveDataToIndexDB = async ({ dbName, storeName, keyPath, requestSelector, data }) => {
  const db = await openIndexDB({ dbName, storeName, keyPath });

  const validData = data.filter(el => el.id);

  if (validData.length === 0) return;

  return new Promise((resolve, reject) => {
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);

    const deleteRequest = store.delete(requestSelector);

    deleteRequest.onsuccess = () => {
      const putRequest = store.put({
        [keyPath]: requestSelector,
        [storeName]: validData,
      });

      putRequest.onsuccess = () => resolve();
      putRequest.onerror = () => reject(putRequest.error);
    };

    deleteRequest.onerror = () => reject(deleteRequest.error);
  });
};
