import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as doctorActions from "../../store/actions/doctor";
import { submitEventForm } from "../../store/actions/calendar";
import moment from "moment/moment";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import CustomAsyncSelect from "../Select/CustomAsyncSelect";
import { getDoctorProfileDetails } from "../../api/doctor";
import { getPatientProfileDetails } from "../../api/patient";
import { getServices } from "../../store/actions/services";
import PopoutSelect from "../Select/PopoutSelect";
import DatePicker from "react-datepicker";
import { FaRegCalendarAlt } from "react-icons/fa";
import StatusSelect from "../Select/StatusSelect";
import { status } from "../../config/settings";
import { uploadPatientFiles } from "../../api/files";
import { FiPaperclip } from "react-icons/fi";
import { Link } from "react-router-dom";
import FileLoader from "../FileLoader/FileLoader";
import {
  getAsyncSelectOption,
  getDoctorsData,
  getPatientsData,
} from "../../utils/getAsyncSelectOptions";
import TreatmentStageFieldBlock from "./TreatmentStageFieldBlock";
import { getTreatmentPlanDetailsApi, getTreatmentStageDetailsApi } from "../../api/treatments";
import { showToast } from "../../utils/toasts";
import MedicalCheckoutEditForm from "./MedicalCheckoutEditForm";
import { IoMdCheckboxOutline } from "react-icons/io";
import ShortInfoBody from "../partients/ShortInfoBody";

const EventEditForm = ({
  isShowEditForm,
  handleClose,
  handleDelete,
  setValuesObject,
  isDisableChangePatient = false,
  isTreatmentStage = false,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialDoctorOption, setInitialDoctorOption] = useState(null);
  const [initialPatientOption, setInitialPatientOption] = useState(null);
  const [initialStageOption, setInitialStageOption] = useState(null);
  const [initialTreatmentPlanOption, setInitialTreatmentPlanOption] = useState(null);
  const [currentSetValuesObject, setCurrentSetValuesObject] = useState(null);
  const [currentDoctorKey, setCurrentDoctorKey] = useState(null);
  const [isShowEditCheckoutModal, setIsShowEditCheckoutModal] = useState(false);
  const [delayedShow, setDelayedShow] = useState(false);
  const services = useSelector(s => s.services.list);
  const eventItem = useSelector(s => s.calendar.dialog.selected);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    doctor: Yup.string().required("Doctor is required"),
    patient_id: Yup.string().required("Patient is required"),
    entry_type: Yup.string(),
    entry_services: Yup.array()
      .min(1, "At least one service must be selected")
      .required("At least one service must be selected"),
    start: Yup.string()
      .required("Start is required")
      .test("is-future-start", "Start time cannot be in the past", function (value) {
        const currentTime = moment();
        const newStartTime = moment(value);

        if (moment(eventItem?.start) && moment(eventItem?.start).isSame(newStartTime)) {
          return true;
        }

        return newStartTime.isSameOrAfter(currentTime);
      }),
    end: Yup.string()
      .required("End is required")
      .test("is-future-end", "End time cannot be in the past", function (value) {
        const currentTime = moment();
        const newEndTime = moment(value);

        if (moment(eventItem?.end) && moment(eventItem?.end).isSame(newEndTime)) {
          return true;
        }

        return newEndTime.isSameOrAfter(currentTime);
      })
      .test("is-after-start", "End time must be after start time", function (value) {
        const start = this.parent.start;

        return moment(value).isAfter(moment(start));
      }),
    comment: Yup.string(),
    treatment_stage: Yup.string().nullable(),
    treatment_plan: Yup.string().nullable(),
    isTreatmentStage: Yup.boolean(),
  });
  const defaultValues = {
    doctor: "",
    patient_id: "",
    entry_type: "APPOINTMENT",
    start: "",
    end: "",
    comment: "",
    entry_services: [],
    status: "ACTIVE",
    treatment_stage: null,
    treatment_plan: null,
    isTreatmentStage: false,
  };
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const isSentRequestForTreatmentsData = useRef(false);

  useEffect(() => {
    let timer;

    if (isShowEditCheckoutModal) {
      timer = setTimeout(() => {
        setDelayedShow(true);
      }, 200);
    } else {
      setDelayedShow(false);
    }

    return () => clearTimeout(timer);
  }, [isShowEditCheckoutModal]);

  const handleFileChange = async event => {
    const selectedFiles = Array.from(event.target.files);

    const newFiles = selectedFiles.filter(
      file => !uploadedFiles.some(existingFile => existingFile?.file?.name === file.name),
    );

    if (newFiles.length === 0) {
      return;
    }

    try {
      setIsUploadingFiles(true);
      const newUploadedFiles = await uploadPatientFiles(
        newFiles,
        isEditMode ? eventItem.patient.id : undefined,
      );
      setUploadedFiles(prevFiles => [...prevFiles, ...newUploadedFiles]);
      console.log("Uploaded file IDs:", newUploadedFiles);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setIsUploadingFiles(false);
    }
  };

  const removeFile = (e, id) => {
    e.preventDefault();
    const currentFile = uploadedFiles.find(file => file.id === id);
    dispatch(
      showSubmitConfirmation({
        message: `Are you sure you want to delete file: ${currentFile.filename}?`,
        onConfirm: () => {
          setUploadedFiles(prevFiles => prevFiles.filter(file => file.id !== id));
        },
      }),
    );
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    shouldUnregister: false,
  };
  const methods = useForm(formOptions);
  const { register, trigger, handleSubmit, formState, setValue, reset, control, watch } = methods;
  const { errors, isSubmitting } = formState;

  const fetchInitialDoctorOption = useCallback(
    async doctorId => {
      if (doctorId || (isEditMode && eventItem?.doctor) || setValuesObject?.doctor) {
        try {
          const result = await getDoctorProfileDetails({
            id: doctorId || eventItem?.doctor || setValuesObject?.doctor,
          });
          setInitialDoctorOption(getAsyncSelectOption(result.data));
        } catch (error) {
          console.error("Error to load initialDoctorOption:", error);
        }
      }
    },
    [eventItem?.doctor, isEditMode, setValuesObject?.doctor],
  );

  const fetchPatientOption = useCallback(async () => {
    if (setValuesObject?.patient_id) {
      try {
        const result = await getPatientProfileDetails({ id: setValuesObject.patient_id });

        setInitialPatientOption(getAsyncSelectOption(result.data));
      } catch (error) {
        console.error("Error to load setInitialPatientOption:", error);
      }
    }
  }, [setValuesObject?.patient_id]);

  useEffect(() => {
    if (isShowEditForm) {
      dispatch(doctorActions.getDoctorsList({ isWithQueries: false }));
      dispatch(getServices({ is_active: 1 }));
    }
  }, [dispatch, isShowEditForm]);

  const getAndSetInitialTreatmentStageOption = async treatmentStageId => {
    const treatmentStageData = await getTreatmentStageDetailsApi(treatmentStageId);
    setInitialStageOption({
      value: treatmentStageData.data.id,
      label: treatmentStageData.data.name,
    });

    return treatmentStageData.data;
  };

  const getAndSetInitialTreatmentPlanOption = async treatmentPlanId => {
    const treatmentPlanData = await getTreatmentPlanDetailsApi(treatmentPlanId);
    setInitialTreatmentPlanOption({
      value: treatmentPlanData.data.id,
      label: treatmentPlanData.data.name,
    });
    setValue("treatment_plan", treatmentPlanData.data.id);

    return treatmentPlanData.data;
  };

  const getTreatmentPlan = async treatmentStageId => {
    if (!isSentRequestForTreatmentsData.current) {
      isSentRequestForTreatmentsData.current = true;
      const treatmentStageData = await getAndSetInitialTreatmentStageOption(treatmentStageId);
      await getAndSetInitialTreatmentPlanOption(treatmentStageData.plan);
      isSentRequestForTreatmentsData.current = false;
    }
  };

  function roundUpToNearest15(date) {
    const minutes = moment(date).minutes();
    const remainder = minutes % 30;
    const additionalMinutes = remainder === 0 ? 0 : 30 - remainder;

    return moment(date).add(additionalMinutes, "minutes").seconds(0).milliseconds(0);
  }

  useEffect(() => {
    if (eventItem?.id) {
      reset({
        doctor: eventItem?.doctor || "",
        patient_id: eventItem?.patient?.id || "",
        entry_type: eventItem?.entry_type || "APPOINTMENT",
        start: eventItem?.start || "",
        end: eventItem?.end || "",
        comment: eventItem?.comment || "",
        entry_services: eventItem?.entry_services || [],
        status: eventItem?.status || "ACTIVE",
        treatment_stage: eventItem?.treatment_stage || null,
      });

      if (eventItem?.treatment_stage && !initialStageOption) {
        getTreatmentPlan(eventItem?.treatment_stage);
        setValue("isTreatmentStage", true);
      }

      if (eventItem.files) {
        setUploadedFiles(eventItem.files);
      }

      setIsEditMode(true);
    } else {
      setValue("entry_type", "APPOINTMENT", {
        shouldDirty: false,
      });

      if (eventItem) {
        const diff = moment(eventItem?.end).diff(moment(eventItem?.start));
        const startDate = moment(eventItem?.start).isBefore(moment().toISOString())
          ? roundUpToNearest15(moment())
          : eventItem?.start;
        setValue("start", startDate, {
          shouldDirty: false,
        });
        setValue("end", moment(startDate).add(diff), {
          shouldDirty: false,
        });
      } else {
        setValue("start", roundUpToNearest15(moment()).toISOString(), {
          shouldDirty: false,
        });
        setValue("end", roundUpToNearest15(moment()).add(30, "minutes").toISOString(), {
          shouldDirty: false,
        });
      }

      if (setValuesObject && !isEqual(currentSetValuesObject, setValuesObject)) {
        Object.entries(setValuesObject).forEach(([key, value]) => {
          setValue(key, value, {
            shouldDirty: false,
          });
        });

        setCurrentSetValuesObject(setValuesObject);

        if (setValuesObject?.treatment_stage) {
          getTreatmentPlan(setValuesObject?.treatment_stage);
          setValue("isTreatmentStage", true);
        }
      }

      setIsEditMode(false);
    }
  }, [eventItem, reset, setValuesObject]);

  useEffect(() => {
    if (!isShowEditForm) {
      setUploadedFiles([]);
    }
  }, [isShowEditForm]);

  const handleCloseEvents = () => {
    setInitialDoctorOption(null);
    setInitialPatientOption(null);
    setInitialStageOption(null);
    setInitialTreatmentPlanOption(null);
    setCurrentSetValuesObject(null);
    reset(defaultValues);
  };

  const onSubmit = ({
    doctor,
    patient_id,
    entry_type,
    start,
    end,
    comment,
    entry_services,
    status,
    treatment_stage,
  }) => {
    return dispatch(
      submitEventForm({
        id: eventItem?.id || null,
        eventData: {
          doctor,
          patient_id,
          entry_type,
          start: moment(start).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          end: moment(end).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          comment,
          entry_services,
          status,
          file_ids: uploadedFiles.map(file => file.id),
          treatment_stage,
        },
      }),
    ).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        handleClose();
        handleCloseEvents();
      } else {
        console.error("Submission failed:", response.payload);
      }
    });
  };

  const handleFormClose = () => {
    const isFormDirty =
      formState.isDirty || Object.values(formState.dirtyFields || {}).some(value => value);

    if (isFormDirty) {
      dispatch(
        showSubmitConfirmation({
          message: "Are you sure you want to exit? All unsaved data will be lost.",
          onConfirm: () => {
            handleClose();
            handleCloseEvents();
          },
        }),
      );
    }

    if (!isFormDirty) {
      handleClose();
      handleCloseEvents();
    }
  };

  const handleFormDelete = () => {
    dispatch(
      showSubmitConfirmation({
        message: "Are you sure you want to delete event?",
        onConfirm: () => {
          handleDelete(eventItem.id);
          handleCloseEvents();
        },
      }),
    );
  };

  const handlePatientChange = () => {
    setValue("treatment_stage", null);
    setValue("treatment_plan", null);
    setInitialStageOption(null);
    setInitialTreatmentPlanOption(null);
  };

  const patientId = watch("patient_id");

  const ts = watch("treatment_stage");

  const tp = watch("treatment_plan");

  const doctorField = watch("doctor");

  const start = watch("start");

  const end = watch("end");

  const onClickTreatmentButtonHandler = async () => {
    if (watch("isTreatmentStage")) {
      setValue("treatment_stage", defaultValues.treatment_stage);
      setValue("treatment_plan", defaultValues.treatment_plan);
      setInitialStageOption(null);
      setInitialTreatmentPlanOption(null);
      setValue("isTreatmentStage", false);
    } else {
      setValue("isTreatmentStage", true);

      if (
        (eventItem?.treatment_stage || setValuesObject?.treatment_stage) &&
        (eventItem?.patient?.id === patientId || setValuesObject.patient_id === patientId)
      ) {
        getTreatmentPlan(eventItem?.treatment_stage || setValuesObject?.treatment_stage);
        setValue("treatment_stage", eventItem?.treatment_stage || setValuesObject?.treatment_stage);

        if (doctorField) {
          try {
            const result = await getDoctorProfileDetails({
              id: doctorField,
            });

            if (!result.data.can_participate_in_treatment_plans) {
              setValue("doctor", null);
              setInitialDoctorOption(null);
              showToast({
                message:
                  "Selected doctor can't participate in treatment plan. Please select other doctor",
                type: "warning",
              });
            }
          } catch (error) {
            console.error("Error to load DoctorData:", error);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (ts && !initialStageOption) {
      getAndSetInitialTreatmentStageOption(ts);
    }

    if (tp && !initialTreatmentPlanOption) {
      getAndSetInitialTreatmentPlanOption(tp);
    }

    if (doctorField && !initialDoctorOption) {
      fetchInitialDoctorOption(doctorField);
    }

    if (patientId && !initialPatientOption) {
      fetchPatientOption();
    }
  }, [
    doctorField,
    fetchInitialDoctorOption,
    fetchPatientOption,
    getAndSetInitialTreatmentPlanOption,
    initialDoctorOption,
    initialPatientOption,
    initialStageOption,
    initialTreatmentPlanOption,
    patientId,
    tp,
    ts,
  ]);

  useEffect(() => {
    const keyForSet = `${tp ? tp : ""}`;

    if (currentDoctorKey !== keyForSet) {
      setCurrentDoctorKey(keyForSet);
    }
  }, [currentDoctorKey, tp]);

  const generateDisabledTimesForToday = currentTime => {
    const disabledTimes = [];
    const startOfDay = moment().startOf("day");

    while (startOfDay.isBefore(currentTime)) {
      disabledTimes.push(startOfDay.clone().toDate());
      startOfDay.add(30, "minutes");
    }

    return disabledTimes;
  };

  const generateAllTimesForDay = () => {
    const disabledTimes = [];
    const startOfDay = moment().startOf("day");
    const endOfDay = moment().endOf("day");

    while (startOfDay.isBefore(endOfDay)) {
      disabledTimes.push(startOfDay.clone().toDate());
      startOfDay.add(30, "minutes");
    }

    return disabledTimes;
  };

  useEffect(() => {
    if (isShowEditForm && !start && !eventItem) {
      setValue("start", roundUpToNearest15(moment()).toISOString(), {
        shouldDirty: false,
      });
      setValue("end", roundUpToNearest15(moment()).add(30, "minutes").toISOString(), {
        shouldDirty: false,
      });
    }
  }, [start, eventItem, isShowEditForm, setValue]);

  return (
    <div>
      <Offcanvas
        show={isShowEditForm}
        onHide={() => {
          if (isEditMode) {
            handleFormClose();
          }
        }}
        backdrop={isEditMode}
        keyboard={false}
        placement="end"
        className={classNames("offcanvasEventEditForm", {
          moveRight: isShowEditCheckoutModal,
        })}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>{`${isEditMode ? "Edit" : "Create"} Form`}</Offcanvas.Title>
          <div className="d-flex gap-1">
            {isEditMode && (
              <Button variant="danger" onClick={handleFormDelete}>
                Delete
              </Button>
            )}
            <Button variant="secondary" onClick={handleFormClose}>
              Close
            </Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FormProvider {...methods}>
            <form className="login-form mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-12"></div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Doctor</label>
                    <CustomAsyncSelect
                      name="doctor"
                      placeholder="Select doctor"
                      control={control}
                      getOptions={queryObj => {
                        const currentQueryObj =
                          isTreatmentStage || tp
                            ? { ...queryObj, can_participate_in_treatment_plans: 1 }
                            : queryObj;

                        return getDoctorsData(currentQueryObj);
                      }}
                      initialOption={initialDoctorOption}
                      limit={10}
                      className={classNames("form-control", "p-0", {
                        "is-invalid": errors.doctor,
                      })}
                      currentKey={currentDoctorKey}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Patient</label>
                    <CustomAsyncSelect
                      name="patient_id"
                      placeholder="Select patient"
                      control={control}
                      isDisabled={isDisableChangePatient}
                      getOptions={getPatientsData}
                      {...(isEditMode && {
                        initialOption: {
                          value: eventItem?.patient?.id,
                          label: `${eventItem?.patient?.first_name} ${eventItem?.patient?.last_name}`,
                          data: eventItem?.patient,
                        },
                      })}
                      {...(setValuesObject?.patient_id && {
                        initialOption: initialPatientOption,
                      })}
                      limit={10}
                      className={classNames("form-control", "p-0", {
                        "is-invalid": errors.patient_id,
                      })}
                      customOnChange={handlePatientChange}
                      renderTooltip={data => (
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <ShortInfoBody shortInfoPatientData={data?.data} />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Services</label>
                    <PopoutSelect
                      name="entry_services"
                      placeholder="Select services"
                      control={control}
                      optionsData={services}
                      className={classNames(
                        "form-control",
                        "form-control_transparent-border",
                        "p-0",
                        {
                          "is-invalid": errors.entry_services,
                        },
                      )}
                      {...((isEditMode || setValuesObject?.entry_services) && {
                        initialValue: eventItem?.entry_services || setValuesObject?.entry_services,
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Date & Time</label>
                    <div className="d-flex gap-2">
                      <DatePicker
                        showIcon
                        closeOnScroll={true}
                        showYearDropdown
                        yearDropdownItemNumber={10}
                        scrollableYearDropdown
                        minDate={moment().toDate()}
                        maxDate={moment().add(10, "years").toDate()}
                        icon={<FaRegCalendarAlt />}
                        selected={
                          start && moment(start, moment.ISO_8601, true).isValid()
                            ? moment.parseZone(start).toDate()
                            : null
                        }
                        onChange={date => {
                          const newDateData = {
                            year: moment(date).year(),
                            month: moment(date).month(),
                            date: moment(date).date(),
                          };
                          const currentStart = moment(start, moment.ISO_8601);
                          const currentEnd = moment(end, moment.ISO_8601);

                          if (!currentStart.isValid() || !currentEnd.isValid()) {
                            setValue("start", roundUpToNearest15(moment()).toISOString(), {
                              shouldDirty: false,
                            });
                            setValue(
                              "end",
                              roundUpToNearest15(moment()).add(30, "minutes").toISOString(),
                              {
                                shouldDirty: false,
                              },
                            );
                          }

                          setValue(
                            "start",
                            moment(start, moment.ISO_8601)
                              .set(newDateData)
                              .format("YYYY-MM-DDTHH:mm:ssZ"),
                            {
                              shouldDirty: true,
                            },
                          );
                          setValue(
                            "end",
                            moment(end, moment.ISO_8601)
                              .set(newDateData)
                              .format("YYYY-MM-DDTHH:mm:ssZ"),
                            {
                              shouldDirty: true,
                            },
                          );

                          trigger("start");
                          trigger("end");
                        }}
                        dateFormat="dd.MM.yyyy"
                        placeholderText="Select date"
                        className={classNames("form-control", {
                          "is-invalid": errors.start,
                        })}
                      />
                      <div className="d-flex align-items-center">
                        <span className="text-muted">{"from: "}</span>
                        <DatePicker
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          selected={
                            start && moment(start, moment.ISO_8601, true).isValid()
                              ? moment.parseZone(start).toDate()
                              : null
                          }
                          onChange={time => {
                            const currentStart = moment(start, moment.ISO_8601);
                            const currentEnd = moment(end, moment.ISO_8601);

                            if (!currentStart.isValid() || !currentEnd.isValid()) return;

                            const diffInMinutes = currentEnd.diff(currentStart, "minutes");

                            const newStart = moment(start, moment.ISO_8601).set({
                              hour: moment(time).hour(),
                              minute: moment(time).minute(),
                            });

                            setValue("start", newStart.format("YYYY-MM-DDTHH:mm:ssZ"), {
                              shouldDirty: true,
                            });

                            if (diffInMinutes > 0) {
                              const newEnd = newStart.clone().add(diffInMinutes, "minutes");
                              setValue("end", newEnd.format("YYYY-MM-DDTHH:mm:ssZ"), {
                                shouldDirty: true,
                              });
                            }

                            trigger("start");
                            trigger("end");
                          }}
                          placeholderText="Select start time"
                          className={classNames("form-control", "d-inline-block", {
                            "is-invalid": errors.start,
                          })}
                          excludeTimes={
                            moment(start).isBefore(moment(), "day")
                              ? generateAllTimesForDay()
                              : moment(start).isSame(moment(), "day")
                                ? generateDisabledTimesForToday(moment())
                                : []
                          }
                          minTime={
                            moment(start).isSame(moment(), "day") &&
                            !moment(start).isBefore(moment(), "day")
                              ? moment().toDate()
                              : moment().startOf("day").toDate()
                          }
                          maxTime={moment().endOf("day").toDate()}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="text-muted">{"to: "}</span>
                        <DatePicker
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          selected={
                            end && moment(end, moment.ISO_8601, true).isValid()
                              ? moment.parseZone(end).toDate()
                              : null
                          }
                          onChange={time => {
                            setValue(
                              "end",
                              moment(end, moment.ISO_8601)
                                .set({
                                  hour: moment(time).hour(),
                                  minute: moment(time).minute(),
                                })
                                .format("YYYY-MM-DDTHH:mm:ssZ"),
                              {
                                shouldDirty: true,
                              },
                            );
                            trigger("end");
                          }}
                          placeholderText="Select end time"
                          className={classNames("form-control", "d-inline-block", {
                            "is-invalid": errors.end,
                          })}
                          excludeTimes={
                            moment(end).isBefore(moment(), "day")
                              ? generateAllTimesForDay()
                              : moment(end).isSame(moment(), "day")
                                ? generateDisabledTimesForToday(moment())
                                : []
                          }
                          minTime={
                            moment(end).isSame(moment(), "day") &&
                            !moment(end).isBefore(moment(), "day")
                              ? moment.max(moment(), moment(start).add(30, "minutes")).toDate()
                              : moment(start).add(30, "minutes").toDate()
                          }
                          maxTime={moment().endOf("day").toDate()}
                        />
                      </div>
                    </div>
                    {errors.start && (
                      <div className="invalid-feedback d-block">{errors.start.message}</div>
                    )}
                    {errors.end && (
                      <div className="invalid-feedback d-block">{errors.end.message}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Appointment status</label>
                    <StatusSelect
                      name="status"
                      placeholder="Select appointment status"
                      control={control}
                      statusFlags={status}
                      className={classNames("form-control", "p-0", {
                        "is-invalid": errors.status,
                      })}
                      statusKeys={["ACTIVE", "PENDING", "CANCELED", "COMPLETED"]}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label me-2">Attachments</label>
                    <label className="btn btn-icon btn-primary">
                      <input
                        type="file"
                        style={{ display: "none" }}
                        accept="image/jpeg, image/png, application/pdf"
                        multiple
                        onClick={e => (e.target.value = null)}
                        onChange={handleFileChange}
                      />
                      <FiPaperclip />
                    </label>
                    <div className="mb-2 d-flex flex-wrap">
                      {isUploadingFiles && <p style={{ width: "100%" }}>Uploading files...</p>}
                      {uploadedFiles.map((item, index) => {
                        const attachmentUrl = item.safe_url;

                        return (
                          <Link
                            to={attachmentUrl}
                            key={index}
                            className="position-relative m-2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FileLoader item={item} removeFile={removeFile} />
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="mb-3 form-group">
                  <TreatmentStageFieldBlock
                    control={control}
                    onClickTreatmentButtonHandler={onClickTreatmentButtonHandler}
                    initialStageOption={initialStageOption}
                    initialTreatmentPlanOption={initialTreatmentPlanOption}
                    setInitialStageOption={setInitialStageOption}
                    setInitialDoctorOption={setInitialDoctorOption}
                    className={classNames("form-control", "form-control_transparent-border", "p-0")}
                  />
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 form-group">
                    <label className="form-label">Comment</label>
                    <textarea
                      name="comment"
                      rows="3"
                      className={classNames("form-control", {
                        "is-invalid": errors.comment,
                      })}
                      placeholder="Comment"
                      {...register("comment")}
                    />
                    <div className="invalid-feedback">{errors.comment?.message}</div>
                  </div>
                </div>
                {isEditMode && (
                  <div className={classNames("mb-3 col-lg-5")}>
                    <div className="d-grid">
                      <Button
                        variant={`${eventItem?.checkout ? "success" : "light"}`}
                        onClick={() => setIsShowEditCheckoutModal(true)}
                        type="button"
                      >
                        {eventItem?.checkout ? (
                          <span className="d-flex align-items-center gap-2 justify-content-center">
                            <IoMdCheckboxOutline size="16" style={{ minWidth: 16 }} />
                            <span>Medical Checkout</span>
                          </span>
                        ) : (
                          "Add Medical Checkout"
                        )}
                      </Button>
                    </div>
                  </div>
                )}
                <div className="col-lg-12 mb-0">
                  <div className="d-grid">
                    <button
                      disabled={isSubmitting || isUploadingFiles}
                      className="btn btn-primary"
                      type="submit"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
          {isEditMode && (
            <MedicalCheckoutEditForm
              delayedShow={delayedShow}
              isShowEditCheckoutModal={isShowEditCheckoutModal}
              setIsShowEditCheckoutModal={setIsShowEditCheckoutModal}
              eventItem={eventItem}
              currentService={watch("entry_services")?.[0]}
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default EventEditForm;
