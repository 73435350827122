import { useEffect } from "react";
import { useSocket } from "./useSocket";
import { useSelector } from "react-redux";

export const usePingSocket = () => {
  const socket = useSocket();
  const userId = useSelector(s => s.auth.user?.userId);

  useEffect(() => {
    const timerId = setInterval(() => {
      const localMessagesList = JSON.parse(localStorage.getItem("chat_message"));
      const allChatMessageRead = JSON.parse(localStorage.getItem("allChatMessageRead"));

      if (
        (!Object.keys(localMessagesList || {}).length ||
          !Object.values(localMessagesList).some(message => {
            const messageTime = new Date(message.created_at).getTime();
            const oneHourAgo = Date.now() - 3600 * 1000;

            return messageTime >= oneHourAgo && message.userId === userId;
          })) &&
        !Object.keys(allChatMessageRead || {}).length
      ) {
        return;
      }

      if (navigator.onLine && socket && socket.readyState === WebSocket.OPEN) {
        const socketPingTimestamp = Date.now();
        localStorage.setItem("socketPingTimestamp", JSON.stringify(socketPingTimestamp));
        socket.send(
          JSON.stringify({
            type: "ping",
            timestamp: socketPingTimestamp,
          }),
        );
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [socket]);
};
