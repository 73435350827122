import { isRejected, isFulfilled } from "@reduxjs/toolkit";

// Utils
import { showToast } from "../../utils/toasts";

export const toastMiddleware = () => next => action => {
  if (isFulfilled(action) && action.payload?.successMessage) {
    showToast({ message: action.payload.successMessage, type: "success" });
  }

  if (isRejected(action) && action.payload?.errorMessage) {
    showToast({ message: action.payload.errorMessage, type: "error" });
  }

  return next(action);
};
