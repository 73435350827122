import React from "react";
import { Link } from "react-router-dom";
import blankImagePath from "../../assets/images/blank-image-icon.png";
import ImageLoader from "../FileLoader/ImageLoader";

const StaffItem = ({ item }) => {
  return (
    <Link to={`/staff/${item.id}`} className="col mt-4 d-flex">
      <div className="card team border-0 rounded shadow overflow-hidden flex-grow-1">
        <div
          className="team-img position-relative d-flex justify-content-center align-items-center"
          style={{ aspectRatio: "1" }}
        >
          <ImageLoader
            src={
              item?.photo_details?.safe_url
                ? `${item?.photo_details?.safe_url}?x=500&y=500`
                : blankImagePath
            }
            className="img-fluid"
            alt=""
          />
        </div>
        <div className="card-body content text-center">
          <p className="title text-dark h5 d-block mb-0">
            {`${item.first_name} ${item.last_name}`}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default StaffItem;
