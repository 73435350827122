import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import uaParserJs from "ua-parser-js";
import { ToastContainer } from "react-toastify";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/scss/bootstrap.scss";
import "./assets/css/materialdesignicons.min.css";
import Index from "./pages";
import Calendar from "./pages/apps/calendar";
import Login from "./pages/authentication/login";
import { setIsIOS } from "./store/reducers/mainSlice";
import { useDispatch } from "react-redux";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { history } from "./utils/history";
import DrProfile from "./pages/doctors/dr-profile";
import { useTerminationToken } from "./hooks/useTerminationToken";
import ConfirmationModal from "./components/ConfirmationModal/ConfirmationModal";
import Doctors from "./pages/doctors/doctors";
import AddDoctor from "./pages/doctors/add-doctor";
import Patients from "./pages/patients/patients";
import AddPatient from "./pages/patients/add-patient";
import PatientProfile from "./pages/patients/patient-profile";
import Services from "./pages/services/services";
import Chats from "./pages/apps/chat";
import Appointment from "./pages/appointment";
import Feedbacks from "./pages/feedbacks";
import Faqs from "./pages/content/faqs";
import Tips from "./pages/content/tips";
import { getUnreadMessagesCountThunk } from "./store/actions/chat";
import StaffPage from "./pages/staff/staff";
import StaffProfilePage from "./pages/staff/staff-profile";
import AddStaffPage from "./pages/staff/add-staff";
import { useGetUserData } from "./hooks/useGetUserData";
// import Treatments from "./pages/treatments";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const userAgent = uaParserJs(window.navigator.userAgent);
    const isIOS = userAgent?.os.name === "iOS";
    dispatch(setIsIOS(isIOS));
    dispatch(getUnreadMessagesCountThunk());
  }, [dispatch]);

  useTerminationToken();
  useGetUserData();

  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Web site created using create-react-app" />
        <link
          href="https://cdn.jsdelivr.net/npm/quill@2.0.3/dist/quill.snow.css"
          rel="stylesheet"
        />
      </Helmet>
      <ConfirmationModal />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Index />} />
          <Route path="/index" element={<Index />} />
          {/*<Route path="/index-sidebar-dark" element={<IndexDarkSidebar />} />*/}
          {/*<Route path="/index-sidebar-colored" element={<IndexSidebarColored />} />*/}
          <Route path="/appointment" element={<Appointment />} />
          <Route path="/doctors" element={<Doctors />} />
          <Route path="/doctors/:id" element={<DrProfile />} />
          <Route path="/add-doctor" element={<AddDoctor />} />
          <Route path="/staff" element={<StaffPage />} />
          <Route path="/staff/:id" element={<StaffProfilePage />} />
          <Route path="/add-staff" element={<AddStaffPage />} />
          {/*<Route path="/dr-profile" element={<DrProfile />} />*/}
          {/*<Route path="/dr-profile/:id" element={<DrProfile />} />*/}
          <Route path="/patients" element={<Patients />} />
          <Route path="/patients/:id" element={<PatientProfile />} />
          <Route path="/add-patient" element={<AddPatient />} />
          <Route path="/services" element={<Services />} />
          {/*<Route path="/patient-profile" element={<PatientProfile />} />*/}
          <Route path="/chat" element={<Chats />} />
          {/*<Route path="/email" element={<Email />} />*/}
          <Route path="/calendar" element={<Calendar />} />
          {/*<Route path="/shop" element={<Shop />} />*/}
          {/*<Route path="/product-detail" element={<ProductDetail />} />*/}
          {/*<Route path="/product-detail/:id" element={<ProductDetail />} />*/}
          {/*<Route path="/shopcart" element={<ShopCart />} />*/}
          {/*<Route path="/checkout" element={<Checkout />} />*/}
          {/*<Route path="/blogs" element={<Blogs />} />*/}
          {/*<Route path="/blog-detail" element={<BlogDetail />} />*/}
          {/*<Route path="/blog-detail/:id" element={<BlogDetail />} />*/}
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/tips" element={<Tips />} />
          <Route path="/feedbacks" element={<Feedbacks />} />
          {/*<Route path="/treatments" element={<Treatments />} />*/}
          {/*<Route path="/invoice-list" element={<InvoiceList />} />*/}
          {/*<Route path="/invoice" element={<Invoice />} />*/}
          {/*<Route path="/terms" element={<Terms />} />*/}
          {/*<Route path="/privacy" element={<Privacy />} />*/}
          {/*<Route path="/blank-page" element={<BlankPage />} />*/}
          {/*<Route path="/email-confirmation" element={<EmailConfirmation />} />*/}
          {/*<Route path="/email-password-reset" element={<EmailPasswordReset />} />*/}
          {/*<Route path="/email-alert" element={<EmailAlert />} />*/}
          {/*<Route path="/email-invoice" element={<EmailInvoice />} />*/}
          {/*<Route path="/signup" element={<Signup />} />*/}
          {/*<Route path="/forgot-password" element={<ForgotPassword />} />*/}
          {/*<Route path="/lock-screen" element={<LockScreen />} />*/}
          {/*<Route path="/ui-button" element={<UiButton />} />*/}
          {/*<Route path="/ui-badges" element={<UiBadges />} />*/}
          {/*<Route path="/ui-alert" element={<UiAlert />} />*/}
          {/*<Route path="/ui-dropdown" element={<UiDropDown />} />*/}
          {/*<Route path="/ui-typography" element={<UiTypography />} />*/}
          {/*<Route path="/ui-background" element={<UiBackground />} />*/}
          {/*<Route path="/ui-text" element={<UiText />} />*/}
          {/*<Route path="/ui-tooltip-popover" element={<UiTooltipPopover />} />*/}
          {/*<Route path="/ui-shadow" element={<UiShadow />} />*/}
          {/*<Route path="/ui-border" element={<UiBorder />} />*/}
          {/*<Route path="/ui-form" element={<UiForm />} />*/}
          {/*<Route path="/ui-pagination" element={<UiPagination />} />*/}
          {/*<Route path="/ui-avatar" element={<UiAvatar />} />*/}
          {/*<Route path="/ui-modals" element={<UiModals />} />*/}
          {/*<Route path="/ui-icons" element={<UiIcon />} />*/}
          {/*<Route path="/comingsoon" element={<Comingsoon />} />*/}
          {/*<Route path="/maintenance" element={<Maintenance />} />*/}
          {/*<Route path="/error" element={<Error />} />*/}
          {/*<Route path="*" element={<Error />} />*/}
          {/*<Route path="thankyou" element={<ThankYou />} />*/}
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
