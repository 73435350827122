import { adminClient } from "../utils/axios";
import query from "querystringify";
import { getIfExist } from "../utils/getIfExist";

export const getFeedbacksList = queryObj => {
  return adminClient.get(`admin/feedback/${query.stringify(getIfExist(queryObj), true)}`);
};

export const removeFeedbackItem = id => {
  return adminClient.delete(`admin/feedback/${id}/`);
};
