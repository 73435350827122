import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteService, getServicesList, postService, putService } from "../../api/services";
import { servicesSliceName } from "../reducers/servicesSlice";
import {
  addMedicalCheckoutServiceApi,
  deleteMedicalCheckoutServiceApi,
  updateMedicalCheckoutServiceApi,
} from "../../api/medicalCheckout";

export const getServices = createAsyncThunk(
  `${servicesSliceName}/list`,
  async (queryObj, thunkAPI) => {
    try {
      const response = await getServicesList(queryObj);

      const sortedServices = [...response.data].sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) return -1;

        if (nameA > nameB) return 1;

        return 0;
      });

      return {
        data: sortedServices,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const addService = createAsyncThunk(
  `${servicesSliceName}/add`,
  async ({ name, description, price, duration_minutes, is_active, code }, thunkAPI) => {
    try {
      const response = await postService({
        serviceData: { name, description, price, duration_minutes, is_active, code },
      });

      return {
        data: response.data,
        successMessage: "Service added successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to add service" });
    }
  },
);

export const updateService = createAsyncThunk(
  `${servicesSliceName}/update`,
  async ({ name, description, price, duration_minutes, is_active, id, code }, thunkAPI) => {
    try {
      const response = await putService({
        serviceData: { name, description, price, duration_minutes, is_active, id, code },
      });

      return {
        data: response.data,
        successMessage: "Service updated successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to update service" });
    }
  },
);

export const removeService = createAsyncThunk(
  `${servicesSliceName}/remove`,
  async ({ id }, thunkAPI) => {
    try {
      const response = await deleteService({
        serviceData: { id },
      });

      return {
        data: response.data,
        successMessage: "Service removed successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to remove service" });
    }
  },
);

export const addMedicalCheckoutService = createAsyncThunk(
  `${servicesSliceName}/addMedicalCheckoutService`,
  async ({ isWithNotification = false, serviceData }, thunkAPI) => {
    try {
      const response = await addMedicalCheckoutServiceApi(serviceData);

      return {
        data: response.data,
        ...(isWithNotification && {
          successMessage: "Medical checkout service added successfully",
        }),
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to add medical checkout service" });
    }
  },
);

export const updateMedicalCheckoutService = createAsyncThunk(
  `${servicesSliceName}/addMedicalCheckoutService`,
  async ({ serviceData, isWithNotification = false }, thunkAPI) => {
    try {
      const response = await updateMedicalCheckoutServiceApi(serviceData);

      return {
        data: response.data,
        ...(isWithNotification && {
          successMessage: "Medical checkout service updated successfully",
        }),
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        errorMessage: "Failed to update medical checkout service",
      });
    }
  },
);

export const removeMedicalCheckoutService = createAsyncThunk(
  `${servicesSliceName}/removeMedicalCheckoutService`,
  async ({ serviceId, isWithNotification = false }, thunkAPI) => {
    try {
      const response = await deleteMedicalCheckoutServiceApi(serviceId);

      return {
        data: response.data,
        ...(isWithNotification && {
          successMessage: "Medical checkout service deleted successfully",
        }),
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        errorMessage: "Failed to delete medical checkout service",
      });
    }
  },
);
