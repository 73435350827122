import { createSlice } from "@reduxjs/toolkit";
import {
  getChatsListThunk,
  getAnUpdatedChatThunk,
  getMessagesListFromThunk,
  getUnreadMessagesCountThunk,
  chatSliceName,
} from "../actions/chat";
import { DB_NAME } from "../../utils/indexedDB";

const initialState = {
  count: null,
  next: null,
  previous: null,
  page: 1,
  page_size: 25,
  chatsList: [],
  isFetching: false,

  activeChatId: null,
  activeChats: [],
  chatLimitMessages: 100,
  isFetchingMessages: false,
  unreadLikesChatIds: [],

  unreadMessagesCount: 0,
};

export const chatSlice = createSlice({
  name: chatSliceName,
  initialState,
  reducers: {
    resetChatState: () => {
      localStorage.removeItem("activeChatId");
      indexedDB.deleteDatabase(DB_NAME.chatsListDB);
      indexedDB.deleteDatabase(DB_NAME.chatDB);

      return {
        ...initialState,
      };
    },
    setActiveChatId: (state, action) => {
      state.activeChatId = action.payload;
    },
    setIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setIsFetchingMessages: (state, action) => {
      state.isFetchingMessages = action.payload;
    },
    addNewMessage: (state, action) => {
      const chat = state.activeChats.find(chat => chat.id === action.payload.chat_id);

      if (chat) {
        chat.results.unshift(action.payload);
      }
    },
    updateMessage: (state, action) => {
      const chat = state.activeChats.find(chat => chat.id === action.payload.chat_id);

      if (chat) {
        const index = chat.results.findIndex(
          message => message.uuid === action.payload.uuid || message.id === action.payload.id,
        );

        if (index >= 0) {
          chat.results[index] = action.payload;
        } else {
          chat.results.unshift(action.payload);
        }
      }
    },
    setIsLikedOrRead: (state, action) => {
      const chat = state.activeChats.find(chat => chat.id === action.payload.chat_id);

      if (chat) {
        const index = chat.results.findIndex(message => message.id === action.payload.message_id);

        if (index >= 0) {
          chat.results[index] = { ...chat.results[index], ...action.payload };
        }
      }
    },
    addUnreadLikeChatId: (state, { payload }) => {
      state.unreadLikesChatIds.push(payload);
    },
    removeUnreadLikeChatId: (state, { payload }) => {
      state.unreadLikesChatIds = state.unreadLikesChatIds.filter(id => id !== payload);
    },
    addCachedMessages: (state, action) => {
      const { chatId, messages } = action.payload;
      const existingChat = state.activeChats.find(chat => chat.id === chatId);

      if (existingChat) {
        existingChat.results = messages;
      } else {
        state.activeChats.push({ id: chatId, results: messages, next: null });
      }
    },
    addCachedChats: (state, action) => {
      state.chatsList = action.payload;
    },
    filterChat: (state, action) => {
      const removedMessage = action.payload;
      const activeChat = state.activeChats.find(chat => chat.id === removedMessage.chat_id);

      if (activeChat) {
        state.activeChats = state.activeChats.filter(chat => chat.id !== activeChat.id);
        activeChat.results = activeChat.results.filter(message => message.id !== removedMessage.id);
        state.activeChats.push(activeChat);
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getChatsListThunk.fulfilled, (state, action) => {
        if (action.payload.isFirstLoad) {
          state.chatsList = action.payload.data.results;
        } else {
          state.chatsList = [...state.chatsList, ...action.payload.data.results];
        }

        state.page = action.payload.page;
        state.next = action.payload.data.next;
        state.previous = action.payload.data.previous;
        state.count = action.payload.data.count;
        state.isFetching = false;
      })
      .addCase(getAnUpdatedChatThunk.fulfilled, (state, action) => {
        const newChat = action.payload.data;
        state.chatsList = [newChat, ...state.chatsList.filter(chat => chat.id !== newChat.id)];
      })
      .addCase(getMessagesListFromThunk.fulfilled, (state, action) => {
        const newPartOfChat = action.payload.data;
        const isFirstRequest = action.payload.isFirstRequest;
        const activeChat = state.activeChats.find(chat => chat.id === state.activeChatId);
        const results = [
          ...(activeChat && !isFirstRequest ? activeChat.results : []),
          ...newPartOfChat.results,
        ];
        const localMessagesList = JSON.parse(localStorage.getItem("chat_message"));

        Object.values(localMessagesList || {}).forEach(message => {
          if (message.chat_id === activeChat?.id) {
            results.unshift(message);
          }
        });

        state.activeChats = [
          {
            results,
            id: state.activeChatId,
            next: newPartOfChat.next,
            id_to: newPartOfChat.results[newPartOfChat.results.length - 1]?.id,
          },
          ...state.activeChats.filter(chat => chat.id !== state.activeChatId),
        ];
        state.isFetchingMessages = false;
      })
      .addCase(getUnreadMessagesCountThunk.fulfilled, (state, action) => {
        state.unreadMessagesCount = action.payload.data;
      });
  },
});

export const {
  resetChatState,
  setActiveChatId,
  setIsFetching,
  setIsFetchingMessages,
  addNewMessage,
  updateMessage,
  setIsLikedOrRead,
  addUnreadLikeChatId,
  removeUnreadLikeChatId,
  addCachedMessages,
  addCachedChats,
  filterChat,
} = chatSlice.actions;

export default chatSlice.reducer;
