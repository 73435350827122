import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { FiCalendar } from "react-icons/fi";
import { MdOutlineCheck } from "react-icons/md";
import { LiaTimesSolid } from "react-icons/lia";
import { LuUser2 } from "react-icons/lu";

import Charts from "../components/chart";
import PersonChat from "../components/personChat";
import { aboutData, latestAppointment, patientsReviews } from "../data/data";
import * as calendarActions from "../store/actions/calendar";
import { useDispatch, useSelector } from "react-redux";
import { RiTimeFill } from "react-icons/ri";
import { getSpecializations } from "../store/actions/specialization";

const daysOfWeekMapping = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};

export default function Index() {
  const dispatch = useDispatch();
  const businessHours = useSelector(s => s.calendar.schedule.businessHours);
  useEffect(() => {
    dispatch(calendarActions.getBusinessHours());
    dispatch(getSpecializations());
  }, [dispatch]);

  const getClinicSchedule = () => {
    return businessHours.reduce((acc, item) => {
      item.daysOfWeek.forEach(day => {
        acc.push({
          day: daysOfWeekMapping[day],
          time: `${item.startTime} ${item.endTime}`,
        });
      });

      return acc;
    }, []);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="layout-specing">
          <h5 className="mb-0">Dashboard</h5>

          <div className="row">
            {aboutData.map((item, index) => {
              let Icon = item.icon;

              return (
                <div className="col-xl-3 col-lg-6 col-md-6 mt-4" key={index}>
                  <div className="card features feature-primary rounded border-0 shadow p-4">
                    <div className="d-flex align-items-center">
                      <div className="icon text-center rounded-md">
                        <Icon className="h3 mb-0" />
                      </div>
                      <div className="flex-1 ms-2">
                        <h5 className="mb-0">{item.title}</h5>
                        <p className="text-muted mb-0">{item.desc}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="row">
            <Charts />
            <div className="col-xl-4 col-lg-5 mt-4">
              <div className="card shadow border-0 p-4">
                <div className="d-flex justify-content-between align-items-center mb-3 flex-column">
                  <h6 className="align-items-center mb-0">Clinic working hours</h6>
                  <ul className="list-unstyled mb-0" style={{ width: "90%" }}>
                    {businessHours.length
                      ? getClinicSchedule().map((item, index) => {
                          return (
                            <li
                              className="d-flex justify-content-between mt-2 ms-0 mt-3 gap-1"
                              key={index}
                            >
                              <p
                                className="text-muted mb-0 d-flex gap-1 align-items-center"
                                style={{ maxWidth: "45%", width: "100%" }}
                              >
                                <RiTimeFill className="text-primary align-middle h5 mb-0" />
                                <span>{item.day}</span>
                              </p>
                              <p
                                className="text-primary mb-0 d-flex gap-1 align-items-center"
                                style={{ maxWidth: "55%", width: "100%" }}
                              >
                                <span className="text-dark">Time:</span>
                                <span style={{ whiteSpace: "nowrap" }}>{item.time}</span>
                              </p>
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/*<div className="row">*/}
          {/*  <div className="col-xl-4 col-lg-6 mt-4">*/}
          {/*    <div className="card border-0 shadow rounded">*/}
          {/*      <div className="d-flex justify-content-between align-items-center p-4 border-bottom">*/}
          {/*        <h6 className="mb-0">*/}
          {/*          <FiCalendar className="text-primary me-1 h5 mb-0" /> Latest Appointment*/}
          {/*        </h6>*/}
          {/*        <h6 className="text-muted mb-0">55 Patients</h6>*/}
          {/*      </div>*/}

          {/*      <ul className="list-unstyled mb-0 p-4 pt-0">*/}
          {/*        {latestAppointment.map((item, index) => {*/}
          {/*          return (*/}
          {/*            <li className="ms-0 pt-4" key={index}>*/}
          {/*              <div className="d-flex align-items-center justify-content-between">*/}
          {/*                <div className="d-inline-flex">*/}
          {/*                  <img*/}
          {/*                    src={item.image}*/}
          {/*                    className="avatar avatar-md-sm rounded-circle border shadow"*/}
          {/*                    alt=""*/}
          {/*                  />*/}
          {/*                  <div className="ms-3">*/}
          {/*                    <h6 className="text-dark mb-0 d-block">{item.name}</h6>*/}
          {/*                    <small className="text-muted">{item.date}</small>*/}
          {/*                  </div>*/}
          {/*                </div>*/}
          {/*                <div>*/}
          {/*                  <Link to="#" className="btn btn-icon btn-pills btn-soft-success">*/}
          {/*                    <MdOutlineCheck />*/}
          {/*                  </Link>*/}
          {/*                  <Link to="#" className="btn btn-icon btn-pills btn-soft-danger ms-1">*/}
          {/*                    <LiaTimesSolid />*/}
          {/*                  </Link>*/}
          {/*                </div>*/}
          {/*              </div>*/}
          {/*            </li>*/}
          {/*          );*/}
          {/*        })}*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </div>*/}

          {/*  <div className="col-xl-4 col-lg-6 mt-4">*/}
          {/*    <PersonChat />*/}
          {/*  </div>*/}

          {/*  <div className="col-xl-4 col-lg-12 mt-4">*/}
          {/*    <div className="card border-0 shadow rounded">*/}
          {/*      <div className="p-4 border-bottom">*/}
          {/*        <div className="d-flex justify-content-between align-items-center">*/}
          {/*          <h6 className="mb-0">*/}
          {/*            <LuUser2 className="text-primary me-1 h5" /> Patients Reviews*/}
          {/*          </h6>*/}

          {/*          <div className="mb-0 position-relative">*/}
          {/*            <select className="form-select form-control" id="dailypatient">*/}
          {/*              <option defaultValue>New</option>*/}
          {/*              <option>Old</option>*/}
          {/*            </select>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <SimpleBar style={{ height: "355px" }}>*/}
          {/*        <ul className="list-unstyled mb-0 p-4 pt-0">*/}
          {/*          {patientsReviews.map((item, index) => {*/}
          {/*            return (*/}
          {/*              <li*/}
          {/*                className="d-flex align-items-center justify-content-between mt-4"*/}
          {/*                key={index}*/}
          {/*              >*/}
          {/*                <div className="d-flex align-items-center">*/}
          {/*                  <img*/}
          {/*                    src={item.image}*/}
          {/*                    className="avatar avatar-small rounded-circle border shadow"*/}
          {/*                    alt=""*/}
          {/*                  />*/}
          {/*                  <div className="flex-1 ms-3">*/}
          {/*                    <span className="d-block h6 mb-0">{item.name}</span>*/}
          {/*                    <small className="text-muted">{item.speciality}</small>*/}

          {/*                    <ul className="list-unstyled mb-0">*/}
          {/*                      <li className="list-inline-item text-muted">({item.rate})</li>*/}
          {/*                      <li className="list-inline-item">*/}
          {/*                        <i className="mdi mdi-star text-warning"></i>*/}
          {/*                      </li>*/}
          {/*                      <li className="list-inline-item">*/}
          {/*                        <i className="mdi mdi-star text-warning"></i>*/}
          {/*                      </li>*/}
          {/*                      <li className="list-inline-item">*/}
          {/*                        <i className="mdi mdi-star text-warning"></i>*/}
          {/*                      </li>*/}
          {/*                      <li className="list-inline-item">*/}
          {/*                        <i className="mdi mdi-star text-warning"></i>*/}
          {/*                      </li>*/}
          {/*                      <li className="list-inline-item">*/}
          {/*                        <i className="mdi mdi-star text-warning"></i>*/}
          {/*                      </li>*/}
          {/*                    </ul>*/}
          {/*                  </div>*/}
          {/*                </div>*/}

          {/*                <p className="text-muted mb-0">{item.patients} Patients</p>*/}
          {/*              </li>*/}
          {/*            );*/}
          {/*          })}*/}
          {/*        </ul>*/}
          {/*      </SimpleBar>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
}
