import React, { useEffect, useRef, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { Controller } from "react-hook-form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { components } from "react-select";

const CustomAsyncSelect = ({
  name,
  placeholder,
  control,
  rules,
  isMulti = false,
  getOptions,
  defaultOption,
  limit,
  className,
  initialOption,
  isClearable = false,
  isSearchable = true,
  isDisabled = false,
  customOnChange,
  currentKey,
  renderTooltip,
}) => {
  const [selected, setSelected] = useState(null);
  const hasUserSelected = useRef(false);

  useEffect(() => {
    if (initialOption === null) {
      setSelected(initialOption);
      hasUserSelected.current = false;
    } else if (!hasUserSelected.current) {
      setSelected(initialOption);
    }
  }, [initialOption]);

  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await getOptions({ search: search, page, page_size: limit });

    return {
      options: defaultOption ? [defaultOption, ...response.options] : response.options,
      hasMore: response.hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const onChange = option => {
          field.onChange(isMulti ? option.map(opt => opt.value) : option?.value || "");

          if (customOnChange) {
            customOnChange();
          }

          hasUserSelected.current = true;

          setSelected(option || "");
        };

        return (
          <div className="select">
            <AsyncPaginate
              placeholder={placeholder}
              value={selected}
              loadOptions={loadOptions}
              onChange={onChange}
              debounceTimeout={500}
              isClearable={isClearable}
              isDisabled={isDisabled}
              isSearchable={isSearchable}
              components={{
                ...(renderTooltip && {
                  Option: props => <CustomOption {...props} renderTooltip={renderTooltip} />,
                  SingleValue: props => (
                    <CustomSingleValue {...props} renderTooltip={renderTooltip} />
                  ),
                }),
              }}
              additional={{
                page: 1,
              }}
              className={className}
              {...(currentKey && { key: currentKey })}
              styles={{
                control: styles => ({
                  ...styles,
                  border: 0,
                }),
                singleValue: styles => ({
                  ...styles,
                  display: "flex",
                  alignItems: "center",
                }),
                option: (styles, { isFocused, isSelected }) => ({
                  ...styles,
                  display: "flex",
                  alignItems: "center",
                  color: isSelected ? styles.color || "white" : styles.color || "black",
                  backgroundColor: isSelected ? "#2684FF" : isFocused ? "#DEEBFF" : "transparent",
                }),
              }}
            />
            {error && <div className="invalid-feedback">{error.message}</div>}
          </div>
        );
      }}
    />
  );
};

const CustomOption = props => {
  const {
    data,
    innerRef,
    innerProps,
    renderTooltip,
    className,
    selectProps,
    cx,
    isFocused,
    isSelected,
    children,
  } = props;
  const optionStyles = selectProps.styles.option({}, props);

  return (
    <OverlayTrigger
      placement="left"
      overlay={
        renderTooltip && data?.data ? (
          <Tooltip id={`tooltip-${data.data.id}`} className="custom-option-tooltip">
            {renderTooltip(data)}
          </Tooltip>
        ) : (
          <></>
        )
      }
      container={document.body}
    >
      <div
        ref={innerRef}
        {...innerProps}
        className={cx({
          option: true,
          "option--is-focused": isFocused,
          "option--is-selected": isSelected,
          className,
        })}
        style={{
          ...optionStyles,
          padding: 10,
          cursor: "pointer",
        }}
      >
        {children}
      </div>
    </OverlayTrigger>
  );
};

const CustomSingleValue = props => {
  const { data, renderTooltip, children } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const hoverTimeoutRef = useRef(null);

  const handleMouseEnter = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setShowTooltip(true);
    }, 300);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeoutRef.current);
    setShowTooltip(false);
  };

  return (
    <components.SingleValue {...props}>
      <span
        style={{ display: "inline-block", cursor: "pointer", position: "relative" }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {renderTooltip && data?.data ? (
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip id={`tooltip-single-${data.data.id}`} className="custom-option-tooltip">
                {renderTooltip(data)}
              </Tooltip>
            }
            show={showTooltip}
            container={document.body}
          >
            <span>{children}</span>
          </OverlayTrigger>
        ) : (
          children
        )}
      </span>
    </components.SingleValue>
  );
};

export default CustomAsyncSelect;
