import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import Select from "../Select/Select";
import React, { useEffect, useRef, useState } from "react";
import {
  addDoctor,
  getDoctorAllServices,
  getDoctorProfile,
  updateDoctor,
  updateDoctorMe,
} from "../../store/actions/doctor";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../utils/history";
import ColorPicker from "../ColorPicker/ColorPicker";
import blankImagePath from "../../assets/images/blank-image-icon.png";
import { Button } from "react-bootstrap";
import { uploadPublicFiles } from "../../api/files";
import ImageLoader from "../FileLoader/ImageLoader";
import { DebounceInput } from "react-debounce-input";
import { checkUsernameApi } from "../../api/staff";
import { generateCustomUserName } from "../../utils/generateCustomUserName";
import Spinner from "react-bootstrap/Spinner";
import { showSubmitConfirmation } from "../../store/reducers/submitsSlice";
import { FiPaperclip } from "react-icons/fi";
import { Link } from "react-router-dom";
import FileLoader from "../FileLoader/FileLoader";
import { ROLES } from "../../config/accessControlConfig";
import { getUserProfile } from "../../store/actions/auth";

const DoctorEditForm = ({
  doctorProfile = {},
  specializations,
  services,
  buttonText = "Submit",
  isCanEdit,
  isCanDelete,
  handleDelete,
  isCanEditUsername,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(s => s.auth.user);
  const token = useSelector(s => s.auth.tokens?.access_token);
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters")
      .matches(/^[A-Za-z]+$/, "First Name can only contain letters"),
    last_name: Yup.string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters")
      .matches(/^[A-Za-z]+$/, "Last Name can only contain letters"),
    specializations: Yup.array().of(Yup.number()),
    additional_services: Yup.array(),
    calendar_color: Yup.string().required("Choose color for calendar"),
    can_participate_in_treatment_plans: Yup.boolean(),
    username: Yup.string()
      .transform(value => (value === "" ? null : value))
      .nullable()
      .min(4, "Username must be at least 4 characters")
      .test("unique", "Checking username...", async (value, context) => {
        if (!value || value === doctorProfile?.username) return true;

        const fieldName = context.path;

        try {
          await checkUsernameApi({ [fieldName]: value });

          return true;
        } catch (err) {
          if (err?.response?.data?.[fieldName]?.[0]) {
            return context.createError({
              message: err.response.data.username[0] || "Username is already taken",
            });
          }
        }

        return true;
      }),
    is_change_password: Yup.boolean(),
    password: Yup.string()
      .transform(value => (value === "" ? null : value))
      .nullable(),
  });

  const defaultValues = {
    first_name: "",
    last_name: "",
    specializations: [],
    additional_services: [],
    calendar_color: "",
    can_participate_in_treatment_plans: false,
    username: "",
    is_change_password: false,
    password: "",
  };
  const formOptions = { resolver: yupResolver(validationSchema), defaultValues };
  const fileInputRef = useRef(null);
  const [isFilesUploading, setIsFilesUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(doctorProfile.photo_details || {});

  const [uploadedDoctorFiles, setUploadedDoctorFiles] = useState([]);
  const [isUploadingDoctorFiles, setIsUploadingDoctorFiles] = useState(false);

  const { register, clearErrors, handleSubmit, formState, setValue, watch, control } =
    useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const iCanParticipateInTreatmentPlans = watch("can_participate_in_treatment_plans");
  const isChangePassword = watch("is_change_password");
  const username = watch("username");
  const firstName = watch("first_name");
  const lastName = watch("last_name");

  useEffect(() => {
    if (doctorProfile.id) {
      setValue("first_name", doctorProfile.first_name || "");
      setValue("last_name", doctorProfile.last_name || "");
      setValue("specializations", doctorProfile.specializations || []);
      setValue("additional_services", doctorProfile.additional_services || "");
      setValue("calendar_color", doctorProfile.calendar_color || "");
      setValue(
        "can_participate_in_treatment_plans",
        doctorProfile.can_participate_in_treatment_plans || false,
      );
      setValue("username", doctorProfile.username || "");
      setValue("is_change_password", false);
      setValue("password", null);

      if (doctorProfile.files_details) {
        setUploadedDoctorFiles(doctorProfile.files_details);
      }
    }
  }, [doctorProfile, setValue]);

  const checkIfRequired = fieldName => {
    const fieldSchema = validationSchema.describe().fields[fieldName];

    return fieldSchema.tests.some(test => test.name === "required" || test.name === "min");
  };

  const handleFileChange = async event => {
    const selectedFile = Array.from(event.target.files)[0];

    if (!selectedFile) return;

    setIsFilesUploading(true);

    try {
      const uploadedFiles = await uploadPublicFiles([selectedFile]);
      setUploadedFile(uploadedFiles[0] || {});

      console.log("Uploaded file IDs:", uploadedFiles);
    } catch (error) {
      console.error("Error uploading files:", error);

      return;
    } finally {
      setIsFilesUploading(false);
    }

    setIsFilesUploading(false);
  };

  const handleRemoveFile = () => {
    setUploadedFile({});
  };

  const handleDoctorFileChange = async event => {
    const selectedFiles = Array.from(event.target.files);

    const newFiles = selectedFiles.filter(
      file => !uploadedDoctorFiles.some(existingFile => existingFile?.file?.name === file.name),
    );

    if (newFiles.length === 0) {
      return;
    }

    try {
      setIsUploadingDoctorFiles(true);
      const newUploadedFiles = await uploadPublicFiles(newFiles);
      setUploadedDoctorFiles(prevFiles => [...prevFiles, ...newUploadedFiles]);
      console.log("Uploaded file IDs:", newUploadedFiles);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setIsUploadingDoctorFiles(false);
    }
  };

  const removeDoctorFile = (e, id) => {
    e.preventDefault();
    const currentFile = uploadedDoctorFiles.find(file => file.id === id);
    dispatch(
      showSubmitConfirmation({
        message: `Are you sure you want to delete file: ${currentFile.filename}?`,
        onConfirm: () => {
          setUploadedDoctorFiles(prevFiles => prevFiles.filter(file => file.id !== id));
        },
      }),
    );
  };

  const onSubmit = async ({
    first_name,
    last_name,
    specializations,
    additional_services,
    calendar_color,
    can_participate_in_treatment_plans,
    username,
    password,
  }) => {
    const doctorData = {
      first_name,
      last_name,
      specializations,
      additional_services,
      calendar_color,
      can_participate_in_treatment_plans,
      photo: uploadedFile.id || null,
      username,
      password,
    };

    if (doctorProfile.id) {
      if (user?.role === ROLES.DOCTOR) {
        await dispatch(
          updateDoctorMe({
            id: doctorProfile.id,
            files: uploadedDoctorFiles.map(file => file.id),
            ...doctorData,
          }),
        );
        await dispatch(getUserProfile(token));
      } else {
        await dispatch(
          updateDoctor({
            id: doctorProfile.id,
            files: uploadedDoctorFiles.map(file => file.id),
            ...doctorData,
          }),
        );
      }

      await dispatch(getDoctorProfile({ id: doctorProfile.id }));
      await dispatch(getDoctorAllServices({ id: doctorProfile.id }));
    } else {
      await dispatch(addDoctor(doctorData));

      history.navigate("/doctors");
    }
  };

  useEffect(() => {
    const generateUsername = async () => {
      if (!doctorProfile.id && firstName && lastName) {
        try {
          const username = await generateCustomUserName({ firstName, lastName });
          setValue("username", username);
          clearErrors("username");
        } catch (error) {}
      }
    };

    generateUsername();
  }, [doctorProfile.id, firstName, lastName]);

  return (
    <>
      <div className={`${doctorProfile?.id ? "col-lg-6" : ""}`}>
        <div className="rounded shadow mt-4">
          <div className="p-4 border-bottom">
            <h6 className="mb-0">Personal Information :</h6>
          </div>
          <div className="p-4">
            <form className="" onSubmit={handleSubmit(onSubmit)}>
              <div className="row align-items-center mb-4">
                <div className="col-lg-2 col-md-4 position-relative">
                  <>
                    {isFilesUploading && (
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          background: "#f8f9fa",
                          borderRadius: "5px",
                        }}
                      >
                        <Spinner
                          as="span"
                          variant="primary"
                          animation="border"
                          size="lg"
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                    <ImageLoader
                      src={
                        isFilesUploading
                          ? ""
                          : uploadedFile.safe_url
                            ? `${uploadedFile.safe_url}?x=80`
                            : blankImagePath
                      }
                      className="avatar avatar-md-md rounded-pill shadow mx-auto d-block"
                      alt=""
                    />
                  </>
                </div>
                {isCanEdit && (
                  <>
                    <div className="col-lg-5 col-md-8 text-center text-md-start mt-4 mt-sm-0">
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept="image/jpeg, image/png"
                        onClick={e => (e.target.value = null)}
                        onChange={handleFileChange}
                      />
                      <h5 className="">Upload your picture</h5>
                      <p className="text-muted mb-0">
                        For best results, use an image at least 600px by 600px in either .jpg or
                        .png format
                      </p>
                    </div>

                    <div className="col-lg-5 col-md-12 text-lg-end text-center mt-4 mt-lg-0">
                      <Button
                        disabled={isFilesUploading}
                        className="btn btn-primary"
                        onClick={() => fileInputRef.current.click()}
                      >
                        {isFilesUploading ? "Uploading..." : "Upload"}
                      </Button>
                      <Button
                        className="btn btn-soft-primary ms-2"
                        disabled={isFilesUploading}
                        onClick={handleRemoveFile}
                      >
                        Remove
                      </Button>
                    </div>
                  </>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">{`First Name${checkIfRequired("first_name") ? " *" : ""}`}</label>
                    <input
                      name="first_name"
                      id="first_name"
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.first_name,
                      })}
                      placeholder="First Name"
                      disabled={!isCanEdit}
                      {...register("first_name")}
                      onChange={e => {
                        const cleanedValue = e.target.value.replace(/[^A-Za-z]/g, "");
                        setValue("first_name", cleanedValue, { shouldDirty: true });
                      }}
                    />
                    <div className="invalid-feedback">{errors.first_name?.message}</div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">{`Last Name${checkIfRequired("last_name") ? " *" : ""}`}</label>
                    <input
                      name="last_name"
                      id="last_name"
                      type="text"
                      disabled={!isCanEdit}
                      className={classNames("form-control", {
                        "is-invalid": errors.last_name,
                      })}
                      placeholder="Last Name"
                      {...register("last_name")}
                      onChange={e => {
                        const cleanedValue = e.target.value.replace(/[^A-Za-z]/g, "");
                        setValue("last_name", cleanedValue, { shouldDirty: true });
                      }}
                    />
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  </div>
                </div>

                {/*<div className="col-md-6">*/}
                {/*  <div className="mb-3">*/}
                {/*    <label className="form-label">Your Email</label>*/}
                {/*    <input*/}
                {/*      name="email"*/}
                {/*      id="email"*/}
                {/*      type="email"*/}
                {/*      className="form-control"*/}
                {/*      placeholder="Your email :"*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div className="col-md-6">*/}
                {/*  <div className="mb-3">*/}
                {/*    <label className="form-label">Phone no.</label>*/}
                {/*    <input*/}
                {/*      name="number"*/}
                {/*      id="number"*/}
                {/*      type="text"*/}
                {/*      className="form-control"*/}
                {/*      placeholder="Phone no. :"*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">{`Specializations${checkIfRequired("specializations") ? " *" : ""}`}</label>
                    <Select
                      isSearchable={false}
                      onChange={setValue}
                      name="specializations"
                      optionsData={specializations}
                      valueField="id"
                      labelField="name"
                      isDisabled={!isCanEdit}
                      placeholder="Select specialization"
                      className={classNames("form-control", "p-0", {
                        "is-invalid": errors.specializations,
                      })}
                      initialValue={watch("specializations")}
                    />
                    <div className="invalid-feedback">{errors.specializations?.message}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">{`Additional Services${checkIfRequired("additional_services") ? " *" : ""}`}</label>
                    <Select
                      isSearchable={false}
                      onChange={setValue}
                      name="additional_services"
                      optionsData={services}
                      valueField="id"
                      labelField="name"
                      isDisabled={!isCanEdit}
                      placeholder="Select services"
                      className={classNames("form-control", "p-0", {
                        "is-invalid": errors.additional_services,
                      })}
                      initialValue={watch("additional_services")}
                    />
                    <div className="invalid-feedback">{errors.additional_services?.message}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">{`Username`}</label>
                      <DebounceInput
                        name="username"
                        id="username"
                        type="text"
                        debounceTimeout={700}
                        disabled={!isCanEdit || !isCanEditUsername}
                        value={username || ""}
                        className={classNames("form-control", {
                          "is-invalid": errors.username,
                        })}
                        placeholder="Username"
                        {...register("username")}
                        onChange={e => {
                          setValue("username", e.target.value, { shouldValidate: true });
                        }}
                      />
                      <div className="invalid-feedback">{errors.username?.message}</div>
                    </div>
                  </div>
                </div>
                {isCanEdit && isCanEditUsername && (
                  <div className="row">
                    <div className="form-check">
                      <div className="px-3">
                        <input
                          className={classNames("form-check-input", {
                            "is-invalid": errors.is_change_password,
                          })}
                          type="checkbox"
                          checked={isChangePassword}
                          id="is_change_password"
                          {...register("is_change_password")}
                        />
                        <label className="form-check-label" htmlFor="is_change_password">
                          {`Is set new password?`}
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                {isChangePassword && (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{`Password`}</label>
                        <input
                          name="password"
                          id="password"
                          type="text"
                          className={classNames("form-control", {
                            "is-invalid": errors.password,
                          })}
                          placeholder="Password"
                          {...register("password")}
                        />
                        <div className="invalid-feedback">{errors.password?.message}</div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">{`Calendar Color${checkIfRequired("calendar_color") ? " *" : ""}`}</label>
                    <ColorPicker
                      control={control}
                      name="calendar_color"
                      disabled={!isCanEdit}
                      className={classNames("form-control", "p-0", {
                        "is-invalid": errors.calendar_color,
                      })}
                    />
                    {errors.calendar_color?.message && (
                      <div className="invalid-feedback d-block">
                        {errors.calendar_color?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-check">
                    <div className="px-3">
                      <input
                        className={classNames("form-check-input", {
                          "is-invalid": errors.can_participate_in_treatment_plans,
                        })}
                        type="checkbox"
                        disabled={!isCanEdit}
                        checked={iCanParticipateInTreatmentPlans}
                        id="can_participate_in_treatment_plans"
                        {...register("can_participate_in_treatment_plans")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="can_participate_in_treatment_plans"
                      >
                        {`Is doctor participate in treatment plans?`}
                      </label>
                    </div>
                  </div>
                </div>

                {/*<div className="col-md-6">*/}
                {/*  <div className="mb-3">*/}
                {/*    <label className="form-label">Gender</label>*/}
                {/*    <select className="form-select form-control">*/}
                {/*      <option defaultValue="EY">Male</option>*/}
                {/*      <option defaultValue="GY">Female</option>*/}
                {/*    </select>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div className="col-md-6">*/}
                {/*  <div className="mb-3">*/}
                {/*    <label className="form-label">Instagram</label>*/}
                {/*    <div className="input-group flex-nowrap">*/}
                {/*      <span*/}
                {/*        className="input-group-text bg-light border border-end-0 text-dark"*/}
                {/*        id="insta-id"*/}
                {/*      >*/}
                {/*        <FiInstagram className="fea icon-sm" />*/}
                {/*      </span>*/}
                {/*      <input*/}
                {/*        type="text"*/}
                {/*        className="form-control"*/}
                {/*        placeholder="Username"*/}
                {/*        aria-label="Username"*/}
                {/*        aria-describedby="insta-id"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div className="col-md-6">*/}
                {/*  <div className="mb-3">*/}
                {/*    <label className="form-label">Facebook</label>*/}
                {/*    <div className="input-group flex-nowrap">*/}
                {/*      <span*/}
                {/*        className="input-group-text bg-light border border-end-0 text-dark"*/}
                {/*        id="fb-id"*/}
                {/*      >*/}
                {/*        <FiFacebook className="fea icon-sm" />*/}
                {/*      </span>*/}
                {/*      <input*/}
                {/*        type="text"*/}
                {/*        className="form-control"*/}
                {/*        placeholder="Username"*/}
                {/*        aria-label="Username"*/}
                {/*        aria-describedby="fb-id"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div className="col-md-6">*/}
                {/*  <div className="mb-3">*/}
                {/*    <label className="form-label">Linkedin</label>*/}
                {/*    <div className="input-group flex-nowrap">*/}
                {/*      <span*/}
                {/*        className="input-group-text bg-light border border-end-0 text-dark"*/}
                {/*        id="linke-pro"*/}
                {/*      >*/}
                {/*        <FiLinkedin className="fea icon-sm" />*/}
                {/*      </span>*/}
                {/*      <input*/}
                {/*        type="text"*/}
                {/*        className="form-control"*/}
                {/*        placeholder="Username"*/}
                {/*        aria-label="Username"*/}
                {/*        aria-describedby="linke-pro"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div className="col-md-6">*/}
                {/*  <div className="mb-3">*/}
                {/*    <label className="form-label">Twitter</label>*/}
                {/*    <div className="input-group flex-nowrap">*/}
                {/*      <span*/}
                {/*        className="input-group-text bg-light border border-end-0 text-dark"*/}
                {/*        id="twitter-id"*/}
                {/*      >*/}
                {/*        <FiTwitter className="fea icon-sm" />*/}
                {/*      </span>*/}
                {/*      <input*/}
                {/*        type="text"*/}
                {/*        className="form-control"*/}
                {/*        placeholder="Username"*/}
                {/*        aria-label="Username"*/}
                {/*        aria-describedby="twitter-id"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div className="col-md-12">*/}
                {/*  <div className="mb-3">*/}
                {/*    <label className="form-label">Your Bio Here</label>*/}
                {/*    <textarea*/}
                {/*      name="comments"*/}
                {/*      id="comments"*/}
                {/*      rows="3"*/}
                {/*      className="form-control"*/}
                {/*      placeholder="Bio :"*/}
                {/*    ></textarea>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
              {isCanEdit && (
                <button
                  disabled={isSubmitting || isFilesUploading || isUploadingDoctorFiles}
                  className="btn btn-primary"
                >
                  {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                  {buttonText}
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
      {doctorProfile?.id && (
        <div className="col-lg-6">
          <div className="rounded shadow mt-4">
            <div className="p-4 border-bottom">
              <h6 className="mb-0">General Notifications :</h6>
            </div>
            <div className="col-lg-12 p-4">
              <div className="mb-3 p-4">
                <label className="form-label me-2">Attachments</label>
                {isCanEdit && (
                  <label className="btn btn-icon btn-primary">
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/jpeg, image/png, application/pdf"
                      multiple
                      onClick={e => (e.target.value = null)}
                      onChange={handleDoctorFileChange}
                    />
                    <FiPaperclip />
                  </label>
                )}
                <div className="mb-2 d-flex flex-wrap">
                  {isUploadingDoctorFiles && <p style={{ width: "100%" }}>Uploading files...</p>}
                  {uploadedDoctorFiles.map((item, index) => {
                    const attachmentUrl = item.safe_url;

                    return (
                      <Link
                        to={attachmentUrl}
                        key={index}
                        className="position-relative m-2"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FileLoader item={item} removeFile={removeDoctorFile} />
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
            {isCanDelete && (
              <div className="p-4">
                <div className="p-4 border-bottom">
                  <h5 className="mb-0 text-danger">Delete Account :</h5>
                </div>

                <div className="p-4">
                  <h6 className="mb-0 fw-normal">
                    Do you want to delete the account? Please press below "Delete" button
                  </h6>
                  <div className="mt-4">
                    <button
                      className="btn btn-danger"
                      onClick={handleDelete}
                      disabled={isSubmitting || isFilesUploading || isUploadingDoctorFiles}
                    >
                      Delete Account
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DoctorEditForm;
