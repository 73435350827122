import { createAsyncThunk } from "@reduxjs/toolkit";
import { addMedicalCheckoutApi, removeMedicalCheckoutApi } from "../../api/medicalCheckout";

import {
  addMedicalCheckoutService,
  removeMedicalCheckoutService,
  updateMedicalCheckoutService,
} from "./services";
import { isEqual } from "lodash";

export const medicalCheckoutSliceName = "medicalCheckout";

export const addMedicalCheckout = createAsyncThunk(
  `${medicalCheckoutSliceName}/addMedicalCheckoutApi`,
  async ({ eventId, checkoutData }, thunkAPI) => {
    try {
      const { selected } = thunkAPI.getState().calendar.dialog;
      const { checkout_services, deleted_services, ...restCheckoutData } = checkoutData;
      const newServices = checkout_services.filter(service => !service.id);
      const oldServices = checkout_services.filter(service => service.id);
      let servicesForUpdate = null;

      if (oldServices.length) {
        const normalize = obj =>
          JSON.parse(
            JSON.stringify(obj, (key, value) =>
              typeof value === "string" && !isNaN(value) ? parseFloat(value) : value,
            ),
          );
        const { checkout_services_details } = selected.checkout;
        servicesForUpdate = checkout_services_details.reduce((acc, service) => {
          const oldService = oldServices.find(item => item.id === service.id);

          if (!oldService) {
            return acc;
          }

          if (!isEqual(normalize(oldService), normalize(service))) {
            acc.push(oldService);
          }

          return acc;
        }, []);

        await Promise.all(
          servicesForUpdate.map(service => {
            return thunkAPI.dispatch(updateMedicalCheckoutService({ serviceData: service }));
          }),
        );
      }

      if (deleted_services?.length) {
        await Promise.all(
          deleted_services.map(service => {
            return thunkAPI.dispatch(removeMedicalCheckoutService({ serviceId: service.id }));
          }),
        );
      }

      const result = await Promise.all(
        newServices.map(service => {
          return thunkAPI.dispatch(addMedicalCheckoutService({ serviceData: service }));
        }),
      );

      const response = await addMedicalCheckoutApi(eventId, {
        ...restCheckoutData,
        checkout_services: [
          ...oldServices.map(service => service.id),
          ...result.map(serviceData => serviceData.payload.data.id),
        ],
      });

      return {
        data: response.data,
        successMessage: "Medical checkout added successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to add medical checkout" });
    }
  },
);

export const deleteMedicalCheckout = createAsyncThunk(
  `${medicalCheckoutSliceName}/deleteMedicalCheckout`,
  async (id, thunkAPI) => {
    try {
      const response = await removeMedicalCheckoutApi(id);

      return {
        data: response.data,
        successMessage: "Medical checkout removed successfully",
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ errorMessage: "Failed to remove medical checkout" });
    }
  },
);
