import { createAsyncThunk } from "@reduxjs/toolkit";
import { getChatsList, getChat, getMessagesListFrom, getUnreadMessagesCount } from "../../api/chat";

export const chatSliceName = "chat";

export const getChatsListThunk = createAsyncThunk(
  `${chatSliceName}/getChatsList`,
  async ({ queryObj, isFirstLoad }, thunkAPI) => {
    try {
      const { page, page_size } = thunkAPI.getState().chat;
      const query = {
        page: isFirstLoad ? 1 : page,
        page_size,
        ...(queryObj && { ...queryObj }),
      };
      const response = await getChatsList(query);

      return {
        data: response.data,
        page: +query.page + 1,
        isFirstLoad,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getAnUpdatedChatThunk = createAsyncThunk(
  `${chatSliceName}/getAnUpdatedChat`,
  async (chatId, thunkAPI) => {
    try {
      const response = await getChat(chatId);

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getMessagesListFromThunk = createAsyncThunk(
  `${chatSliceName}/getMessagesListFrom`,
  async ({ activeChatId, isFirstRequest }, thunkAPI) => {
    try {
      const { chatLimitMessages, activeChats } = thunkAPI.getState().chat;
      const activeChat = activeChats.find(chat => chat.id === activeChatId);

      const query = {
        limit: chatLimitMessages,
        chat_id: activeChatId,
        ...(activeChat ? { id_to: activeChat.id_to } : {}),
      };
      const response = await getMessagesListFrom(query);

      return {
        data: response.data,
        isFirstRequest,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getUnreadMessagesCountThunk = createAsyncThunk(
  `${chatSliceName}/getUnreadMessagesCount`,
  async (_, thunkAPI) => {
    try {
      const response = await getUnreadMessagesCount();

      return {
        data: response.data.unread_count,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
