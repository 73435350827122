import React, { useState } from "react";
import { history } from "../../../utils/history";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import classNames from "classnames";

const SelectDateRange = () => {
  const location = history.location;
  const [dateRange, setDateRange] = useState([
    Object.fromEntries(new URLSearchParams(location.search))?.start || null,
    Object.fromEntries(new URLSearchParams(location.search))?.end || null,
  ]);
  const [startDate, endDate] = dateRange;

  const handleChangeRange = update => {
    const params = new URLSearchParams(location.search);
    setDateRange(update);
    params.delete("start");
    params.delete("end");

    if (update[0]) {
      params.set("start", moment(update[0]).format("YYYY-MM-DDTHH:mm:ss"));
    }

    if (update[1]) {
      params.set("end", moment(update[1]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"));
    } else if (update[0]) {
      params.set("end", moment(update[0]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"));
    }

    history.navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <div className={classNames("form-control")}>
      <DatePicker
        placeholderText="Select range"
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={update => {
          handleChangeRange(update);
        }}
        isClearable={true}
        className="react-datepicker-custom-input"
        dateFormat="dd.MM.yyyy"
      />
    </div>
  );
};

export default SelectDateRange;
