import { useCallback, useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useLocation, useNavigate } from "react-router-dom";

const SearchFilter = ({ filterName }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(
    Object.fromEntries(new URLSearchParams(location.search))?.[filterName] || "",
  );

  const updateSearchParams = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const currentParams = Object.fromEntries(params.entries());

    if (searchValue || currentParams[filterName]) {
      if (searchValue !== currentParams[filterName]) {
        if (searchValue) {
          params.set(filterName, searchValue);
        } else {
          params.delete(filterName);
        }

        if (currentParams.page && searchValue && currentParams.page !== "1") {
          params.set("page", "1");
        }

        navigate({ pathname: location.pathname, search: params.toString() }, { replace: true });
      }
    }
  }, [searchValue, filterName, location.pathname, location.search, navigate]);

  useEffect(() => {
    updateSearchParams();
  }, [searchValue, updateSearchParams]);

  const handleInputChange = event => {
    setSearchValue(event.target.value);
  };

  return (
    <div className="mb-0">
      <form>
        <DebounceInput
          type="text"
          debounceTimeout={300}
          className="border p-1 mt-2"
          style={{ minWidth: "20px", width: "100%" }}
          value={searchValue}
          onChange={handleInputChange}
        />
      </form>
    </div>
  );
};

export default SearchFilter;
